import React, { useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import utilService from "../../../services/util.service";
import BatchActions from "../BatchActions/BatchActions";
import BatchChildTable from "../BatchChildTable/BatchChildTable";
import BatchSupplyDetails from "../BatchSupplyDetails/BatchSupplyDetails";

function BatchTable({
  batchData,
  getBatchesOfCollection,
  collectionId,
  setKey,
  maxSupply,
}) {
  const [page, setPage] = useState(0);
  const limit = 10;

  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit,
      page: e == 0 ? 0 : e - 1,
      collectionId,
    };

    getBatchesOfCollection(obj);
  };

  return (
    <Col>
      <BatchSupplyDetails batchData={batchData} maxSupply={maxSupply} />
      <Table striped bordered hover className="mt-2">
        <thead>
          <tr>
            <th>#Batch.No</th>
            <th>Whitelist</th>
            <th>Presale</th>
            <th>Mainsale</th>
            <th>Status</th>
            <th>Minted/Total</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {batchData.totalCounts > 0 ? (
            <>
              {batchData.list.map((batch) => (
                <tr>
                  <td>{batch.batchNo}</td>
                  <td>
                    <BatchChildTable
                      startTime={batch.whiteListStartTime}
                      endTime={batch.whiteListEndTime}
                    />
                  </td>
                  <td>
                    <BatchChildTable
                      startTime={batch.preSaleStartTime}
                      endTime={batch.preSaleEndTime}
                    />
                  </td>
                  <td>
                    <BatchChildTable
                      startTime={batch.mainSaleStartsIn}
                      endTime={batch.mainSaleEndsIn}
                    />
                  </td>
                  <td>{utilService.formatBatchStatus(batch.batchStatus)}</td>
                  <td>{`${
                    batch.nftsMinted.length > 0 ? batch.nftsMinted[0].count : 0
                  }/${batch.supply}`}</td>

                  <BatchActions
                    batch={batch}
                    getBatchesOfCollection={getBatchesOfCollection}
                    setKey={setKey}
                    batchData={batchData}
                    maxSupply={maxSupply}
                  />
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={7}> No batches found </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="layoutStyle">
        {batchData?.totalCounts > limit && (
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={batchData.totalCounts}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        )}
      </div>
    </Col>
  );
}

export default withRouter(BatchTable);
