import { combineReducers } from "redux";

import persistedReducer from "./persistReducer";
import { history } from "../history";

import {reducer as formReducer} from 'redux-form';
import loadingReducer from "./loadingReducer";
import collectionReducer from "./collectionReducer";
import { connectRouter } from "connected-react-router";
import batchReducer from "./batchReducer";
import customizeReducer from "./customizeReducer";
import notificationReducer from "./notificationReducer";

const appReducer = combineReducers({
  persist: persistedReducer,
  router: connectRouter(history),
  form:formReducer,
  loading:loadingReducer,
  collection:collectionReducer,
  batch:batchReducer,
  customize:customizeReducer,
  notification:notificationReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USERS_PERSIST") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
