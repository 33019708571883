import React from "react";
import * as moment from "moment";

function BatchChildTable({ startTime, endTime }) {
  const formatDate = (date) => {
    if (!date) return "NA";
    return moment(date).format("MM-DD-YYYY h:mm:ss a");
  };
  return (
    <table>
      <tbody>
        <tr>
          <td style={{ padding: "0px 30px" }}>
            Starts In :- {formatDate(startTime)}
          </td>
        </tr>
        <tr>
          <td style={{ padding: "0px 30px" }}>
            Ends In :- {formatDate(endTime)}
          </td>
        </tr>
        {/* <tr>
          <td style={{ padding: "20px" }}>{formatDate(startTime)}</td>
          <td style={{ padding: "20px" }}>{formatDate(endTime)}</td>
        </tr> */}
      </tbody>
    </table>
  );
}

export default BatchChildTable;
