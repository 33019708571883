import React from "react";
import "./HeaderStyle.scss";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink, useHistory, useLocation, withRouter } from "react-router-dom";
// import Logo from "../../../assets/images/LOGO.svg";
import Logo from "../../../assets/images/logo-spoz.svg";
import MediaIcon from "../socialmedia/MediaIcon";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { GearFill } from "react-bootstrap-icons";
import { useMemo } from "react";
import HeaderLoginButtons from "../HeaderLoginButtons/HeaderLoginButtons";
import { CustomizeActions } from "../../../redux/actions/customize.action";

const Header = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = props.history;
  const INNER_PAGES = "auth";
  const DETAIL_PAGE_WITHOUT_LOGIN_BSC = "BSC";
  const DETAIL_PAGE_WITHOUT_LOGIN_ETH = "ETH";
  const DETAIL_PAGE_WITHOUT_LOGIN_MATIC = "MATIC";
  const DETAIL_PAGE_WITH_LOGIN = "collection/details";

  const navigate = (url) => {
    history.push(url);
  };

  const checkCurrentPage = useMemo(() => {
   const {setCustomizeButtonClicked} = CustomizeActions;
    if (location.pathname.indexOf(DETAIL_PAGE_WITHOUT_LOGIN_BSC) != -1) {
      return "DETAIL_PAGE_WITHOUT_LOGIN";
    } else if (location.pathname.indexOf(DETAIL_PAGE_WITHOUT_LOGIN_ETH) != -1) {
      return "DETAIL_PAGE_WITHOUT_LOGIN";
    } else if (
      location.pathname.indexOf(DETAIL_PAGE_WITHOUT_LOGIN_MATIC) != -1
    ) {
      return "DETAIL_PAGE_WITHOUT_LOGIN";
    } else if (location.pathname.indexOf(DETAIL_PAGE_WITH_LOGIN) != -1) {
      return "DETAIL_PAGE_WITH_LOGIN";
    }  else if (location.pathname.indexOf(INNER_PAGES) != -1) {
      dispatch(setCustomizeButtonClicked(false))
      return "INNER_PAGES";
    }
    dispatch(setCustomizeButtonClicked(false))
    return "HOMEPAGE" 
  }, [location.pathname]);

  return (
    <div
      className="header"
      style={!!props.style ? { background: props.style.headerColor } : null}
    >
      <Navbar expand="md">
        <Navbar.Brand style={{ cursor: "pointer" }}>
          <NavLink to="/">
            <img src={Logo} alt={"logo-icon"} />
          </NavLink>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse id="basic-navbar-nav" className="ml-auto">
          {!props.isLoggedIn ? (
            <Nav className="">
              <button
                style={
                  !!props.style
                    ? {
                        background: props.style.buttonBackground,
                        color: props.style.buttonColor,
                      }
                    : null
                }
                onClick={() => navigate("/login")}
                className=" loginBtn cmnBtn me-3 bg-white "
              >
                LOGIN
              </button>
            </Nav>
          ) : (
            <HeaderLoginButtons style={props.style} history={history} checkCurrentPage={checkCurrentPage}/>
          )}

          {/* <MediaIcon /> */}
        </Navbar.Collapse>
        {/* <MediaIcon /> */}
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.persist.isLoggedIn,
});

export default withRouter(connect(mapStateToProps)(Header));
