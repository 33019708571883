import {createStore, applyMiddleware} from 'redux'

import {composeWithDevTools} from 'redux-devtools-extension';

import rootReducer from './reducers/rootReducer';

import thunkMiddleware from "redux-thunk";

import { history } from './history';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from 'connected-react-router';
import {createLogger} from 'redux-logger'


const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["persist","collection","customize"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  // const middlewares = [thunkMiddleware, logger, routerMiddleware(history)];
  const middlewares = [thunkMiddleware,routerMiddleware(history)];

  // redux devtools
  const enhancers =
    process.env.NODE_ENV === "development"
      ? composeWithDevTools(applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares);
  // create redux store
  const store = createStore(persistedReducer,{},enhancers);
  let persistor = persistStore(store);
  return { store, persistor };
};  

export default configureStore;