import types from "../types";

const initialState = {
  isCustomizeButtonClicked:false
};

const customizeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.customize.SET_CUSTOMIZE_BUTTON_CLICKED:
      return { ...state, isCustomizeButtonClicked: payload };
    default:
      return state;
  }
};

export default customizeReducer;
