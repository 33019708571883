import { Enviroments } from "../constants/constants";
import { fetch } from "./Fetch";

const { API_URL } = Enviroments;

const register = (data, headers) =>
  fetch("post", `${API_URL}/users/user/register`, data, headers, true);

const login = (data, headers) =>
  fetch("post", `${API_URL}/users/user/login`, data, headers, true);

const forgotPassword = (data, headers) =>
  fetch("post", `${API_URL}/users/user/forgotPassword`, data, headers, true);

const resetPassword = (data, headers) => {
  return fetch(
    "put",
    `${API_URL}/users/user/resetPassword`,
    data,
    headers,
    true
  );
};
const fetchFaq = (data, headers) => {
  return fetch("post", `${API_URL}/admins/faq/fetchFaq`, data, headers, true);
};

const createCollection = (data, headers) =>
  fetch(
    "post",
    `${API_URL}/nfts/collection/createCollection`,
    data,
    headers,
    true
  );

const getCollectionDetailsByExternalLink = (data, headers) =>
  fetch(
    "get",
    `${API_URL}/nfts/collection/getCollectionDetailsByExternalLink/${data} `,
    {},
    headers
  );

const createCollectionNfts = (data, headers) =>
  fetch(
    "post",
    `${API_URL}/nfts/nft/createCollectionNfts`,
    data,
    headers,
    true
  );

const whitelistUser = (data, headers) =>
  fetch(
    "post",
    `${API_URL}/nfts/whitelist/whitelistUserForMint`,
    data,
    headers,
    true
  );

const getCommission = (headers) =>
  fetch("get", `${API_URL}/admins/commission/getCommission`, {}, headers);

const getCollectionNfts = (data, headers) =>
  fetch("post", `${API_URL}/nfts/nft/getCollectionNfts`, data, headers);

const getCollections = (data, headers) =>
  fetch("post", `${API_URL}/nfts/collection/getAllCollections`, data, headers);

const getPublicNftsForMint = (data, headers) =>
  fetch("post", `${API_URL}/nfts/nft/getPublicNftsForMint`, data, headers);

const isWhiteListedUser = (data, headers) =>
  fetch(
    "post",
    `${API_URL}/nfts/whitelist/isUserWhiteListed`,
    data,
    headers,
    true
  );

const updateNftStatusToMinted = (data, headers) => {
  console.log({updateNftStatusToMinted:data});
  return fetch(
    "put",
    `${API_URL}/nfts/nft/updateNftStatusToMinted`,
    data,
    headers,
    true
  );
}
  

const getMintedNftsCount = (id, role, currentBatchNo, headers) =>
  fetch(
    "get",
    `${API_URL}/nfts/nft/getMintedNftsCount/${id}/${currentBatchNo}/${role}`,
    {},
    headers
  );

const getContracts = (headers) =>
  fetch("get", `${API_URL}/nfts/contract/getContracts`, {}, headers);

const collectionGoLive = (data, headers) =>
  fetch(
    "post",
    `${API_URL}/nfts/collection/collectionGoLive`,
    data,
    headers,
    true
  );

const updateCustomization = (id, data, headers) =>
  fetch(
    "post",
    `${API_URL}/nfts/customization/updateCustomization/${id}`,
    data,
    headers,
    true
  );

const getCustomization = (data, headers) => {
  return fetch(
    "get",
    `${API_URL}/nfts/customization/getCustomization/${data.externalLink}`,
    {},
    headers
  );
};

const changePassword = (data, headers) => {
  return fetch(
    "put",
    `${API_URL}/users/user/changePassword`,
    data,
    headers,
    true
  );
};
const addAttribute = (data, headers) => {
  return fetch(
    "post",
    `${API_URL}/nfts/nftAttributes/uploadAttributesFile`,
    data,
    headers
  );
};
const getContractByBlockChain = (blockChain, headers) => {
  return fetch(
    "get",
    `${API_URL}/nfts/contract/getContractByBlockChain/${blockChain}`,
    {},
    headers
  );
};
const getNftDetails = (data, headers) => {
  return fetch(
    "get",
    `${API_URL}/nfts/nft/getNftDetails/${data.nftId}/${data.collectionId}/${data.currentBatchNo}`,
    {},
    headers
  );
};

const deleteDraft = (id, headers) => {
  return fetch(
    "delete",
    `${API_URL}/nfts/collection/deleteDraft/${id}`,
    {},
    headers
  );
};

const getMintedCountPerWallet = (data, headers) => {
  return fetch(
    "post",
    `${API_URL}/nfts/mintedCount/getMintedCountPerWallet`,
    data,
    headers
  );
};

const getInvitationCode = (data, headers) => {
  return fetch(
    "post",
    `${API_URL}/nfts/invitation/generateInvitation`,
    data,
    headers,
    true
  );
};

const getInvitations = (data, headers) => {
  return fetch(
    "post",
    `${API_URL}/nfts/invitation/getInvitations`,
    data,
    headers
  );
};

const getInvitationDetailByInviteCode = (inviteCode, headers) => {
  return fetch(
    "get",
    `${API_URL}/nfts/invitation/getInvitationDetailByInviteCode/${inviteCode}`,
    {},
    headers
  );
};

const deleteInvitation = (id, headers) => {
  return fetch(
    "delete",
    `${API_URL}/nfts/invitation/deleteInvitation/${id}`,
    {},
    headers
  );
};

const updateMaxPerWallet = (data, headers) => {
  return fetch(
    "put",
    `${API_URL}/nfts/invitation/updateMaxPerWallet/${data.id}`,
    { maxNumberOfWallet: data.body },
    headers,
    true
  );
};

const uploadWhitelistUsersCsv = (data, headers) => {
  return fetch(
    "post",
    `${API_URL}/nfts/whitelist/uploadWhitelistUsersCsv`,
    data,
    headers
  );
};

const getPrivateNftsForMint = (data, headers) =>
  fetch("post", `${API_URL}/nfts/nft/getPrivateNftsForMint`, data, headers);

const updateCollection = (id, data, headers) =>
  fetch(
    "put",
    `${API_URL}/nfts/collection/updateCollection/${id}`,
    data,
    headers,
    true
  );

const getPrivateMintedNfts = (data, headers) =>
  fetch("post", `${API_URL}/nfts/nft/getPrivateMintedNfts`, data, headers);

const updateNftOwner = (data, headers) =>
  fetch("put", `${API_URL}/nfts/nft/updateNftOwner`, data, headers, true);

const getPrivateNftsSupplyCount = (id, currentBatchNo) => {
  return fetch(
    "get",
    `${API_URL}/nfts/nft/getPrivateNftsSupplyCount/${id}/${currentBatchNo}`,
    {},
    {}
  );
};

const getTermsCondition = (data, headers) =>
  fetch("get", `${API_URL}/admins/termsCondition/getTermsCondition`, {}, {});

const getHeaderValues = (data, headers) =>
  fetch("get", `${API_URL}/admins/homepage/getHeaderValues`, {}, {});

const getTestimonials = (data, headers) =>
  fetch(
    "post",
    `${API_URL}/admins/testimonial/getTestimonials`,
    data,
    headers,
    true
  );

const getAllCollectionsHomePage = (data, headers) =>
  fetch(
    "post",
    `${API_URL}/nfts/collection/getAllCollectionsHomePage`,
    data,
    headers
  );

const getService3Aws = (headers) =>
  fetch("get", `${API_URL}/nfts/nft/getService3Aws`, {}, headers);

const getInfuraForMint = (data, headers) =>
  fetch(
    "get",
    `${API_URL}/nfts/nft/getInfuraForMint/${data.collectionId}`,
    {},
    headers
  );

const uploadNftNameDescCsv = (data, headers) =>
  fetch("post", `${API_URL}/nfts/nft/uploadNftNameDescCsv`, data, headers);

const updateBatch = (id, data, headers) =>
  fetch("put", `${API_URL}/nfts/batch/updateBatch/${id}`, data, headers, true);

const getBatchesOfCollection = (data, headers) => {
  return fetch("post", `${API_URL}/nfts/batch/getBatchesOfCollection`, data, headers, true);
}

const getBatchDetails = (data, headers) =>
  fetch(
    "get",
    `${API_URL}/nfts/batch/getBatchDetails/${data.batchId}`,
    {},
    headers
);

const createNewBatch = (data, headers) =>
  fetch("post", `${API_URL}/nfts/batch/createNewBatch`, data, headers, true);

const verifyEmail = (data, headers) => fetch("post", `${API_URL}/users/otp/verifyEmail`, data, headers, true);
const resendOneTimePass = (data, headers) => fetch("post", `${API_URL}/users/otp/resendOtp`, data, headers, true);

const publishBatch = (data, headers) => fetch("post", `${API_URL}/nfts/batch/publishBatch`, data, headers, true);

const getFaqDetails = (id, headers) =>
  fetch("get", `${API_URL}/admins/faq/getFaqDetails/${id}`, {}, headers);

const getTotalBatchesSupply = (id, headers) =>
  fetch("get", `${API_URL}/nfts/batch/getTotalBatchesSupply/${id}`, {}, headers);

const getSoldOutNfts = (page, limit, headers) =>
  fetch("get", `${API_URL}/nfts/nft/getSoldOutNfts/${page}/${limit}`, {}, headers); 

const sendOtpForCp = (headers) =>
  fetch("get", `${API_URL}/users/otp/sendOtpForCp`, {}, headers);

const verificationBeforeCp = (data, headers) =>
  fetch("post", `${API_URL}/users/otp/verificationBeforeCp`, data , headers, true);

const getMintingNowbatches =  (page, limit, headers) =>
  fetch("get", `${API_URL}/nfts/batch/getMintingNowbatches/${page}/${limit}`, {}, headers); 

const getSoldOutCollectionsOnHomePage = (page, limit, headers) =>
fetch("get", `${API_URL}/nfts/collection/getSoldOutCollectionsOnHomePage/${page}/${limit}`, {}, headers);

const getLastBatchNftDetails = (data, headers) =>
fetch("get", `${API_URL}/nfts/batch/getLastBatchNftDetails/${data}`, {} , headers);

const deleteBatch = (id, headers) =>
fetch("delete", `${API_URL}/nfts/batch/deleteBatch/${id}`, {}, headers);

const getAllCategories = (data, headers) => 
fetch("get", `${API_URL}/admins/categories/getAllCategories/${data.page}/${data.limit}/${data.contentType.toLowerCase()}`,{}, headers)

const uploadCoverImages = (data, headers) => 
fetch("put", `${API_URL}/nfts/nft/uploadCoverImages`,data, headers, true)


export const ApiService = {
  register,
  login,
  forgotPassword,
  resetPassword,
  createCollection,
  getCollectionDetailsByExternalLink,
  createCollectionNfts,
  whitelistUser,
  getCommission,
  getCollectionNfts,
  getCollections,
  getPublicNftsForMint,
  isWhiteListedUser,
  updateNftStatusToMinted,
  getMintedNftsCount,
  fetchFaq,
  getContracts,
  collectionGoLive,
  updateCustomization,
  getCustomization,
  changePassword,
  addAttribute,
  getContractByBlockChain,
  getNftDetails,
  deleteDraft,
  getMintedCountPerWallet,
  getInvitationCode,
  getInvitations,
  getInvitationDetailByInviteCode,
  deleteInvitation,
  updateMaxPerWallet,
  uploadWhitelistUsersCsv,
  getPrivateNftsForMint,
  updateCollection,
  getPrivateMintedNfts,
  updateNftOwner,
  getPrivateNftsSupplyCount,
  getTermsCondition,
  getHeaderValues,
  getTestimonials,
  getAllCollectionsHomePage,
  getService3Aws,
  getInfuraForMint,
  uploadNftNameDescCsv,
  updateBatch,
  getBatchesOfCollection,
  getBatchDetails,
  createNewBatch,
  verifyEmail,
  resendOneTimePass,
  publishBatch,
  getFaqDetails,
  getTotalBatchesSupply,
  getSoldOutNfts,
  sendOtpForCp,
  verificationBeforeCp,
  getMintingNowbatches,
  getSoldOutCollectionsOnHomePage,
  getLastBatchNftDetails,
  deleteBatch,
  getAllCategories,
  uploadCoverImages
};
