import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AdminActions } from "../../../redux/actions/admin.action";

const GenreFilter = ({ contentType, filterDrafts }) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState({
    list: [],
    totalCounts: 0,
  });

  const dropdownRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Choose One");

  const getCategories = async (obj) => {
    try {
      const res = await dispatch(AdminActions.getAllCategories(obj));
      if(res.list.length > 0) {
        setIsSelected("Choose One");
        setCategories({
          list: res.list,
        });
      } else {
        setCategories({
          list: [],
        });
      }
     
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenreChange = (value) => {
    let selectedCategory = categories.list.find((x) => x.name === value);
    console.log({ selectedCategory });
    filterDrafts({
      contentType: contentType,
      categoryId: selectedCategory._id,
    });
    setIsSelected(value);
  };

  useEffect(() => {
    if (contentType) {
      const obj = {
        limit: 500,
        page: 0,
        contentType,
      };
      getCategories(obj);
    }
  }, [contentType]);

  return (
    <>
      {categories?.list && categories?.list.length > 0 && (
        <Col lg={6}>
          <div className="collection_col col-padding d-flex mb-5">
            <h5>Choose genre</h5>

            <div className="dropdown" id="select_cat" ref={dropdownRef}>
              <div
                onClick={(e) => {
                  setIsActive(!isActive);
                }}
                className="dropdown-btn"
              >
                {selected}
                <span
                  className={isActive ? "fas fa-caret-up" : "fas fa-caret-down"}
                />
              </div>

              <div
                className="dropdown-content"
                style={{ display: isActive ? "block" : "none" }}
              >
                {categories?.list.map((category) => (
                  <div
                    className="item"
                    onClick={(e) => {
                      handleGenreChange(e.target.textContent);
                      setIsActive(!isActive);
                    }}
                  >
                    {category.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

export default GenreFilter;
