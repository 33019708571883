import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../header/Header";
import { Footer } from "..";
import "./LayoutStyle.scss";
import CustomizedLayout from "../../../HOC/customizedLayout";

const Layout = (props) => {
  return (
    <>
      {props.fromInside ? (
        <Container
          fluid
          className={`layoutStyle ${props.className}`}
          style={props.style}
        >
          {props.children}
        </Container>
      ) : (
        <>
          <Header style={props.style} />
          <Container
            fluid
            className={`layoutStyle ${props.className}`}
            style={props.style}
          >
            {props.children}
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};
const StyledLayout = CustomizedLayout(Layout);
export { StyledLayout, Layout };
