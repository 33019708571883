import React, { useMemo, useState } from "react";
import Sidebar from "../../../common/Sidebar/Sidebar";
import "./Mainsetting.scss";
import Setting from "../Setting/Setting";
import { Layout } from "../../../common";
import Otp from "../../public/login-signup/otp";

const Mainsetting = () => {
  console.count("Mainsetting loaded")
  const [showOtp, setShowOtp] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);

  const isOtp = useMemo(()=>{
    if(showOtp) return true

    return false;
  },[showOtp])

  return (
    <Layout>
      <div className="PrivateArea__content">
        <div className="PrivateArea__contentIn">
          <div className="dashLeft">
            <Sidebar showOtp={showOtp} setShowOtp={setShowOtp} showChangePass={showChangePass}/>
          </div>
          {isOtp && (
            <Otp
            isOtp={isOtp}
              fromInside={true}
              setShowOtp={setShowOtp}
              setShowChangePass={setShowChangePass}
            />
          )}
          {showChangePass && (
            <div className="dashRight">
              <Setting />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Mainsetting;
