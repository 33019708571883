import React from "react";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import SoldOutInfo from "../SoldOutInfo/SoldOutInfo";

const UserPageBasicInfo = ({
  collectionDetails,
  contractFetch,
  setDisableMinting,
  getCustomizedColor,
  notifyInvitationStatus,
  invitationType,
  walletAddress,
  setCollectionDetails,
  finalMinted,
}) => {
  return (
    <>
      {collectionDetails.isLive &&
        collectionDetails.currentBatchNo.batchStatus === "ON_GOING" && (
          <>
            <CountdownTimer
              collectionDetails={collectionDetails}
              getCustomizedColor={getCustomizedColor}
              setDisableMinting={setDisableMinting}
              batch={collectionDetails.currentBatchNo}
              setCollectionDetails={setCollectionDetails}
              invitationType={invitationType}
              notifyInvitationStatus={notifyInvitationStatus}
            />
            {walletAddress && (
              <h5
                style={getCustomizedColor("heading")}
                className="labeltext  mb-4"
              >
                Wallet
                <p
                  className="mb-0 ms-2 inputSec"
                  style={getCustomizedColor("inputField")}
                >
                  {walletAddress}
                </p>
                {walletAddress && (
                  <ConnectWallet
                    className="mx-2"
                    isChange="true"
                    collectionDetails={collectionDetails}
                  />
                )}
              </h5>
            )}

            <h5
              style={getCustomizedColor("heading")}
              className="labeltext mb-4"
            >
              Price per Unit : {collectionDetails.currentBatchNo?.price}{" "}
              {collectionDetails.blockChain.blockChain}
            </h5>
            <h5
              style={getCustomizedColor("heading")}
              className="labeltext  mb-4"
            >
              Max. Mint per Wallet :{" "}
              {collectionDetails.currentBatchNo?.mintPerWallet}
            </h5>
          </>
        )}
      {collectionDetails.currentBatchNo.batchStatus === "PUBLISHED" && (
        <SoldOutInfo
          collectionDetails={collectionDetails}
          finalMinted={finalMinted}
          getCustomizedColor={getCustomizedColor}
        />
      )}
    </>
  );
};

export default UserPageBasicInfo;
