import React, { useState } from "react";
import { Row } from "react-bootstrap";
import CategoriesFilter from "../CategoriesFilter/CategoriesFilter";
import GenreFilter from "../GenreFilter/GenreFilter";
import { Enviroments } from "../../../constants/constants";

const DraftFilters = ({ filterDrafts }) => {
  const { CONTENT_TYPE : {MUSIC,VIDEO} } = Enviroments;
  const [contentType, setContentType] = useState(null);

  const filterGenre = (value) => {
    setContentType(value.toUpperCase());
  };

  return (
    <Row>
      <CategoriesFilter filterDrafts={filterDrafts} filterGenre={filterGenre} />
      {contentType &&
        (contentType.toUpperCase() === MUSIC ||
          contentType.toUpperCase() === VIDEO) && (
          <GenreFilter contentType={contentType} filterDrafts={filterDrafts}/>
        )}
    </Row>
  );
};

export default DraftFilters;
