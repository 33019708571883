import { useEffect, useState } from "react";
import useTotalMintedCount from "../../../hooks/useTotalMintedCount";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import CreatorPageBasicInfo from "../CreatorPageBasicInfo/CreatorPageBasicInfo";
import UserPageBasicInfo from "../UserPageBasicInfo/UserPageBasicInfo";

function RoundImageDetails({
  getCustomizedColor,
  collectionDetails,
  setDisableMinting,
  walletAddress,
  contractFetch,
  isPrivate,
  mintedCountInfo,
  setCollectionDetails,
  invitationType,
  notifyInvitationStatus,
}) {
  console.important({ propsMintedCountInfo: mintedCountInfo });

  const { totalMinted } = useTotalMintedCount(
    mintedCountInfo,
    collectionDetails,
    isPrivate
  );

  const [finalMinted, setFinalMinted] = useState(0);

  const checkMinted = () => {
    return totalMinted > mintedCountInfo ? totalMinted : mintedCountInfo;
  };

  useEffect(() => {
    console.important({ totalMinted, mintedCountInfo });
    setFinalMinted(checkMinted());
  }, [totalMinted, mintedCountInfo]);

  return (
    <>
      {/* round image design */}
      {isPrivate && (
        <h5 style={getCustomizedColor("heading")} className="labeltext mb-4">
          Collection Name{" "}
          <p
            className="mb-0  ms-2 inputSec"
            style={getCustomizedColor("inputField")}
          >
            {" "}
            {collectionDetails.originalName}
          </p>
        </h5>
      )}
      {isPrivate ? (
        <CreatorPageBasicInfo
          collectionDetails={collectionDetails}
          contractFetch={contractFetch}
          setDisableMinting={setDisableMinting}
          getCustomizedColor={getCustomizedColor}
          notifyInvitationStatus={notifyInvitationStatus}
          invitationType={invitationType}
          walletAddress={walletAddress}
          setCollectionDetails={setCollectionDetails}
          finalMinted={finalMinted}
        />
      ) : (
        <UserPageBasicInfo
        collectionDetails={collectionDetails}
        contractFetch={contractFetch}
        setDisableMinting={setDisableMinting}
        getCustomizedColor={getCustomizedColor}
        notifyInvitationStatus={notifyInvitationStatus}
        invitationType={invitationType}
        walletAddress={walletAddress}
        setCollectionDetails={setCollectionDetails}
        finalMinted={finalMinted}
        />
      )}

      
    </>
  );
}

export default RoundImageDetails;
