import SpotTradeABI from "../assets/ABI/SpozzClubTokenizzerErc.json";
import FactorySpotTrade from "../assets/ABI/FactorySpozzClubTokenizzer.json";
import WalletManager from "./WalletManager.service";
import WalletManagerService from "./WalletManager.service";

class ContractService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {}

  compareAccounts = async (owner, web3) => {
    try {
      let accounts = await WalletManager.getAccounts(web3);
      if (accounts.length > 0) {
        if (accounts[0].toLowerCase() !== owner.toLowerCase()) {
          throw new Error("Accounts didn't match");
        }
        return accounts[0];
      }
    } catch (error) {
      throw error;
    }
  };

  checkWalletConnect = async (web3) => {
    try {
      const account = await WalletManager.getAccounts(web3);
      if (account.length === 0)
        throw new Error(`Please re-connect your wallet`);
    } catch (error) {
      throw error;
    }
  };

  mintTokenAndTransfer = async (
    data,
    txAmount,
    nftIds,
    balance,
    api,
    adminAddress,
    collectionId,
    web3,
    currentBatchNo
  ) => {
    try {
      await this.checkWalletConnect(web3);
      const {
        tokenIds,
        tokenUris,
        seller,
        price,
        adminCommission: commission,
        royality,
        proxyAddress: CONTRACT_ADDRESS,
        mintPerWallet
      } = data;

      let { buyer } = data;
      const contract = await WalletManager.initContract(
        SpotTradeABI.abi,
        CONTRACT_ADDRESS,
        web3
      );

      if (Number(txAmount) > Number(balance))
        throw new Error("Insufficient funds");

      buyer = await this.compareAccounts(buyer, web3);
      let adminCommission  = commission*tokenIds.length;
      // console.table({adminCommission})
      // if(tokenIds.length >= 9){
      //   adminCommission  = adminCommission*(99/100);
      // }
      // adminCommission = Math.floor(adminCommission);

      console.important({adminCommission, nfts: tokenIds.length})
      const estimatedGas = await contract.methods
        .mintTokenAndTransfer({
          tokenIds,
          tokenUris,
          seller,
          price,
          adminCommission,
          adminWallet: adminAddress,
        })
        .estimateGas({ from: buyer, value: String(txAmount) });

      return new Promise(async (resolve, reject) => {
        contract.methods
          .mintTokenAndTransfer({
            tokenIds,
            tokenUris,
            seller,
            price,
            adminCommission,
            royality,
            adminWallet: adminAddress,
          })
          .send({ from: buyer, value: txAmount, gas: estimatedGas })
          .on("error", (error) => {
            // console.log("Contract error here");
            // console.log(error);
            reject(error);
          })
          .on("transactionHash",  (txHash) => {
            console.log(txHash);
          })
          .on("receipt", (receipt) => {
            console.log("STEP 3", { receipt });
          })
          .on("confirmation", async (confirmationNumber, receipt) => {
            console.log("STEP 4", { confirmationNumber, receipt });
            if(receipt && confirmationNumber == 1)
            await api(
              {
                nft: nftIds,
                collectionId,
                walletAddress: buyer.toLowerCase(),
                currentBatchNo:currentBatchNo._id
              },
              {}
            );
            resolve(true);
          });
      });
    } catch (error) {
      throw error;
    }
  };

  createNewERC21 = async (
    name,
    serialId,
    proxyAddress,
    symbol,
    from,
    api,
    apiData,
    headers,
    web3,
    royality,
    updateApi,
    batch,
    totalSupply
  ) => {
    console.log({ apiData });
    let updateApiData = {...apiData};
    let updatedId = updateApiData._id;
    delete updateApiData._id;

    let updateBatchData = {...apiData};
    delete updateBatchData.contractName
    delete updateBatchData._id;
    if(!updateBatchData.isPrivateMintingAllowed){
      delete updateBatchData.startMint
      delete updateBatchData.endMint
     
    }

    try {
      await this.checkWalletConnect(web3);
      console.log({proxyAddress});
      const contract = await WalletManagerService.initContract(
        FactorySpotTrade.abi,
        proxyAddress,
        web3
      );
      console.log({contractName:apiData.contractName, symbol, serialId, from, royality:royality*100})
      
      // const estimatedGas = await web3.eth.getGasPrice()
      const estimatedGas = await contract.methods
        .createNewErc721(apiData.contractName.toUpperCase(), symbol.toUpperCase(), serialId, from, royality*100, from, totalSupply)
        .estimateGas({ from: from, value: 0 });
      
        console.important({estimatedGas})

      return new Promise((resolve, reject) => {
        contract.methods
          .createNewErc721(apiData.contractName.toUpperCase(), symbol.toUpperCase(), serialId, from, royality*100, from, totalSupply)
          .send({ from: from, value: 0, gas: estimatedGas })
          .on("error", (error) => {
            // console.log("Contract error here");
            // console.log(error);
            reject(error);
          })
          .on("transactionHash", async (txHash) => {
            console.log({ txHash });
            await updateApi(batch._id,{...updateBatchData}, headers)
            // api({...apiData,transactionHash:txHash}, headers);
           
          })
          .on("receipt", (receipt, abc) => {
            console.log({abc});
            console.log("STEP 3", { receipt });
          })
          .on("confirmation", async (confirmationNumber, receipt) => {
            console.log("STEP 4", { confirmationNumber, receipt });
            if(receipt && confirmationNumber == 1) {
              let res = await api(
              { ...apiData, currentBatchNo:batch._id, transactionHash: receipt.transactionHash },
                headers
              );
              resolve(true);
            }
          });
      });
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  mintTokenByCreator = async (data, nftIds, collectionId, api, web3, currentBatchNo) => {
    try {
      await this.checkWalletConnect(web3);
      const { tokenIds, tokenUris, proxyAddress: CONTRACT_ADDRESS } = data;

      let { buyer } = data;

      const contract = await WalletManager.initContract(
        SpotTradeABI.abi,
        CONTRACT_ADDRESS,
        web3
      );
      buyer = await this.compareAccounts(buyer, web3);

      const estimatedGas = await contract.methods
        .mintTokenByCreator(tokenIds, tokenUris)
        .estimateGas({ from: buyer, value: 0 });
      return new Promise(async (resolve, reject) => {
        contract.methods
          .mintTokenByCreator(tokenIds, tokenUris)
          .send({ from: buyer, value: 0, gas: estimatedGas })
          .on("error", (error) => {
            console.log("Contract error here");
            console.log(error);
            reject(error);
          })
          .on("transactionHash", (txHash) => {
            console.log(txHash);
          })
          .on("receipt", (receipt) => {
            console.log("STEP 3", { receipt });
          })
          .on("confirmation", async (confirmationNumber, receipt) => {
            console.log("STEP 4", { confirmationNumber, receipt });
            if(receipt && confirmationNumber == 1)
            await api(
              {
                nft: nftIds,
                collectionId,
                walletAddress: buyer.toLowerCase(),
                currentBatchNo:currentBatchNo._id
              },
              {}
            );
            resolve(true);
          });
      });
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  transferTokenByCreator = async (data, api, headers, web3) => {
    try {
      await this.checkWalletConnect(web3);
      let { tokenId, buyerAddress, contract: CONTRACT_ADDRESS, owner } = data;
      const contract = await WalletManager.initContract(
        SpotTradeABI.abi,
        CONTRACT_ADDRESS,
        web3
      );
      const estimatedGas = await contract.methods
        .transferTokenByCreator(buyerAddress, tokenId)
        .estimateGas({ from: owner, value: 0 });

      return new Promise(async (resolve, reject) => {
        contract.methods
          .transferTokenByCreator(buyerAddress, tokenId)
          .send({ from: owner, value: 0, gas: estimatedGas })
          .on("error", (error) => {
            // console.log("Contract error here");
            // console.log(error);
            reject(error);
          })
          .on("transactionHash", (txHash) => {
            console.log({txHash});
          })
          .on("receipt", (receipt) => {
            console.log("STEP 3", { receipt });
            
          })
          .on("confirmation", async (confirmationNumber, receipt) => {
            console.log("STEP 4", { confirmationNumber, receipt });
            if(receipt && confirmationNumber == 1)
            await api(
              {
                tokenId,
                owner: buyerAddress,
                transactionHash: receipt.transactionHash,
              },
              headers
            );
            resolve(true);
          });
      });
    } catch (error) {
      throw error;
    }
  };

  withdrawFunds = async (data, web3) => {
    try {
      await this.checkWalletConnect(web3);
      let {  contract: CONTRACT_ADDRESS } = data;
      const contract = await WalletManager.initContract(
        SpotTradeABI.abi,
        CONTRACT_ADDRESS,
        web3
      );
      const creator = await contract.methods.owner().call();
      // console.important({owner: o})

      const estimatedGas = await contract.methods
        .withdrawAmount(creator)
        .estimateGas({ from: creator, value: 0 });

      return new Promise(async (resolve, reject) => {
        contract.methods
          .withdrawAmount(creator)
          .send({ from: creator, value: 0, gas: estimatedGas })
          .on("error", (error) => {
            // console.log("Contract error here");
            // console.log(error);
            reject(error);
          })
          .on("transactionHash", (txHash) => {
            console.log({txHash});
          })
          .on("receipt", (receipt) => {
            console.log("STEP 3", { receipt });
            
          })
          .on("confirmation", async (confirmationNumber, receipt) => {
            console.log("STEP 4", { confirmationNumber, receipt });
            if(receipt && confirmationNumber == 1)
          
            resolve(true);
          });
      });
    } catch (error) {
      throw error;
    }
  };
}

export default new ContractService();
