import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import BatchDropdownOptions from "../BatchDropdownOptions/BatchDropdownOptions";
import useS3Config from "../../../hooks/useS3Config";

function BatchDraft({ batchData, collectionId, setKey, setBatchData }) {
  const { keys: s3keys } = useS3Config();
  console.log({batchData})
  return (
    <Container className="ContMain">
      <Row className="banner_row ">
        <Col lg={9}>
          <div className="collection_col">
            <h2 className="main-heading ">Drafts </h2>
          </div>
        </Col>
      </Row>

      <Row className="mb-5">
        {batchData.list.filter(x=>x.batchStatus === "DRAFT").map(row =>
          <>
            <Col sm={6} lg={3} key={row._id}>
              <div className="coll-new mb-3">
                <div className="imageSec">
                  <Image fluid src={row.collection.logo} alt="name" />
                </div>
                <h5 className="mt-2">Batch : {row.batchNo}</h5>
              </div>
            </Col>

            <BatchDropdownOptions
              row={row}
              s3keys={s3keys}
              setKey={setKey}
              setBatchData={setBatchData}
            />
          </>
        )}
      </Row>
    </Container>
  );
}

export default BatchDraft;
