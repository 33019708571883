import React from "react";
import "./CreatorButton.scss";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CustomizeActions } from "../../../redux/actions/customize.action";
import { CollectionActions } from "../../../redux/actions/collection.action";
import WalletManagerService from "../../../services/WalletManager.service";
import Web3 from "web3";
import contractErrorsService from "../../../services/contractErrors.service";
import { toasts } from "../Toast/Toast";

function CreatorButtons({
  getCustomizedColor,
  handleShow,
  collectionDetails,
  contractFetch,
  setShowUpdateDatesModal,
  history,
}) {
  const dispatch = useDispatch();

  const goToCollectionBatches = () => {
    history.push({
      pathname: `/auth/collection/batches/${collectionDetails._id}`,
    });
  };

  const customize = async () => {
    await dispatch(CustomizeActions.setCustomizeButtonClicked(true));
    handleShow();
  };

  const withdrawAmount = async () => {
    try {
      const provider = WalletManagerService.initialize();
      const web3 = new Web3(provider);
      const data = {
        contract: collectionDetails.contract.contractAddress
      }

      const res = await dispatch(CollectionActions.withdrawAmount(data, web3))
    } catch (error) {
      console.log(error)
      let message = contractErrorsService.handleContractError(error)
      toasts.error(message)
    }
  }

  return (
    <>
      <Button
        variant="primary"
        style={getCustomizedColor("button")}
        className="cmnBtn creator_btn"
        onClick={() => customize()}
      >
        Customize
      </Button>
      {collectionDetails?.currentBatchNo.isPrivateMintingAllowed &&
        !!contractFetch.contract && (
          <Button
            variant="primary"
            style={getCustomizedColor("button")}
            className="cmnBtn creator_btn ms-3"
            onClick={() =>
              history.push({
                pathname: `/auth/private-nfts/${collectionDetails._id}`,
                state: {
                  collectionDetails,
                  contract: contractFetch.contract,
                },
              })
            }
          >
            See Private minted nfts
          </Button>
        )}

      <Button
        variant="primary"
        style={getCustomizedColor("button")}
        className="cmnBtn creator_btn ms-3"
        disabled={collectionDetails?.currentBatchNo.batchStatus === "PUBLISHED" || collectionDetails?.currentBatchNo.batchStatus === "DRAFT"}
        onClick={() => setShowUpdateDatesModal(true)}
      >
        Extend minting times
      </Button>

      <Button
        variant="primary"
        style={getCustomizedColor("button")}
        className="cmnBtn creator_btn ms-3"
        onClick={goToCollectionBatches}
      >
        Batches
      </Button>
      {/* {!!contractFetch.contract && (
        <Button
          variant="primary"
          style={getCustomizedColor("button")}
          className="cmnBtn creator_btn ms-3"
          onClick={withdrawAmount}
        >
          Withdraw
        </Button>
      )} */}
    </>
  );
}

export default CreatorButtons;
