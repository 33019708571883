import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FaqActions } from "../../../../redux/actions/faq.action";
import { Layout } from "../../../common";
import "./FaqDetails.scss"

function FaqDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [faqDetail, setFaqDetail] = useState({
    question:"",
    answer:""
  })

  const getFaqDetails = async () => {
    try {
      const res = await dispatch(FaqActions.getFaqDetails(id))
      setFaqDetail({
        question:res.question,
        answer:res.answer
      })
    } catch (error) {
      console.log(error);
    }
  }

  function createMarkup(answer) {
    return { __html: answer };
  }
  
  useEffect(() => {
    getFaqDetails()
  }, [id]);

  return (
    <Layout>
      <Container>
        <div>
          <div>
            <h2 className="mt-4 maintitle text-center">{faqDetail.question}</h2>
          </div>
          <div className="text-center custom-padding" dangerouslySetInnerHTML={createMarkup(faqDetail.answer)}>
            
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default FaqDetails;
