import React from "react";
import { Button } from "react-bootstrap";
import "./ButtonCommon.scss";

const ButtonCommon = (props) => {
  const { buttonText, onClick, className } = props;
  return (
    <Button onClick={onClick} className={`commonBtn ${className}`}>
      {buttonText}
      <svg
        width="32"
        height="29"
        viewBox="0 0 32 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.319 0.503784H18.9719C18.9718 3.23497 19.5602 5.91413 20.6732 8.2511C21.7863 10.5881 23.3816 12.4938 25.2865 13.7618H0.896973V15.4725H25.2865C23.3816 16.7406 21.7863 18.6463 20.6732 20.9833C19.5602 23.3202 18.9718 25.9994 18.9719 28.7306H20.319C20.319 25.2143 21.4189 21.8421 23.3768 19.3557C25.3347 16.8694 28.3046 15.4725 31.0735 15.4725V13.7618C28.3046 13.7618 25.3347 12.365 23.3768 9.87866C21.4189 7.39229 20.319 4.02004 20.319 0.503784Z"
          fill="#7448FF"
        />
      </svg>
    </Button>
  );
};

export { ButtonCommon };
