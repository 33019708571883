import React from "react";

function PausedBatchInfo({ getCustomizedColor }) {
  return (
    <h5 style={getCustomizedColor("heading")} className="mt-3">
      Note:-{" "}
      <span style={getCustomizedColor("text")} className="max_wallet_per_mint">
        The batch is currently paused, please extend times
      </span>
    </h5>
  );
}

export default PausedBatchInfo;
