import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CollectionActions } from "../../../redux/actions/collection.action";
import fileValidationService from "../../../services/fileValidation.service";

function CustomizeLogoImage({ collectionDetails }) {
  const dispatch = useDispatch()
  const [selectedImage, setSelectedImage] = useState(null);
  const fileRef = useRef();

  const acceptedfileList = [
    "image/png",
    "image/jpeg",
    // ".glb",
    // ".gltf"
  ];

  const handleClick = () => {
    fileRef.current.click();
    console.log(fileRef.current.value);
  };

  const selectFile = (e) => {
    const file = e.target.files[0];
    console.log({ file });
    changeLogo(file);
  };

  const changeLogo = async (file) => {
    let res = fileValidationService.validate(file, acceptedfileList);
    if (res) {
      var reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
      await dispatch(CollectionActions.saveCollectionLogoImage({
        collectionId: collectionDetails._id,
        image: file,
      }));
    } else {
      toast.error("Please select image file");
    }
  };

  return (
    <div className="frame_sec">
      <div className="frame square mt-0">
        <Image
          className="imgSec"
          src={selectedImage || collectionDetails.logo}
        />{" "}
        <span />
      </div>
      <div className="upload_sec">
        <Button className="upload_btn" onClick={handleClick}>
          Change Image
        </Button>
        <input type="file" hidden ref={fileRef} onChange={selectFile} />
        <p className="upload_text text-white">
          <i>Image Format (500 x 500)</i>
        </p>
      </div>
    </div>
  );
}

export default CustomizeLogoImage;
