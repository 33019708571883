import React, { memo, useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./Draft.scss";
import { useDispatch } from "react-redux";
import {
  CollectionActions,
  getContractByBlockChain,
} from "../../redux/actions/collection.action";
import Pagination from "react-js-pagination";
import { withRouter } from "react-router-dom";
import { Enviroments } from "../../constants/constants";
import { toasts as toast } from "../common/Toast/Toast";
import Web3 from "web3";
import WalletManagerService from "../../services/WalletManager.service";
import useS3Config from "../../hooks/useS3Config";
import DropdownOptions from "../common/DropdownOptions/DropdownOptions";
import contractErrorsService from "../../services/contractErrors.service";
import { ReleaseDateService } from "../common/ReleaseDateConfirmationModal/ReleaseDataConfirmationService";
import { NotificationActions } from "../../redux/actions/notification.action";
import DraftFilters from "../common/DraftFilters/DraftFilters";

function Draft({ setKey }) {
  console.count("draft re-renders");
  const [collections, setCollections] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const [draftReleaseDate, setDraftReleaseDate] = useState(null);

  const [page, setPage] = useState(0);
  const limit = 3;
  const numberOfPages = Math.ceil(totalCount / limit);
  const {
    COLLECTION_TYPES: { DRAFT },
    COUNTDOWN_TYPES,
  } = Enviroments;

  const { keys: s3keys } = useS3Config();

  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e == 0 ? 0 : e - 1,
      filters: {},
      type: DRAFT,
    };
    // getCollections(obj);
  };

  const getCollections = async (data) => {
    const { getCollections } = CollectionActions;
    try {
      const res = await dispatch(getCollections(data));
      await dispatch(
        NotificationActions.checkDisabledCollection(res.data.data?.list)
      );
      setCollections(res.data.data);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  };

  const filterDrafts = async (data) => {
    console.important({filterDraftData: data});
    getCollections({
      page: page == 0 ? 0 : page - 1,
      limit: limit,
      filters: data,
      type: DRAFT,
    });
  }

  useEffect(() => {
    getCollections({
      page: page == 0 ? 0 : page - 1,
      limit: limit,
      filters: {},
      type: DRAFT,
    });
  }, [page]);

  const collectionGoLive = async (obj, publishParams) => {
    try {
      let data = {
        _id: obj._id,
        ...publishParams,
        contractName: obj.contractName,
      };
      console.log({
        localDates: ReleaseDateService.checkLocalDates(publishParams),
      });

      const batch = obj.batch;
      console.log({ goLiveData: data });
      const { collectionGoLive } = CollectionActions;
      const provider = WalletManagerService.initialize();
      const web3 = new Web3(provider);
      let response = await dispatch(
        getContractByBlockChain(obj, data, web3, batch)
      );

      // let res = await dispatch(collectionGoLive(data));
      toast.success("Collection is live now..!!");
      setDraftReleaseDate(null);
      setTimeout(() => {
        setKey("collections");
      }, 2000);
    } catch (error) {
      setDraftReleaseDate(null);
      if (error) {
        const message = contractErrorsService.handleContractError(error);
        toast.error(message);
      }
    }
  };

  return (
    <Container className="ContMain">
      <Row className="banner_row_col mt-3 ">
        <Col lg={9}>
          <div className="collection_col">
            <h2 className="main-heading ">Drafts </h2>
          </div>
        </Col>
      </Row>


      <DraftFilters filterDrafts={filterDrafts}/>
    
      <Row className="mb-5">
        {collections.totalCounts > 0 ? (
          <>
            {collections.list.map((row, i) => (
              <>
                <Col sm={6} lg={3} key={row._id}>
                  <div className="coll-new mb-3">
                    <div className="imageSec">
                      <Image fluid src={row.logo} alt="name" />
                    </div>
                    <h5 className="mt-2">{row.originalName}</h5>
                  </div>
                </Col>
                <DropdownOptions
                  row={row}
                  collections={collections}
                  s3keys={s3keys}
                  collectionGoLive={collectionGoLive}
                  getCollections={getCollections}
                />
              </>
            ))}
          </>
        ) : (
          <p>No Drafts Found</p>
        )}
      </Row>
      {totalCount > limit && (
        <Row>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        </Row>
      )}
    </Container>
  );
}

export default withRouter(memo(Draft));
