import { Enviroments } from "../constants/constants";

class UtilService {
  formatWalletAddress(str) {
    let firstFour = str.slice(0, 4);
    let lastFour = str.slice(-4);
    return firstFour + "..." + lastFour;
  }

  formatBatchStatus(status) {
    if (status) {
      if (status === "ON_GOING" || status === "PAUSED") {
        return "CURRENT";
      } else {
        return status;
      }
    }

    return "";
  }

  formatCountMessage(count, singleTerm, pluralTerm) {
    if (count === 1) {
      return `You can only upload ${count} ${singleTerm}`;
    } else {
      return `You can only upload ${count} ${pluralTerm}`;
    }
  }

  formatContentTypeTooltipText(text, contentType) {
    switch (contentType) {
      case Enviroments.CONTENT_TYPE.MUSIC: {
        return text.replace("type", "song");
      }
      case Enviroments.CONTENT_TYPE.VIDEO: {
        return text.replace("type", "video");
      }
      default:
        return "";
    }
  }
}

export default new UtilService();
