import iconMatamask from "../assets/images/metamask_icon.png";
import iconWallet from "../assets/images/wallet_icon.svg";
import iconCoinbase from '../assets/images/coinbase_icon.svg'

const API_URL = process.env.REACT_APP_API_URL;
const DOMAIN = process.env.REACT_APP_DOMAIN;
const ETH_RPC_URL = process.env.REACT_APP_ETH_RPC_URL;
const BSC_RPC_URL = process.env.REACT_APP_BSC_RPC_URL;
const MATIC_RPC_URL = process.env.REACT_APP_MATIC_RPC_URL;
const ENV = process.env.REACT_APP_ENV;

const COLLECTION_TYPES = Object.freeze({
  DRAFT: "DRAFT",
  LIVE: "LIVE",
});

const NFT_LIMITS = {
  UPLOAD_LIMIT: 10000, // file upload limit
  FILE_SIZE_LIMIT: 10, // in mb
  VIDEO_SIZE_LIMIT:50
};

const COUNTDOWN_TYPES = {
  whitelist: {
    start_time: {
      time: 0,
      text: "whitelisting will start in",
      select_placeholder:"Select whitelist start time",
      status:"Minting closed"
    },
    end_time: {
      time: 0,
      text: "whitelisting ends in",
      select_placeholder:"Select whitelist end time",
      status:"Whitelisting active"
    }
  },

  presale: {
    start_time: {
      time: 0,
      text: "pre-sale will start in",
      select_placeholder:"Select pre-sale start time",
      status:"Whitelisting ended"
    },
    end_time: {
      time: 0,
      text: "pre-sale ends in",
      select_placeholder:"Select pre-sale end time",
      status:"Pre-Sale active"
    },
  },
  
  postsale: {
   start_time:{
    time: 0,
    text: "main-sale will start in",
    select_placeholder:"Select main-sale start time",
    status:"Pre-Sale ended"
   },
   end_time:{
    time: 0,
    text: "main-sale ends in",
    select_placeholder:"Select main-sale end time",
    status:"Main-Sale active"
   }
  },
};

const PUBLISH_DATES = {
  whitelist:{
    start_time:new Date(),
    end_time:new Date()
  },
  presale:{
    start_time:new Date(),
    end_time:new Date(),
  },
  mainsale:{
    start_time:new Date(),
    end_time:new Date(),
  },
}

const PUBLISH_PARAMS = {
  whiteListStartTime:"",
  mainSaleStartsIn:"",
  isWhiteListedEnable:"",
  preSaleEndTime:"",
  mainSaleEndsIn:"",
  preSaleStartTime:"",
  whiteListEndTime:""
}

const MINTING_TYPES = {
  private:"PRIVATE",
  public:"PUBLIC",
  info:"INFO"
}

const INVITATION_TYPES = {
    private:"PRIVATE",
    public:"PUBLIC",
    csv:"CSV"
}

const WALLETS = [
  {
    type:"METAMASK",
    text:"MetaMask",
    image:iconMatamask
  },
  {
    type:"WALLETCONNECT",
    text:"WalletConnect",
    image:iconWallet
  },
  {
    type:"COINBASE",
    text:"Coinbase",
    image:iconCoinbase
  }

]

const WALLET_TYPES = {
  metamask: "METAMASK",
  walletConnect:"WALLETCONNECT",
  coinbase:"COINBASE"
}

const WALLET_PROVIDERS = {
  metamask:"isMetaMask",
  coinbase:"isCoinbaseWallet"
}

let WC_RPC = ENV == "development" || ENV == "staging" ? 
 {
  5: `${ETH_RPC_URL}`,
  80001:`${MATIC_RPC_URL}`,
  97:`${BSC_RPC_URL}`
}: {
  1: `${ETH_RPC_URL}`,
  137: `${MATIC_RPC_URL}`,
  56 : `${BSC_RPC_URL}`
}

const QR_CODE_LINKS = {
  mobileLinks: [
    // "rainbow",
    "metamask",
    // "argent",
    // "trust",
    // "imtoken",
    // "pillar",
  ],
  desktopLinks: [],
}

const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;


const COINBASE_WALLET_CONSTANTS = {
  APP_NAME : "My Awesome App",
  APP_LOGO_URL : "https://example.com/logo.png",
  DEFAULT_ETH_JSONRPC_URL : ENV == "development" || ENV == "staging" ? `https://goerli.infura.io/v3` : `https://mainnet.infura.io/v3`,
  DEFAULT_CHAIN_ID : ENV == "development" || ENV == "staging" ? 5 : 1,
}


const TOOLTIP = {
  CREATE:{
    PROJECT_NAME:"This is the name of the project used on the Tokenizzer",
    CONTRACT_NAME:"This is the name of the collection that is written to the metadata that belong to the smart contract. You cannot change this later. However, some marketplaces let you to change a collection name once you list your collection on the marketplace.",
    SYMBOL:"This a short text of maximum 6 characters that is written to the metadata of the contract. You cannot change the symbol text later.",
    DESCRIPTION:"This is the description of your collection; this text is written to the metadata of the contract. You cannot change this text later. However, some marketplaces let you to change a description once you list your collection on the marketplace.",
    PRICE:"This is the minting price per NFT. The price can be changed by the creator later.",
    WALLET_ADDRESS:"This is the address of the creator (=your wallet address), where the income of the NFT sale will received. You cannot change this wallet later. If you want to use another wallet, you need to change it now.",
    BLOCKCHAIN:"You can choose between the blockchains available in the dropdown menu. You will not be able to change the blockchain once your contract was created.",
    MAX_MINT_PER_WALLET:"This parameter sets the maximum number NFTs that can be minted by an individual wallet. This number cannot be bigger than the maximum number of assets that you upload per batch (or per collection if you do not choose batch minting). The max mint parameter can be changed by the creator during pre-sales and minting if required.",
    ROYALITY:"Creator royalties are paid to the original creator wallet each time the NFT is changing hands. Not all marketplaces may enforce the payment of creator royalties. In some marketplaces the owner of a collection can change the % of royalties paid. Creator royalties are typically set between 0% and 10%. Some marketplaces apply a maximum of 10% for creator royalties.",
    LOGO:"This is the image that represents your collection on the Tokenizzer website.",
    SUPPLY:"The 'max supply' parameter specifies the maximum number of NFTs that the contract can hold.",
    MUSIC_CATEGORY:"Select from the list of genres or music types that users can choose from.",
    VIDEO_CATEGORY:"Select from the list of video types that users can choose from."

  },
  PRIVATE_MINTING: {
    START:"Start of private mint",
    END:"End of private mint"
  },
  INVITATION: {
    PUBLIC_LINK:"The public link enables users to participate in the whitelisting process from the beginning of the whitelisting period until its conclusion. This same link can be used for both the pre-sale and main sale.",
    PRIVATE_LINK:"",
    CSV_LINK:"This link can be shared with all users who have been whitelisted with their wallets on the uploaded CSV file."
  },
  UPLOAD_NFT:{
    UPLOAD_COVERS:"This is to upload the cover images that will represent each type in the collection"
  }
}

const INVITATION_STATUS = {
  ACTIVE:"ACTIVE",
  INACTIVE:"INACTIVE"
}

const SERVER_ERRORS = {
  USER_DISABLED_WARNING1 : "Error: Your account has been disabled by admin, please contact for more details.",
  USER_DISABLED_WARNING2: "Your account has been disabled by admin, please contact for more details."
}

const CONTENT_TYPE = {
  MUSIC:"MUSIC",
  IMAGES:"IMAGE",
  VIDEO:"VIDEO",
}


export const Enviroments = {
  API_URL,
  COLLECTION_TYPES,
  NFT_LIMITS,
  COUNTDOWN_TYPES,
  PUBLISH_DATES,
  PUBLISH_PARAMS,
  DOMAIN,
  MINTING_TYPES,
  INVITATION_TYPES,
  WALLETS,
  WALLET_TYPES,
  WC_RPC,
  QR_CODE_LINKS,
  ENCRYPTION_KEY,
  WALLET_PROVIDERS,
  COINBASE_WALLET_CONSTANTS,
  TOOLTIP,
  INVITATION_STATUS,
  SERVER_ERRORS,
  CONTENT_TYPE
};
