import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import { Footer } from "../../../common";
import Header from "../../../common/header/Header";
import LeftHeaderSection from "../../../common/LeftHeaderSection/LeftHeaderSection";
import MintingNow from "../../../common/MintingNow/MintingNow";
import SoldOut from "../../../common/SoldOut/SoldOut";
import Faq from "../faq/Faq";
import "./landing.scss";
import Slider from "../../../common/slider/Slider";

function Landing() {
  const imageUrl = "../../../../assets/images/circle.png";

  const [collections, setCollections] = useState({
    list: [],
    totalCounts: 0,
  });

  const [mintingNowIndex, setMintingNowIndex] = useState(0);

  const [soldOutCollections, setSoldOutCollections] = useState({
    list: [],
    totalCounts: 0,
  });

  const [soldOutIndex, setSoldOutIndex] = useState(0);

  const dispatch = useDispatch();

  const getMintingNowbatches = async (data) => {
    try {
      let res = await dispatch(
        CollectionActions.getMintingNowbatches(data.page, data.limit)
      );
      console.log({ getMintingNowbatches: res });
      if (res) {
        setCollections(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSoldOutCollectionsOnHomePage = async (data) => {
    try {
      let res = await dispatch(
        CollectionActions.getSoldOutCollectionsOnHomePage(data.page, data.limit)
      );
      console.log({ getSoldOutCollectionsOnHomePage: res });
      setSoldOutCollections({
        list: res.list,
        totalCounts: res.totalCounts
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let obj = {
      page: mintingNowIndex,
      limit: 8,
    };
    getMintingNowbatches(obj);
  }, [mintingNowIndex]);

  useEffect(() => {
    let obj = {
      page: soldOutIndex,
      limit: 8,
    };
    getSoldOutCollectionsOnHomePage(obj);
  }, [soldOutIndex]);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    fade: true,
  };

  return (
    <>

      <div className="landing-page">
        <Header />
        <section className="banner_slider">
          <Container>
            <Row className="launchpad-Sec">
              <LeftHeaderSection />
              <Col xl={5} lg={4}>
                <Slider />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="cardSec">
          <Container>
            <Faq />
            {collections.totalCounts > 0 && (
              <MintingNow
                settings={settings}
                collections={collections}
                setMintingNowIndex={setMintingNowIndex}
              />
            )}
            {soldOutCollections.totalCounts > 0 && (
              <SoldOut
                settings={settings}
                soldOutCollections={soldOutCollections}
                setSoldOutIndex={setSoldOutIndex}
              />
            )}
          </Container>
        </section>
        {/* <section className="accordianBg">
          <Container>
          </Container>
        </section> */}
        <Footer />
      </div>
    </>
  );
}

export default Landing;
