import { Enviroments } from "../constants/constants";

const isContentTypeValid = (type) => {
  return Object.values(Enviroments.CONTENT_TYPE).includes(type);
};

export const getTypeHeader = (type) => {
  if (isContentTypeValid(type)) {
    return convertFirstLetterCaps(type);
  }
};

const convertFirstLetterCaps = (value) => {
  if (!value) return "";
  return value.charAt(0) + value.slice(1).toLowerCase();
};

export const getSupportedMimeTypesByType = (type) => {
  const { IMAGES, MUSIC, VIDEO } = Enviroments.CONTENT_TYPE;
  if (isContentTypeValid(type)) {
    if (type === IMAGES) {
      return ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/svg"];
    } else if (type === MUSIC) {
      return ["audio/mpeg", "audio/flac", "audio/mp4"];
    } else if (type === VIDEO) {
      return ["video/mp4"];
    }
  }
};

export const getToolTipCategoryText = (type) => {
  const {
    CONTENT_TYPE,
    TOOLTIP: { CREATE },
  } = Enviroments;

  if (type === CONTENT_TYPE.VIDEO) {
    return CREATE.VIDEO_CATEGORY;
  } else if (type === CONTENT_TYPE.MUSIC) {
    return CREATE.MUSIC_CATEGORY;
  } else return "";
};
