import React from 'react'
import { Button, Image } from 'react-bootstrap'
import frame from "../../../assets/images/Frame.svg";

function RoundImageLogoSection({banners, collectionDetails}) {
    return (
        <section>
        <div className="text-center mb-4">
          {banners.banner1.isShow == false && (
            <Image
              className={`nftImage`}
              src={collectionDetails.logo}
            />
          )}
          <div>
            <Button className="frame">
              <Image className="imgSec" src={frame} /> <span /> MINTING TECH. provided by SPOZZ.Club
            </Button>
          </div>
        </div>
        </section>
    )
}

export default RoundImageLogoSection
