import types from "../types";

const saveBatchDraft = (data) => {
    return {
      type: types.batch.SAVE_BATCH_DRAFT,
      payload: data,
    };
  };

export const BatchReduxActions = {
    saveBatchDraft
};
