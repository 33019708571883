import React from "react";
import { ButtonCommon } from "../buttonCommon/ButtonCommon";

const FaqSlide = ({ src, text, url }) => {
  const goToOutsidePage = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="slick_slide">
      <img src={src} alt="" />
      <div className="slide_content d-flex justify-content-center">
        <h2>{text}</h2>
        <ButtonCommon
          onClick={() =>
            goToOutsidePage(url)
          }
          className="read_btn"
          buttonText="Read More"
        />
      </div>
    </div>
  );
};

export default FaqSlide;
