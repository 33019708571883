import React, { useState } from "react";
import { useEffect } from "react";
import { batch, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Layout } from "../layout/Layout";
import { CollectionActions } from "../../../redux/actions/collection.action";
import CollectionBasicDetails from "../CollectionBasicDetails/CollectionBasicDetails";
import Nft from "../Nft/Nft";

function BatchDetails() {
  const { batchId } = useParams();
  const dispatch = useDispatch();
  const [batchDetails, setBatchDetails] = useState({});
  const [nfts, setNfts] = useState([]);
  const [totalCount, setTotalCount] = useState([]);

  const getBatchDetails = async () => {
    try {
      let res = await dispatch(CollectionActions.getBatchDetails({ batchId }));
      console.log(res);
      setBatchDetails(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getCollectionNfts = async (obj) => {
    const {    } = CollectionActions;
    let data = {
      ...obj,
      collection: batchDetails.collectionId._id,
      currentBatchNo: batchDetails._id,
      pageType:"CREATOR"
    };
    try {
      let res = await dispatch(getCollectionNfts(data));
      setNfts(res.data.data.list);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getBatchDetails();
  }, []);

  useEffect(() => {
    if(Object.keys(batchDetails).length > 0){
      getCollectionNfts({
        limit: 8,
        page:  0,
        filters: {},
        currentBatchNo:batchDetails._id,
        pageType:"CREATOR"
      })
    }
  },[batchDetails])

  return (
    <Layout>
      {batchDetails && Object.keys(batchDetails).length > 0 && (
        <>
          <CollectionBasicDetails collectionDetails={batchDetails} />
          <Nft
            nfts={nfts}
            getNfts={getCollectionNfts}
            totalCount={totalCount}
            collectionId={batchDetails.collectionId._id}
            parent="nftDetails"
            currentBatchNo={batchDetails._id}
          />
        </>
      )}
    </Layout>
  );
}

export default BatchDetails;
