import React from "react";
import { Player, ControlBar, BigPlayButton } from "video-react";
// import "./VideoPlayer.scss"; // Import your CSS file for styling
import 'video-react/dist/video-react.css'; // import css

function VideoPlayer({ src, cover }) {
  return (
    <div className="video-player">
      <Player
        fluid={true}
        playsInline={true}
        poster={cover}
        preload="none"
        className="video-controls"
        posterStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BigPlayButton position="center" />
        <source src={src} />
        <ControlBar autoHide={false} />
      </Player>
    </div>
  );
}

export default VideoPlayer;
