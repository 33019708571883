import React from "react";
import { Card } from "react-bootstrap";
import "../card/Card.scss";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CustomizeActions } from "../../../redux/actions/customize.action";
function SoldOutCards({ history, collections }) {
  const dispatch = useDispatch()

  const goToMintingPage = async (data) => {
    await dispatch(CustomizeActions.setCustomizeButtonClicked(false))
    const { externalLink, blockChain: { blockChain } } = data;
    if (blockChain && externalLink)
      history.push(`/${blockChain.toLowerCase()}?collection=${externalLink}`)
  }


  return (
    <>
      <div className="cardBlocks">
        <Row>
          {collections?.list.map((nft) => (
            <Col xl={3} lg={4} md={6} sm={6} xs={12} className="card_link" onClick={() => goToMintingPage(nft)}>
              <Card className="cards ">
                <Card.Img className="mainImg" src={nft?.logo} />
                <Card.Body className="content">
                  <div>
                    <Card.Title>{`${nft.originalName}`}</Card.Title>
                  </div>

                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default withRouter(SoldOutCards);
