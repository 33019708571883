import React from "react";
import "./BatchDetailAction.scss"
import { Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

function BatchDetailAction({ batch, history }) {
  
  const goToBatchDetails = () => {
    history.push({
      pathname:`/auth/collection/details/${batch.collection.externalLink}`,
      state:{
        currentBatchNo:batch
      }
    });
  };

  return <Button className="view_btn" onClick={() => goToBatchDetails()}>View Details</Button>;
}

export default withRouter(BatchDetailAction);
