import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../redux/actions/collection.action";
import { EncryptionHelper } from "../utils/EncryptionHelper";

const useS3Config = () => {
  console.count("s3count")
  const [s3Keys, setS3Keys] = useState(null)
  const dispatch = useDispatch();

  const getS3Config = useCallback(async () => {
    try {
      let res = await dispatch(CollectionActions.getService3Aws());
      console.log("s3-response", res);
      let keys = JSON.parse(EncryptionHelper.decrypt(res.data.data.s3));
      if (Object.keys(keys).length == 0) throw new Error("Invalid config");

      Object.keys(keys).forEach((key) => {
        keys[key] = JSON.parse(EncryptionHelper.decrypt(keys[key]));
      });
      console.log(keys);
      setS3Keys(keys);
    } catch (error) {
      console.log(error);
      return null;
    }
  },[dispatch]);

  useEffect(() => {
    if(!s3Keys) getS3Config();
  }, [getS3Config]);

  return {keys:s3Keys}
};

export default useS3Config;
