import * as AWS from "aws-sdk";

class S3Service {
  s3 = null;
  isCorsSet = false;
  defaultBucketName = "tokenizzer";
  
  constructor(config) {
    // console.log(AWS.config);
    this.updateConfig(config);
    const ep = new AWS.Endpoint(config.REACT_APP_S3_WASABI_DOMAIN_URL)
    this.s3 = new AWS.S3({ apiVersion: "2006-03-01", endpoint:ep });
  }
  /**
   * @function initS3
   * @returns S3
   */

   updateConfig(config) {
    AWS.config.update({
      accessKeyId: config.REACT_APP_S3_ACCESS_ID,
      secretAccessKey: config.REACT_APP_S3_SECRET_ID,
      region: config.REACT_APP_S3_REGION,
    });
  }

  /**
   * @function uploadFileToS3Bucket
   * @param file
   * @returns error | data
   */
  async uploadFileToS3Bucket(file, folder) {
    return new Promise(async (resolve, reject) => {
      let selectedFile = file;
      const bucketName = this.defaultBucketName;
      const ext = selectedFile.name.split(".")[1];
      let fileName = selectedFile.name.split(".")[0].split(" ").join("-");
      fileName = `${fileName}-${new Date().getTime()}.${ext}`;
      // const fileBuffer = await this.toBase64(selectedFile);
      // console.log(fileBuffer);

      const params = {
        Bucket: bucketName,
        Key: !!folder ? folder + "/" + fileName : fileName,
        Body: selectedFile,
        ACL: "public-read",
        ContentType: file["mimetype"],
      };
      const options = { partSize: 100 * 1024 * 1024, queueSize: 1 };

      this.s3.upload(params, options, (error, data) => {
        if (error)
          reject(error); /** return error if any otherwise, return data */
        resolve(data);
      });
    });
  }
}

export default S3Service;
