import { ApiService } from "../../services/api.service";
import { LoadingActions } from "./loading.action";

const getFaqDetails = (data) => {
    return async (dispatch, getState) => {
      const { getFaqDetails } = ApiService;
      const { startLoader, stopLoader } = LoadingActions;
      try {
        await dispatch(startLoader());
      
        const headers = {
          "content-type": "application/json",
        };
        let res = await getFaqDetails(data, headers);
        await dispatch(stopLoader());
        return res.data.data;
      } catch (e) {
        await dispatch(stopLoader());
        // console.log(e);
        return e;
      }
    };
  };

  export const FaqActions = {
    getFaqDetails
  }