import React, { useState, useRef, useEffect } from "react";
import "./AudioPlayer.scss"; // Import your CSS file for styling

function AudioPlayer({ src, fileName, hidePlayIcon, nftId, cover }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const togglePlay = (e) => {
    e.preventDefault();
    const audio = audioRef.current;

    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  useEffect(()=>{
    console.log("isPlaying");
    setIsPlaying(false)
  },[nftId])

  return (
    <div className="audio-player">
      <div
        className={`audio-controls ${isPlaying ? "playing" : ""}`}
        style={{
          height: "auto",
          width: "100%",
          backgroundImage:`url(${cover})`,
          backgroundSize:"cover",
          aspectRatio:"1/1"
        }}
      >
        <audio ref={audioRef} src={src} />
        <div className="name-pos">{fileName}</div>
        {hidePlayIcon === false ? (
          <button onClick={togglePlay} className="play-pause-button">
            {!isPlaying ? (
              <i
                className="fa-solid fa-play"
              ></i>
            ) : (
              <i
                className="fa-solid fa-pause"
              ></i>
            )}
          </button>
        ): (
            <img src={cover} style={{height:"100%", width:"auto", aspectRatio:"1/1"}} />
        )}
      </div>
    </div>
  );
}

export default AudioPlayer;
