import types from "../types";

const setNotifData = (data) => {
  console.log({ data });
  return {
    type: types.notifications.SET_NOTIF_DATA,
    payload: data,
  };
};

const checkDisabledCollection = (data) => async (dispatch, getState) => {
  try {
    let disabledCollection = data.find((x) => x.isDisabled === true);
    if (disabledCollection) {
      dispatch(
        setNotifData({
          actionText: "Close",
          text: `Collection name ${disabledCollection.originalName} has been disabled. Please contact your admin`,
          data: {
            type: "CLOSE",
          },
        })
      );
    }
  } catch (error) {
    throw error;
  }
};



export const NotificationActions = {
  setNotifData,
  checkDisabledCollection
};
