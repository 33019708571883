import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Field } from "redux-form";
import { required } from "redux-form-validators";
import { MainCard } from "..";
import { FormField } from "../FormField.jsx";
import { toasts as toast } from "../Toast/Toast";
import "../../pages/public/login-signup/Login.scss";
import { reduxFormHelper } from "../../../HOC/redux.form.helper";
import { matchPasswords, securePasswordValidator } from "../../../validators/customValidators";
import { UserActions } from "../../../redux/actions/user.action";
import { withRouter } from "react-router";

function ForgotPassword(props) {
  let { handleSubmit, history, title } = props;
  

  const dispatch = useDispatch();
  const submitForm = (values) => {
    const { password, new_password, old_password } = values;
    let data = {};
    if (title == "CHANGE PASSWORD") {
      data = {
        oldPassword: old_password,
        newPassword: new_password,
      };
      changePassword(data);
    } else {
      data = { password };
      resetPassword(data);
    }
  };

  const resetPassword = async (data) => {
    try {
      let res = await dispatch(UserActions.resetPassword(data));
      toast.success(res.data.message);
      setTimeout(() => {
        history.push("/login");
      }, 500);
    } catch (err) {

    }
  };

  const changePassword = async (data) => {
    try {
      let res = await dispatch(UserActions.changePassword(data));
      console.log({changePassRes:res});
      if(res){
        toast.success(res.data.message);
        setTimeout(() => {
          history.push("/login");
        }, 500);
      }
      
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div className="userLogPage">
      <section className="loginSec cmnSec">
        <div className="topSec">
          <Link to="" className="logBtn cmnBtn active">
            {title}
          </Link>
        </div>
        <div className="logForm">
          <MainCard>
            <Form className="logForm" autoComplete="off" onSubmit={handleSubmit(submitForm)}>
              {title && title == "CHANGE PASSWORD" && (
                <Form.Group className="fieldWrap" controlId="formBasicPassword">
                  <Field
                    component={FormField}
                    type="password"
                    name="old_password"
                    className="form-control"
                    placeholder="Old Password"
                    validate={[required()]}
                  />
                  <div className="formIcon">
                    {/* <img src={Email} alt="" /> */}
                  </div>
                </Form.Group>
              )}

              <Form.Group className="fieldWrap" controlId="formBasicPassword">
                <Field
                  component={FormField}
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder="New Password"
                  validate={[required(), securePasswordValidator()]}
                  />
                <div className="formIcon">
                  {/* <img src={Email} alt="" /> */}
                </div>
              </Form.Group>

              <Form.Group className="fieldWrap" controlId="formBasicPassword2">
                <Field
                  component={FormField}
                  type="password"
                  name="new_password"
                  className="form-control"
                  placeholder="Confirm Password"
                  validate={[required(), matchPasswords()]}
                />
                <div className="formIcon">
                  {/* <img src={Email} alt="" /> */}
                </div>
              </Form.Group>

              
              <Button className="cmnBtn2  cmnBtn" type="submit">
                Change Password
              </Button>
            </Form>
          </MainCard>
        </div>
      </section>
    </div>
  );
}

const fields = ["password", "new_password", "old_password"];
const ForgotPassForm = reduxFormHelper({
  form: "forgotPassword",
  fields,
  component: ForgotPassword,
});

export default withRouter(ForgotPassForm);
