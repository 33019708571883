import React, { useEffect, useState } from "react";
import { Col, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Enviroments } from "../../../constants/constants";
import withAttributesModal from "../../../HOC/WithAttributesModal";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { CustomizeActions } from "../../../redux/actions/customize.action";
import AttributesModal from "../AttributesModal/AttributesModal";
import DeleteConfirmationModal from "../DeleteConfirmationModal/DeleteConfirmationModal";
import ReleaseDateConfirmationModal from "../ReleaseDateConfirmationModal/ReleaseDateConfirmationModal";

function DropdownOptions({
  row,
  history,
  collections,
  s3keys,
  getCollections,
  collectionGoLive
}) {
  const { PUBLISH_PARAMS } = Enviroments;
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [showAttributeModal, setShowAttributeModal] = useState(false);
  const [showUpdateAttributeModal, setShowUpdateAttributeModal] =
    useState(false);
  const [showReleaseDateModal, setShowReleaseDateModal] = useState(false);
  const [showDeleteDraftModal, setShowDeleteDraftModal] = useState(false);
  const [draftReleaseDate, setDraftReleaseDate] = useState(null);
  const [publishParams, setPublishParams] = useState(PUBLISH_PARAMS);


  const {
    COLLECTION_TYPES: { DRAFT }
  } = Enviroments;

  const dispatch = useDispatch();
  const AddAttributesModal = withAttributesModal(
    AttributesModal,
    "addAttribute"
  );
  const UpdateAttributeModal = withAttributesModal(
    AttributesModal,
    "uploadNftNameDescCsv"
  );

  const selectDraft = (id) => {
    console.log({ collectionList: collections.list });
    let draftSelected = collections.list.find((draft) => draft._id === id);
    setSelectedDraft(draftSelected);
  };

  const publishDraft = (id) => {
    selectDraft(id);
    setShowReleaseDateModal(true);
  };

  const openAttributeModal = (id) => {
    selectDraft(id);
    setShowAttributeModal(true);
  };

  const openUpdateAttributeModal = (id) => {
    selectDraft(id);
    setShowUpdateAttributeModal(true);
  };
  const uploadNft = async (row) => {
    await dispatch(CollectionActions.allowNFTCreation(true));

    history.push({
      pathname: `/auth/collection/UploadNft/${row._id}`,
      state: {
        externalLink: row.externalLink,
        contentType: row.contentType
      },
    });
  };

  const openDeleteDraftModal = (id) => {
    setSelectedDraft(id);
    setShowDeleteDraftModal(true);
  };

  const handleClose = () => {
    setShowReleaseDateModal(false);
    setShowDeleteDraftModal(false);
  };

  const deleteDraft = async (id) => {
    try {
      let res = await dispatch(CollectionActions.deleteDraft(id));
      setShowDeleteDraftModal(false);
      getCollections({ page: 0, limit: 10, filters: {}, type: DRAFT });
    } catch (error) {
      setShowDeleteDraftModal(false);
      // console.log(error);
    }
  };

  const confirmDeleteDraft = (choice) => {
    if (choice == false) {
      setShowDeleteDraftModal(false);
      return
    }
    setShowDeleteDraftModal(false);
    deleteDraft(selectedDraft);
   
  };

  const handleResponse = (res) => {
    if (res.isWhiteListedEnable) {
      setDraftReleaseDate(res.whiteListStartTime);
    } else {
      setDraftReleaseDate(res.mainSaleStartsIn);
    }
    setPublishParams(res);
  };

  const goToCustomize = async () => {
    await dispatch(CustomizeActions.setCustomizeButtonClicked(true))
    history.push(`/auth/collection/details/${row.externalLink}`)
  }

  const goToCoverUpload = (row) => {
    console.important({row})
    history.push({
      pathname: `/auth/collection/UploadCover/${row._id}`,
      state: {
        contentType: Enviroments.CONTENT_TYPE.IMAGES,
        externalLink: row.externalLink,
        currentBatchNo: row.batch?._id,
      },
    });
  }

  useEffect(() => {
    if (!!draftReleaseDate) {
      collectionGoLive(selectedDraft, publishParams);
    }
  }, [draftReleaseDate]);

  return (
    <>
      <Col>
        <div className="publishBtn">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => publishDraft(row._id)}>
                Publish
              </Dropdown.Item>
              <Dropdown.Item onClick={() => openAttributeModal(row._id)}>
                Upload Attributes
              </Dropdown.Item>
              <Dropdown.Item onClick={() => openUpdateAttributeModal(row._id)}>
                Upload Name And Description
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  goToCustomize()
                }
              >
                Customize
              </Dropdown.Item>
              <Dropdown.Item onClick={() => uploadNft(row)}>
                Upload {row.contentType ? row.contentType.toLowerCase() : 'image'}
              </Dropdown.Item>
              {row.contentType !== Enviroments.CONTENT_TYPE.IMAGES &&
              row.batch?.supply != 0 && (
                <Dropdown.Item onClick={() => goToCoverUpload(row)}>
                  Upload Covers
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={() => openDeleteDraftModal(row._id)}>
                Delete Draft
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {showReleaseDateModal && (
            <ReleaseDateConfirmationModal
              show={showReleaseDateModal}
              handleClose={handleClose}
              setLiveParams={handleResponse}
              supply={selectedDraft.batch.supply}
              setShowReleaseDateModal={setShowReleaseDateModal}
              title={"Publish Draft"}
            />
          )}

          <DeleteConfirmationModal
            show={showDeleteDraftModal}
            handleClose={handleClose}
            confirmDelete={confirmDeleteDraft}
            item={"Draft"}
            message={"Are you sure you want to delete this draft?"}
          />

          {showAttributeModal && (
            <AddAttributesModal
              show={showAttributeModal}
              setShow={setShowAttributeModal}
              collectionId={selectedDraft._id}
              s3Keys={s3keys}
              currentBatchNo={selectedDraft.batch._id}
              contentType={selectedDraft.contentType}
            />
          )}

          {showUpdateAttributeModal && (
            <UpdateAttributeModal
              show={showUpdateAttributeModal}
              setShow={setShowUpdateAttributeModal}
              collectionId={selectedDraft._id}
              s3Keys={s3keys}
              currentBatchNo={selectedDraft.batch._id}
              contentType={selectedDraft.contentType}
            />
          )}
        </div>
      </Col>
    </>
  );
}

export default withRouter(DropdownOptions);
