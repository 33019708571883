import * as Yup from "yup";

 // Validation schema for yup
 export const validationSchema  = (supply) => Yup.object().shape({
    mintPerWallet: Yup.number()
        .required('Mint Per Wallet is required')
        .integer('Mint Per Wallet must be an integer')
        .min(1, 'Mint Per Wallet must be atleast 1')
        .max(9, supply === 0 ? 'Please add NFTs in collection before publish' : `Mint Per Wallet can't be greater than ${supply > 9 ? 9 : supply}`)
        .typeError('Mint Per Wallet must be a number')
    ,
    price: Yup.number()
        .required("Price is required")
        .typeError('Price must be a number')
        .min(0.0001,'Price must be atleast 0.0001')
        
  });

