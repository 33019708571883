const types = {
    persist:{
        SAVE_WALLET_ADDRESS: "[REDUCER] SAVE_WALLET_ADDRESS",
        SAVE_IS_LOGGED_IN: "[REDUCER] SAVE_IS_LOGGED_IN",
        SAVE_JWT_TOKEN: "[REDUCER] SAVE_JWT_TOKEN",
        RESET_STORE: "[REDUCER] RESET_STORE",
        SAVE_USER: "[REDUCER] SAVE_USER",
        OTP_RESENT_BOOL: "[REDUCER] OTP_RESENT_BOOL"
    },
    loading:{
        SAVE_GET_LOADING:"[REDUCER] SAVE_GET_LOADING"
    },
    collection:{
        SAVE_COLLECTION:"[REDUCER] SAVE_COLLECTION",
        ALLOW_NFT_CREATION: "[REDUCER] ALLOW_NFT_CREATION",
        SAVE_COLLECTION_WALLET_ADDRESS: "[REDUCER] SAVE_COLLECTION_WALLET_ADDRESS",
        SAVE_NFT_UPLOAD: "[REDUCER] SAVE_NFT_UPLOAD",
        SAVE_WALLET_WHITELIST_STATUS:"[REDUCER] SAVE_WALLET_WHITELIST_STATUS",
        SAVE_NFT_PAGE:"[REDUCER] SAVE_NFT_PAGE",
        SAVE_COLLECTION_UPDATED_STATUS:"[REDUCER] SAVE_COLLECTION_UPDATED_STATUS",
        SAVE_WALLET_TYPE: "[REDUCER] SAVE_WALLET_TYPE",
        SAVE_COLLECTION_DETAILS:"[REDUCER] SAVE_COLLECTION_DETAILS",
        SAVE_COLLECTION_LOGO_IMAGE:"[REDUCER] SAVE_COLLECTION_LOGO_IMAGE"
    },
    admin:{
        GET_COMMISSION:"[REDUCER] GET_COMMISSION"
    },
    batch: {
        SAVE_BATCH_DRAFT:"[REDUCER] SAVE_BATCH_DRAFT"
    },
    customize: {
        SET_CUSTOMIZE_BUTTON_CLICKED:"[REDUCER] SET_CUSTOMIZE_BUTTON_CLICKED"
    },
    notifications : {
        SET_NOTIF_DATA: "[REDUCER] SET_NOTIF_DATA"
    }

}

export default types;