import React, { useEffect, useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { PencilFill } from "react-bootstrap-icons";
import { length, numericality, required } from "redux-form-validators";
import useTotalMintedCount from "../../../hooks/useTotalMintedCount";
import ConnectWallet from "../ConnectWallet/ConnectWallet";
import CountdownTimer from "../CountdownTimer/CountdownTimer";
import CustomizeLogoImage from "../CustomizeLogoImage/CustomizeLogoImage";
import "./SquareImageSection.scss";

function SquareImageSection({
  collectionDetails,
  getCustomizedColor,
  contractFetch,
  isPrivate,
  setDisableMinting,
  walletAddress,
  openEditCollectionModal,
  mintedCountInfo,
  setCollectionDetails,
  invitationType,
  notifyInvitationStatus,
}) {
  const { totalMinted } = useTotalMintedCount(
    mintedCountInfo,
    collectionDetails,
    isPrivate
  );

  const [finalMinted, setFinalMinted] = useState(0);

  const checkMinted = () => {
    return totalMinted > mintedCountInfo ? totalMinted : mintedCountInfo;
  };

  useEffect(() => {
    console.important({ totalMinted, mintedCountInfo });
    setFinalMinted(checkMinted());
  }, [totalMinted, mintedCountInfo]);

  return (
    <div className="square_sec mt-5">
      <Row>
        <Col sm={12} md={12} lg={5}>
          {/* <Image className="nftImage" src={collectionDetails.logo} /> */}
          <CustomizeLogoImage collectionDetails={collectionDetails} />
        </Col>
        <Col sm={12} md={12} lg={7} className="Right">
          {collectionDetails && Object.keys(collectionDetails).length > 0 && (
            <>
              <h5
                style={getCustomizedColor("heading")}
                className="labeltext mb-4"
              >
                Collection Name{" "}
                <p
                  className="mb-0  ms-2 inputSec"
                  style={getCustomizedColor("inputField")}
                >
                  {" "}
                  {collectionDetails.originalName}
                </p>
              </h5>

              {collectionDetails.isLive &&
                collectionDetails.currentBatchNo.batchStatus === "ON_GOING" && (
                  <>
                    <h5
                      style={getCustomizedColor("heading")}
                      className="labeltext  mb-4"
                    >
                      Contract Address {/* {debugContractAddress()} */}
                      {contractFetch.isContractFound == true ? (
                        <p
                          className="mb-0  ms-2 inputSec"
                          style={getCustomizedColor("inputField")}
                        >
                          {contractFetch.contract.contractAddress.toLowerCase()}{" "}
                        </p>
                      ) : (
                        <div class="lds-facebook">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </h5>
                    <CountdownTimer
                      collectionDetails={collectionDetails}
                      getCustomizedColor={getCustomizedColor}
                      setDisableMinting={setDisableMinting}
                      batch={collectionDetails.currentBatchNo}
                      styleName={"align_timer"}
                      setCollectionDetails={setCollectionDetails}
                      invitationType={invitationType}
                      notifyInvitationStatus={notifyInvitationStatus}
                    />
                    <h5
                      style={getCustomizedColor("heading")}
                      className="labeltext  mb-4"
                    >
                      Wallet
                      <p
                        className="mb-0 ms-2 inputSec"
                        style={getCustomizedColor("inputField")}
                      >
                        {walletAddress}
                      </p>
                      {walletAddress && !isPrivate && (
                        <ConnectWallet
                          className="mx-2"
                          isChange="true"
                          collectionDetails={collectionDetails}
                          style={getCustomizedColor("button")}
                        />
                      )}
                    </h5>

                    <h5
                      style={getCustomizedColor("heading")}
                      className="labeltext mb-4"
                    >
                      Price per Unit
                      <p
                        className="mb-0 ms-2 inputSec"
                        style={getCustomizedColor("inputField")}
                      >
                        {collectionDetails.currentBatchNo?.price}{" "}
                        {collectionDetails.blockChain.blockChain}
                        {isPrivate == true && (
                          <PencilFill
                            className="edit-collection"
                            onClick={() =>
                              openEditCollectionModal({
                                name: "price",
                                text: "Price per Unit :",
                                validation: [
                                  required(),
                                  numericality({ float: true, ">=": 0.0001 }),
                                ],
                              })
                            }
                          />
                        )}
                      </p>
                    </h5>
                    <h5
                      style={getCustomizedColor("heading")}
                      className="labeltext  mb-4"
                    >
                      Max. Mint per Wallet
                      <p
                        className="mb-0 ms-2 inputSec"
                        style={getCustomizedColor("inputField")}
                      >
                        {collectionDetails.currentBatchNo?.mintPerWallet}
                        {isPrivate == true && (
                          <PencilFill
                            className="edit-collection"
                            onClick={() =>
                              openEditCollectionModal({
                                name: "mintPerWallet",
                                text: "Max. Mint per Wallet :",
                                validation: [
                                  required(),
                                  numericality({
                                    integer: true,
                                    ">=": 1,
                                    "<=": collectionDetails.currentBatchNo
                                      ?.supply,
                                    msg: {
                                      defaultMessage: "must be integer",
                                      ">=": "must be greater than 0",
                                      "<=": "must be less than or equal to supply",
                                    },
                                  }),
                                ],
                              })
                            }
                          />
                        )}
                      </p>
                    </h5>
                    <h5
                      style={getCustomizedColor("heading")}
                      className="labeltext  mb-4"
                    >
                      Total Supply
                      <p
                        className="mb-0 ms-2 inputSec"
                        style={getCustomizedColor("inputField")}
                      >
                        {collectionDetails?.totalSupply}
                      </p>
                    </h5>
                  </>
                )}

              <h5
                style={getCustomizedColor("heading")}
                className="labeltext  mb-4"
              >
                Batch #{collectionDetails?.currentBatchNo?.batchNo}
                <p
                  className="mb-0 ms-2 inputSec"
                  style={getCustomizedColor("inputField")}
                >
                  {collectionDetails?.currentBatchNo?.supply}
                </p>
                &nbsp;{" "}
                {!!collectionDetails?.currentBatchNo?.supply && collectionDetails?.currentBatchNo?.supply === finalMinted && (
                  <span>(sold out)</span>
                )}
              </h5>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default SquareImageSection;
