import React, { useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Field } from "redux-form";
import { required, numericality, length } from "redux-form-validators";
import { UserActions } from "../../../../redux/actions/user.action";
import { MainCard, Layout } from "../../../common";
import { FormField } from "../../../common/FormField";
import { toasts as toast } from "../../../common/Toast/Toast";
import "./Login.scss";
import { useHistory, useLocation, withRouter } from "react-router";
import { reduxFormHelper } from "../../../../HOC/redux.form.helper";
import { PersistActions } from "../../../../redux/actions/persist.action";

function OneTimePassword(props) {
  const { handleSubmit } = props;
  const dispatch = useDispatch();
  const isResent = useSelector((state) => state.persist.isResent);
  const location = useLocation();
  const history = useHistory();
  const [user, setUser] = useState({ email: "" });
  const [timer, setTimer] = useState("00:00");

  let countDownTImer = null;

  const twoMinTimer = () => new Date(
    new Date().setMinutes(new Date().getMinutes() + 2)
  ).getTime();

  const submitForm = async (values) => {
    try {
      const { verifyEmail, verificationBeforeCp } = UserActions;
      if (!props.fromInside) {
        values.user = user.email;
        values.role = "EMAIL";
        const {
          data: { message },
        } = await dispatch(verifyEmail(values));
        toast.success(message);
        setTimer("00:00");
        history.push({ pathname: "/login", state: undefined });
      } else {
        const data = {
          otp: values.otp,
        };
        await dispatch(verificationBeforeCp(data));
        setTimeout(() => {
          props.setShowOtp(false);
          props.setShowChangePass(true);
        }, 1000);
      }
    } catch (error) {
      if (error?.message) toast.error(error.message);
      else toast.error(error);
      throw error;
    }
  };

  const resendOtp = async () => {
    // countDownTimer(twoMinTimer)
    if (!props.fromInside) {
      await resendOtpApi();
    } else {
     
      await sendOtpForCpApi();
      countDownTimer(twoMinTimer())
    }
  };

  const resendOtpApi = async () => {
    try {
      const { resendOneTimePass } = UserActions;
      const { saveIsResent } = PersistActions;
      history.replace({
        pathname: "/signup/one-time-password",
        state: {
          email: user.email,
          otpExpiresIn:twoMinTimer()
        },
      });

      dispatch(saveIsResent(true));
      const {
        data: { message },
      } = await dispatch(
        resendOneTimePass({ role: "EMAIL", user: user.email })
      );
      toast.success(message);
    } catch (error) {
      if (error?.message) toast.error(error.message);
      else toast.error(error);
      history.push({ pathname: "/login", state: undefined });
      throw error;
    }
  };

  const sendOtpForCpApi = async () => {
    try {
      
      const res = await dispatch(UserActions.sendOtpForCp());
      console.log(res);
      
      toast.success("Otp has been sent to your email");
    } catch (error) {
      console.log(error);
    }
  };

  const countDownTimer = useCallback(
    (timeStamp) => {
      const { saveIsResent } = PersistActions;
        countDownTImer = setInterval(() => {
          const timestamp = new Date(timeStamp).getTime();
          const now = new Date().getTime();
          const distance = timestamp - now;

          let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          let seconds = Math.floor((distance % (1000 * 60)) / 1000);

          if (seconds >= 0) {
            if (minutes < 10) minutes = `0${minutes}`;
            if (seconds < 10) seconds = `0${seconds}`;
            const time = `${minutes}:${seconds}`;
            setTimer(time);
            dispatch(saveIsResent(true));
          }

          if (distance < 0) {
            clearInterval(countDownTImer);
            dispatch(saveIsResent(false));
          }
        }, 1000);
      
    },
    [dispatch]
  );

  useEffect(async () => {
    if (!props.fromInside) {
      const { state } = location;
      if (
        typeof state === "undefined" ||
        Object.keys(state).length === 0 ||
        !state.email
      ) {
        history.push({ pathname: "/login", state: undefined });
      }

      setUser({ email: state.email });
      countDownTimer(state.otpExpiresIn);
    } else {
      sendOtpForCpApi();
      countDownTimer(twoMinTimer());
      
    }

    return () => {
      countDownTImer = null;
    }
  }, [countDownTimer, history, location]);

  return (
    <Layout fromInside={props.fromInside}>
      <div className="userLogPage">
        <section className="loginSec cmnSec">
          <div className="topSec">
            <Link to="/signup" className="sgnBtn otp cmnBtn ">
              Enter OTP
            </Link>
          </div>
          <div className="logForm">
            <MainCard>
              <Form
                className="logForm"
                autoComplete="off"
                autoFocus="off"
                onSubmit={handleSubmit(submitForm)}
                noValidate
              >
                <Form.Group className="fieldWrap">
                  <Field
                    component={FormField}
                    type="text"
                    name="otp"
                    className="form-control"
                    placeholder="One Time Password"
                    validate={[
                      required(),
                      numericality({
                        integer: true,
                        msg: "must be a number",
                      }),
                      length({
                        max: 8,
                        min: 8,
                        msg: "must be 8 digits",
                      }),
                    ]}
                  />
                  <Form.Text id="passwordHelp">
                    {isResent === true ? (
                      <p>Resend code in: {timer}</p>
                    ) : (
                      <>
                        Didn't receive OTP?{" "}
                        <p onClick={resendOtp}> Resend OTP</p>
                      </>
                    )}
                  </Form.Text>
                </Form.Group>

                <Button className="cmnBtn2  cmnBtn" type="submit">
                  Verify
                </Button>
              </Form>
            </MainCard>
          </div>
        </section>
      </div>
      {/* <div>
      <Faq/>
      </div> */}
    </Layout>
  );
}

const fields = ["otp"];

let formComponent = reduxFormHelper({
  form: "OtpForm",
  fields,
  component: OneTimePassword,
});

export default withRouter(formComponent);
