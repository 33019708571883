import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  lazy,
  Suspense,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import { Container } from "react-bootstrap";
import { StyledLayout } from "../../../common/layout/Layout";
import "../../public/information/Infomation.scss";

import { toasts as toast } from "../../../common/Toast/Toast";

import AccessModal from "../../../common/AccessModal/AccessModal";

import "./CollectionDetails.scss";
import CustomizationDrawer from "../../../common/CustomizationDrawer/CustomizationDrawer";

import BasicInfo from "../../../common/BasicInfo/BasicInfo";
import useQuery from "../../../../hooks/useQuery";
import Banner from "../../../common/Banner/Banner";
import PublishSection from "../../../common/PublishSection/PublishSection";
import CreatorButtons from "../../../common/CreatorButtons/CreatorButtons";
import SocialMediaIcons from "../../../common/SocialMediaIcons/SocialMediaIcons";
import WalletManager from "../../../../services/WalletManager.service";
import Web3 from "web3";
import useS3Config from "../../../../hooks/useS3Config";

const Nft = React.lazy(() => import("../../../common/Nft/Nft"));

function CollectionDetails({ history, match }) {
  const query = useQuery();
  let {
    params: { externalLink },
  } = match;

  const inviteCode = query.get("code");
  externalLink = externalLink || query.get("collection");

  const [collectionDetails, setCollectionDetails] = useState({});

  const dispatch = useDispatch();
  const [preSaleDate, setPreSaleDate] = useState(null);
  const [postSaleDate, setPostSaleDate] = useState(null);

  const [nfts, setNfts] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [isWhiteListed, setIsWhiteListed] = useState(false);

  const [showAccessModal, setShowAccessModal] = useState(false);
  const [disableMessage, setDisableMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showUpdateDatesModal, setShowUpdateDatesModal] = useState(false); // to update minting timers
  const { collectionDetails: collectionDetailsInStore } = useSelector(
    (state) => state.collection
  );

  const defaultColor = "";
  const [customize, setCustomize] = useState({
    colors: {
      headerColor: {
        color: defaultColor,
        text: "Header background color",
      },
      backgroundColor: { color: defaultColor, text: "Background color" },
      headingColor: { color: defaultColor, text: "Heading color" },
      textColor: { color: defaultColor, text: "Text color" },
      inputFieldColor: { color: defaultColor, text: "Input field text color" },
      inputFieldBackground: {
        color: defaultColor,
        text: "Input field background color",
      }, // Input field background color
      inputFieldBorder: {
        color: defaultColor,
        text: "Input field border color",
        border: "1px",
      }, // Input field background color
      buttonColor: { color: defaultColor, text: "Button text color" },
      buttonBackground: {
        color: defaultColor,
        text: "Button background color",
      },
      buttonBorder: {
        color: defaultColor,
        text: "Button border color",
        border: "1px",
      },
    },
    socialMedia: {
      facebook: "",
      instagram: "",
      twitter: "",
      discord: "",
    },
    images: {
      banner1: {
        url: "",
        isShow: true,
      },
      banner2: {
        url: "",
        isShow: true,
      },
    },
    isShowNftList: true,
  });

  const [isCustomized, setIsCustomized] = useState(false);
  const [contractFetch, setIsContractFetch] = useState({
    count: 0,
    isContractFound: false,
    contract: null,
  });

  const handleClose = useCallback(() => setShow(false), [show]);
  const handleShow = () => setShow(true);

  const [walletAddress, setWalletAddress] = useState();

  const isLoggedIn = useSelector((state) => state.persist.isLoggedIn);
  const collection = useSelector((state) => state.collection.collections);

  const selectedPage = useSelector((state) => {
    if (state.collection?.nft) {
      if (collectionDetails._id == state.collection?.nft?.collectionId) {
        return state.collection.nft.currentPage;
      }
    }
    return 0;
  });

  const { isCustomizeButtonClicked } = useSelector(state => state.customize);

  const account = useSelector(
    (state) => state.collection.collections.walletAddress
  );

  const { keys: s3Keys } = useS3Config();

  useEffect(async () => {
    if (!account && isPrivate) {
      const provider = WalletManager.initialize();
      const web3 = new Web3(provider);
      let accounts = await WalletManager.getAccounts(web3);
      setWalletAddress(accounts[0]);
    } else {
      setWalletAddress(account);
    }
  }, [account]);

  useEffect(async () => {
    if (collectionDetails._id) {
      if (match.path.indexOf("auth") == -1) {
        if (collectionDetails.isDisabled) {
          setDisableMessage(
            !!collectionDetails.disableMessage
              ? collectionDetails.disableMessage
              : "Collection is disabled"
          );
          setShowAccessModal(true);
        } else if (!collectionDetails.isLive) {
          setDisableMessage("You have no access to this page");
          setShowAccessModal(true);
        } 
      }
    }
  }, [collectionDetails]);

  const getCollectionDetailsByExternalLink = async (data, type) => {
    const { getCollectionDetailsByExternalLink } = CollectionActions;
    try {
      const res = await dispatch(getCollectionDetailsByExternalLink(data));
      // Updating dates
      if (Object.keys(collectionDetails).length == 0 || type == "reset") {
        if (history.location.state?.currentBatchNo) {
          setCollectionDetails({ ...res.data.data, currentBatchNo: history.location.state?.currentBatchNo });
        } else {

          setCollectionDetails({ ...res.data.data });
        }
        if (type !== "reset") {
          await dispatch(
            CollectionActions.saveCollectionDetails(res.data.data)
          );
        }
      }
      if (res.data.data.contract) {
        if (res.data.data.isWhiteListedEnable) {
          setPreSaleDate(res.data.data.whiteListEndTime);
        } else {
          setPostSaleDate(res.data.data.mainSaleStartsIn);
        }
        return { response: true, data: res.data.data };
      } else {
        return { response: false, data: res.data.data };
      }
    } catch (error) { }
  };

  const checkWhitelistUser = async (address) => {
    try {
      if (!collectionDetails._id) return false;
      let data = {
        collection: collectionDetails._id,
        walletAddress: address || walletAddress,
        currentBatchNo: collectionDetails.currentBatchNo._id,
      };
      const { checkIsWhiteListed } = CollectionActions;
      let res = await dispatch(checkIsWhiteListed(data));
      return res.data.data.isWhiteListed;
    } catch (error) {
      // console.log(error);
    }
  };

  const isWhiteListedUser = async () => {
    if (!collectionDetails.isWhiteListedEnable) {
      setIsWhiteListed(true);
    } else {
      const isWhiteListed = await checkWhitelistUser(walletAddress);
      setIsWhiteListed(isWhiteListed);
    }
    // checkForWhiteListAndMint();
  };

  const getCollectionNfts = async (obj) => {
    const { getCollectionNfts } = CollectionActions;
    let data = {
      ...obj,
      collection: collectionDetails._id,
      currentBatchNo: collectionDetails.currentBatchNo._id,
      pageType:isPrivate ? "CREATOR" : "USER"
    };
    try {
      let res = await dispatch(getCollectionNfts(data));
      setNfts(res.data.data.list);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  };

  const getCustomizedColor = (element) => {
    if (element === "heading") {
      return isCustomized
        ? {
          color:
            customize.colors.headingColor.color ||
            customize.colors.headingColor,
        }
        : null;
    }
    if (element === "button") {
      return isCustomized
        ? {
          color:
            customize.colors.buttonColor.color ||
            customize.colors.buttonColor,
          background:
            customize.colors.buttonBackground.color ||
            customize.colors.buttonBackground.color,
          border:
            customize.colors.buttonBorder.border +
            " solid " +
            customize.colors.buttonBorder.color ||
            customize.colors.buttonBorder,
        }
        : null;
    }
    if (element === "background") {
      return isCustomized
        ? {
          backgroundColor:
            customize.colors.backgroundColor.color ||
            customize.colors.backgroundColor,
        }
        : null;
    }
    if (element === "text") {
      return isCustomized
        ? {
          color:
            customize.colors.textColor.color || customize.colors.textColor,
        }
        : null;
    }

    if (element === "inputField") {
      return isCustomized
        ? {
          color: customize.colors.inputFieldColor.color,
          border:
            customize.colors.inputFieldBorder.border +
            " solid " +
            customize.colors.inputFieldBorder.color,
          background: customize.colors.inputFieldBackground.color,
        }
        : null;
    }

    if (element === "layout") {
      let data = isCustomized
        ? {
          buttonColor:
            customize.colors.buttonColor.color ||
            customize.colors.buttonColor,
          backgroundColor:
            customize.colors.backgroundColor.color ||
            customize.colors.backgroundColor,
          textColor:
            customize.colors.textColor.color || customize.colors.textColor,
          buttonBackground:
            customize.colors.buttonBackground.color ||
            customize.colors.buttonBackground,
          headerColor:
            customize.colors.headerColor.color ||
            customize.colors.headerColor,
        }
        : null;
      return data;
    }
    if (element === "nft") {
      return isCustomized
        ? {
          color: customize.colors.headingColor.color,
          textColor: customize.colors.textColor.color,
        }
        : null;
    }
  };

  const customizePage = useCallback(
    async (customize) => {
      try {
        const customizeData = { ...customize };
        //   Object.keys(customizeData.colors).forEach((key)=>{
        //     customizeData.colors[key] = customizeData.colors[key]['color']
        //  })
        const { updateCustomization } = CollectionActions;
        let res = await dispatch(
          updateCustomization(externalLink, customizeData)
        );
        toast.success("Customizations applied!!");
        handleClose();
      } catch (error) {
        handleClose();
        // console.log(error);
      }
    },
    [externalLink]
  );

  const customizeDetailsPage = async (e) => {
    let counter = 0;
    e.preventDefault();
    Object.values(customize.colors).map((value) => {
      if (value == "transparent") {
        counter += 1;
      }
    });
    if (counter == 0) {
      await customizePage(customize);
      handleClose();
    } else {
      toast.error("Please select all customizations");
    }
  };

  const getCustomization = async () => {
    try {
      let res = await dispatch(
        CollectionActions.getCustomization({
          externalLink,
        })
      );
      // console.clear();
      if (res.data.data && Object.keys(res.data.data).length != 0) {
        setCustomize(res.data.data);
        setIsCustomized(true);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const checkIfContractFetched = async () => {
    try {
      let res = await getCollectionDetailsByExternalLink(externalLink);
      if (res.response) {
        setIsContractFetch((prev) => {
          return {
            count: prev.count + 1,
            isContractFound: true,
            contract: res.data.contract,
          };
        });
      } else {
        setIsContractFetch((prev) => {
          return {
            count: prev.count + 1,
            isContractFound: false,
            contract: null,
          };
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const connectWallet = (address) => {
    try {
      setWalletAddress(address);
    } catch (error) {
      setWalletAddress("");
    }
  };

  useEffect(() => {
    connectWallet(account);
  }, [account]);

  useEffect(() => {
    getCustomization();
  }, []);

  useEffect(async () => {
    console.important("refreshed")
    if (collectionDetails._id) {
      getCollectionNfts({
        limit: 8,
        page: 0,
        filters: {},
        currentBatchNo: collectionDetails.currentBatchNo._id,
      });
    }
  }, [collectionDetails]);

  useEffect(() => {
    if (walletAddress) {
      isWhiteListedUser();
    }
  }, [preSaleDate, walletAddress]);

  useEffect(() => {
    let interval = null;
    if (contractFetch.isContractFound == false) {
      if (contractFetch.count == 0) {
        // toast.error("Fetching details!")
        checkIfContractFetched();
      } else {
        interval = setTimeout(() => {
          checkIfContractFetched();
        }, 10000);
      }
    }
    return () => {
      clearTimeout(interval);
    };
  }, [contractFetch]);

  const isPrivate = useMemo(() => {
    if (match.path.indexOf("auth") != -1 && isLoggedIn) {
      return true;
    }
    return false;
  }, [match, isLoggedIn]);

  const handleDatesUpdate = async (dates) => {
    console.important({ updatedDates: dates });
    history.location.state  = undefined;
    let res = await getCollectionDetailsByExternalLink(externalLink, "reset");
  };

  useEffect(() => {
    if (collectionDetailsInStore?._id === collectionDetails?._id) {
      getCollectionDetailsByExternalLink(externalLink, "reset");
    }
  }, [collectionDetailsInStore?.modifiedDate]);

  return (
    <>
      <div className="Collection-Page">
        <StyledLayout
          className={`${isCustomized ? "customize" : null}`}
          style={getCustomizedColor("layout")}
        >
          <Banner
            type="1"
            customize={customize}
            setCustomize={setCustomize}
            isPrivate={isPrivate}
            customizePage={customizePage}
            s3Keys={s3Keys}
            collectionDetails={collectionDetails}
          />
          <Container className={`owerlap ${!isCustomizeButtonClicked ? "text-center" : ''}`}>
            {isPrivate && Object.keys(collectionDetails).length > 0 && (
              <CreatorButtons
                getCustomizedColor={getCustomizedColor}
                handleShow={handleShow}
                collectionDetails={collectionDetails}
                contractFetch={contractFetch}
                setShowUpdateDatesModal={setShowUpdateDatesModal}
                history={history}
              />
            )}

            <CustomizationDrawer
              show={show}
              handleClose={handleClose}
              customize={customize}
              setCustomize={setCustomize}
              setIsCustomized={setIsCustomized}
              externalLink={externalLink}
              customizeDetailsPage={customizeDetailsPage}
            />
            {collectionDetails && Object.keys(collectionDetails).length > 0 && (
              <>
                <BasicInfo
                  connectWallet={connectWallet}
                  walletAddress={walletAddress}
                  collectionDetails={collectionDetails}
                  getCustomizedColor={getCustomizedColor}
                  contractFetch={contractFetch}
                  setCollectionDetails={setCollectionDetails}
                  nfts={nfts}
                  showUpdateDatesModal={showUpdateDatesModal}
                  setShowUpdateDatesModal={setShowUpdateDatesModal}
                  setWalletAddress={setWalletAddress}
                  banners={customize.images}
                />
                {nfts && totalCount > 0 && customize.isShowNftList && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <Nft
                      nfts={nfts}
                      getNfts={getCollectionNfts}
                      totalCount={totalCount}
                      getCustomizedColor={getCustomizedColor}
                      collectionId={collectionDetails._id}
                      currentBatchNo={collectionDetails.currentBatchNo._id}
                      parent="collectionDetails"
                      collectionName={collectionDetails.originalName}
                      isCustomizeButtonClicked={isCustomizeButtonClicked}
                    />
                  </Suspense>
                )}
              </>
            )}
            <AccessModal show={showAccessModal} message={disableMessage} />
          </Container>
          {customize.images.banner2 != undefined && (
            <Banner
              type="2"
              customize={customize}
              setCustomize={setCustomize}
              isPrivate={isPrivate}
              customizePage={customizePage}
              s3Keys={s3Keys}
            />
          )}
          <div className="social_media_wrapper">
            <PublishSection
              collectionDetails={collectionDetails}
              getCustomizedColor={getCustomizedColor}
              showUpdateDatesModal={showUpdateDatesModal}
              setShowUpdateDatesModal={setShowUpdateDatesModal}
              handleDatesUpdate={handleDatesUpdate}
            />

            <SocialMediaIcons customize={customize} />
          </div>
        </StyledLayout>
      </div>
    </>
  );
}

export default withRouter(CollectionDetails);
