import React from "react";
import "./Sidebar.scss";
// import { rootName } from "../../constant";
// import logo from "../../assets/images/logo.png";
import { Link, NavLink } from "react-router-dom";
import "../../pages/private/Setting/Setting";

const Sidebar = (props) => {

  const showOtp = (e) => {
    if(props.showOtp) e.preventDefault()
      
    if(props.showChangePass == false && props.showOtp == false) {
      props.setShowOtp(true)
    }
  }

  return (
    <div className="sidebar">
      <ul className="linkList">
        <li activeClassName="is-active">
          <NavLink to={`/auth/mainsetting`} onClick={(e)=>showOtp(e)}>
            Change Password
          </NavLink>
        </li>

        {/* <li activeClassName="is-active">
          <NavLink to={`/auth/collection/allCollection`} >
            Back
          </NavLink>
        </li> */}
       
      </ul>
    </div>
  );
};

export default Sidebar;
