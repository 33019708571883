import React from 'react'

const SoldOutInfo = ({getCustomizedColor, collectionDetails, finalMinted}) => {
  return (
    <h5 style={getCustomizedColor("heading")} className="labeltext  mb-4">
    Batch #{collectionDetails?.currentBatchNo?.batchNo}
    <p
      className="mb-0 ms-2 inputSec"
      style={getCustomizedColor("inputField")}
    >
      {collectionDetails?.currentBatchNo?.supply}
    </p>
    &nbsp;
    {!!collectionDetails?.currentBatchNo?.supply &&
      collectionDetails?.currentBatchNo?.supply === finalMinted && (
        <span>(sold out)</span>
      )}
  </h5>
  )
}

export default SoldOutInfo