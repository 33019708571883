import { Container } from "react-bootstrap";
import { Form } from "redux-form";

import React, { useEffect, useState } from "react";
import Button from "@restart/ui/esm/Button";
import { ProgressBar } from "react-bootstrap";
import { LoadingActions } from "../../../../redux/actions/loading.action";
import { reduxFormHelper } from "../../../../HOC/redux.form.helper";
import S3Service from "../../../../services/s3.service";
import { toasts as toast } from "../../../common/Toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Layout } from "../../../common";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import styles from "./uploadNFT.module.scss";
import AttributesModal from "../../../common/AttributesModal/AttributesModal";
import { Enviroments } from "../../../../constants/constants";
import fileValidationService from "../../../../services/fileValidation.service";
import withAttributesModal from "../../../../HOC/WithAttributesModal";
import useS3Config from "../../../../hooks/useS3Config";
import WalletManagerService from "../../../../services/WalletManager.service";
import Web3 from "web3";
import { PersistActions } from "../../../../redux/actions/persist.action";
import { getSupportedMimeTypesByType } from "../../../../utils/contentTypeHelper";
import UploadPreview from "../../../common/UploadPreview/UploadPreview";
import utilService from "../../../../services/util.service";

function UploadCover(props) {
  const { _id: userId } = useSelector((state) => state.persist.user);
  const isNFTCreationAllowed = useSelector(
    (state) => state.collection.isNFTCreationAllowed
  );
  const { handleSubmit } = props;
  const [uploads, setUploads] = useState([]);
  const [currentChunk, setCurrentChunk] = useState(1);
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState("pending");
  const [percentageUploaded, setPercentageUploaded] = useState(0);

  const [masterUploads, setMasterUploads] = useState([]);
  const [showAttributeModal, setShowAttributeModal] = useState(false);
  const [showUpdateAttributeModal, setShowUpdateAttributeModal] =
    useState(false);
  const AddAttributesModal = withAttributesModal(
    AttributesModal,
    "addAttribute"
  );
  const UpdateAttributeModal = withAttributesModal(
    AttributesModal,
    "uploadNftNameDescCsv"
  );
  const { keys: s3Keys } = useS3Config();
  const dispatch = useDispatch();
  const [currentBatchNo, setCurrentBatchNo] = useState("");
  const [collectionDetails, setCollectionDetails] = useState({});
  const [inputKey, setInputKey] = useState(Date.now());

  let {
    match: {
      params: { collectionId },
    },
  } = props;

  let { history } = props;

  let timeout = null;

  const nftUploads = useSelector(
    (state) => state?.collection?.currentCollection?.nftUploads
  );

  const ownerWalletAddress = useSelector(
    (state) => state.persist.walletAddress
  );

  useEffect(() => {
    console.log({ ownerWalletAddress });
  }, [ownerWalletAddress]);


  useEffect(() => {
    resetEverything();
  }, [props.items]);

  useEffect(() => {
    if (uploading == "uploaded") {
      setMasterUploads((prevUploads) => {
        return [...uploads];
      });
      setUploads([]);
    }
  }, [uploading]);

  useEffect(() => {
    if (files.length != 0) chunkUpload();
  }, [files]);

  useEffect(() => {
    if (uploads.length != 0) chunkUpload();
  }, [currentChunk, uploads]);

  useEffect(() => {
    console.important({
      currentBatchNo: history.location.state?.currentBatchNo,
    });
    if (history.location.state.currentBatchNo) {
      setCurrentBatchNo(history.location.state.currentBatchNo);
    }
  }, [history.location.state]);

  const selectImage = (fileObj) => {
    if (Object.keys(fileObj).length > 0) {
      uploadItems(fileObj);
    } else {
      setFiles([]);
    }
  };

  const acceptedfileList = getSupportedMimeTypesByType(
    history.location.state?.contentType
  );

  const handleClick = (id) => {
    resetEverything();
    if (uploading != "uploading") {
      document.getElementById(id).click();
    }
  };

  const uploadItems = async (fileObj) => {
    const { startLoader, stopLoader } = LoadingActions;
    try {
      dispatch(startLoader());
      let items = Object.values(fileObj);
      let fileArray = [];

      if (!!items && items.length > 0) {
        let canWeUpload = await areFilesUploadable(items);
        dispatch(stopLoader());
        if (canWeUpload) {
          fileArray = Object.keys(items).map((key, index) => ({
            file: items[key],
            serial: items[key]["name"].split(".")[0],
            user: userId,
          }));

          console.log(fileArray);
          setFiles(fileArray);
        } else return;
      } else {
        dispatch(stopLoader());
        toast.error("Please select files to continue");
      }
    } catch (error) {
      console.log(error);
      dispatch(stopLoader());
    }
  };

  const areFilesUploadable = async (items) => {
    const {
      NFT_LIMITS: { FILE_SIZE_LIMIT, UPLOAD_LIMIT, VIDEO_SIZE_LIMIT },
      CONTENT_TYPE,
    } = Enviroments;
    const contentType = history.location.state?.contentType;
    let fileSizeLimit =
      contentType === CONTENT_TYPE.VIDEO ? VIDEO_SIZE_LIMIT : FILE_SIZE_LIMIT;
    let message_file_type_error = "";

    if (contentType === CONTENT_TYPE.MUSIC) {
      message_file_type_error =
        "Please write nft names in sequence 1.mp3, 2.mp3, 3.mp3";
    } else if (contentType === CONTENT_TYPE.IMAGES) {
      message_file_type_error =
        "Please write nft names in sequence 1.png, 2.png, 3.png";
    } else if (contentType === CONTENT_TYPE.VIDEO) {
      message_file_type_error =
        "Please write nft names in sequence 1.mp4, 2.mp4, 3.mp4";
    }

    let isFileLessThanFileSizeLimit = true;
    let isFileCorrectExtension = true;
    if (items.length > 0) {
      for (let item of items) {
        isFileCorrectExtension = fileValidationService.validate(
          item,
          acceptedfileList
        );
        if (!isFileCorrectExtension) {
          break;
        }
      }
    }

    if (!isFileCorrectExtension) {
      toast.error(`File must be ${contentType.toLowerCase()} only`);
      return false;
    }

    if (
      nftUploads &&
      nftUploads != 0 &&
      (nftUploads > UPLOAD_LIMIT || nftUploads + items.length > UPLOAD_LIMIT)
    ) {
      toast.error(`You can't upload more than ${UPLOAD_LIMIT} files`);
      return false;
    }

    Object.values(items).forEach((file) => {
      if (file.size / 1024 / 1024 >= fileSizeLimit) {
        isFileLessThanFileSizeLimit = false;
      }
    });

    if (!isFileLessThanFileSizeLimit) {
      toast.error(`File size can't be greater than ${fileSizeLimit} mb`);
      return false;
    } else if (items.length > UPLOAD_LIMIT) {
      toast.error(`You can select only ${UPLOAD_LIMIT} files`);
      return false;
    }
    let isFileInSequence = true;
    let sequenceArr = [];
    Object.values(items).forEach((file, index) => {
      sequenceArr.push(+file.name.split(".")[0]);
    });
    sequenceArr = sequenceArr.sort((a, b) => a - b);
    isFileInSequence = sequenceArr.every((x) => isNaN(x) === false);
    console.important({ sequenceArr });

    if (!isFileInSequence) {
      toast.error(message_file_type_error);
      return false;
    }

    let lastItemName = await getLastNftName(collectionId);

    let lastItemIndex = 0;

    if (!lastItemName) {
      lastItemIndex = 0;
    } else {
      lastItemIndex = +lastItemName.split(".")[0];
    }
    sequenceArr.forEach((item, index) => {
      if (item != lastItemIndex + index + 1) {
        isFileInSequence = false;
      }
    });

    if (!isFileInSequence) {
      toast.error(`Please upload files in sequence from ${lastItemIndex + 1}`);
      return false;
    }

    let { totalSupply, batchSupply } = await getCollectionDetails(
      history.location.state?.externalLink
    );
  


   
    if (!ownerWalletAddress) {
      const provider = WalletManagerService.initialize();
      const web3 = new Web3(provider);
      let accounts = await WalletManagerService.getAccounts(web3);
      if (accounts.length == 0) {
        toast.error(`Please connect wallet and try again later`);
        return false;
      } else {
        await dispatch(
          PersistActions.saveWalletAddress(accounts[0].toLowerCase())
        );
      }
    }

    if (!history.location.state.currentBatchNo) {
      if (items.length > batchSupply) {
        toast.error(
          `${utilService.formatCountMessage(batchSupply, "cover", "covers")}`
        );
        return false;
      }
    } else {
      const { supply } = await getBatchDetails(currentBatchNo);
      if (supply && items.length > supply) {
        toast.error(
          `${utilService.formatCountMessage(supply, "cover", "covers")}`
        );
        return false;
      }
    }

    return true;
  };

  const resetEverything = () => {
    setCurrentChunk(1);
    // setFiles([]);
    setUploads([]);
    setPercentageUploaded(0);
    setUploading("pending");
  };

  const chunkUpload = async () => {
    try {
      console.log(s3Keys);
      const s3Service = new S3Service(s3Keys);
      console.log({ s3Service });
      await dispatch(LoadingActions.startLoader());
      let tempArray = [];
      let chunkSize =
        history.location.state?.contentType === Enviroments.CONTENT_TYPE.VIDEO
          ? 10
          : 100;
      let chunks = Math.ceil(files.length / chunkSize);
      if (currentChunk <= chunks) {
        let newArr = files.slice(
          (currentChunk - 1) * chunkSize,
          currentChunk * chunkSize
        );
        // newArr.forEach((file, index) => {
        //   uploadingFile(file, index, newArr, tempArray);
        // });
        for (let [index, file] of newArr.entries()) {
          await uploadingFile(file, index, newArr, tempArray, s3Service);
        }
      } else {
        await dispatch(LoadingActions.stopLoader());
      }
    } catch (error) {
      await dispatch(LoadingActions.stopLoader());
      console.log(error);
      toast.error("Failed uploading files");
    }
  };

  const uploadingFile = async (
    file,
    index,
    chunkArray,
    tempArray,
    s3Service
  ) => {
    try {
      let res = await s3Service.uploadFileToS3Bucket(
        file.file,
        history.location.state.externalLink
      );
      // await dispatch(LoadingActions.stopLoader());
      setUploading("uploading");
      let uploadObj = {
        url: res.Location,
        type: file.file.type,
        collectionId,
        serial: file.serial,
        fileName: file.file.name,
      };
      tempArray.push(uploadObj);
      uploads.push(uploadObj);
      let percentage = calcPercentageUploaded();
      console.log("percentage", percentage);
      setPercentageUploaded(percentage);
      console.log(index + 1 + " uploaded out of " + chunkArray.length);
      if (tempArray.length == chunkArray.length) {
        // call api here
        setUploads(uploads.sort((a, b) => a.serial - b.serial));
        let chunkSize =
          history.location.state?.contentType === Enviroments.CONTENT_TYPE.VIDEO
            ? 10
            : 100;
        await createNftsInChunks(
          uploads.slice(
            (currentChunk - 1) * chunkSize,
            currentChunk * chunkSize
          )
        );
      }
      if (uploads.length == files.length) {
        setUploading("uploaded");
        setInputKey(Date.now());
      }
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  const calcPercentageUploaded = () => {
    return Math.ceil((uploads.length / files.length) * 100);
  };

  const showFileUploadedSuccessMessage = () => {
    let filetype = "";
    if (
      history.location.state?.contentType === Enviroments.CONTENT_TYPE.IMAGES
    ) {
      filetype = "photos";
    } else if (
      history.location.state?.contentType === Enviroments.CONTENT_TYPE.MUSIC
    ) {
      filetype = "music";
    }
    if (uploads.length != 0) {
      if (uploading == "uploading") {
        return (
          <div className="text-center mt-2">
            <span>
              Adding {filetype} <b>{uploads.length}</b> out of{" "}
              <b>{files.length}</b>{" "}
            </span>
            <ProgressBar
              className="mt-3"
              variant="success"
              now={percentageUploaded}
            />
          </div>
        );
      }
    } else {
      if (uploading == "uploaded") {
        return (
          <>
            <div className="text-center mt-2">
              <span>
                Successfully uploaded all {masterUploads.length} {filetype}
              </span>
              <ProgressBar
                className="mt-3"
                variant="success"
                now={percentageUploaded}
              />
            </div>
          </>
        );
      }
    }
    if (uploading == "pending") {
      return null;
    }
  };

  const getCollectionDetails = async (data) => {
    try {
      let res = await dispatch(
        CollectionActions.getCollectionDetailsByExternalLink(data)
      );
      let apiData = res.data.data;

      if (apiData && Object.keys(apiData) != 0) {
        console.log({ externalLinkData: apiData });
        if (!currentBatchNo) {
          setCurrentBatchNo(apiData.currentBatchNo._id);
        }
        return {
          totalSupply: apiData.totalSupply,
          batchSupply: apiData.currentBatchNo.supply,
        };
      }
      return 0;
    } catch (error) {
      console.log(error);
    }
  };

  const checkTotalSupply = async (data) => {
    let res = await dispatch(
      CollectionActions.getCollectionDetailsByExternalLink(data)
    );
    setCollectionDetails(res.data.data);
    let supply = res.data.data.totalSupply;
    console.log({ supply });
    // if (supply == 0) {
    //   return false;
    // }
    return res.data.data;
  };

  const submitForm = async (values) => {
    const { startLoader, stopLoader } = LoadingActions;
    try {
      if (masterUploads.length > 0) {
        console.important({ masterUploads });
        await dispatch(startLoader());
        // history.push({
        //   pathname:
        //     "/auth/collection/details/" + history.location.state.externalLink,
        // });
        let res = await checkTotalSupply(history.location.state.externalLink);
        if (Object.keys(res).length > 0) {
          setTimeout(async () => {
            await dispatch(stopLoader());

            if (history.location.state.currentBatchNo) {
              history.push({
                pathname: `/auth/collection/batches/${collectionId}`,
                state: {
                  name: res.name,
                },
              });
            } else {
              history.push({
                pathname: "/auth/collection/allCollection",
                state: { externalLink: history.location.state.externalLink },
              });
            }

            toast.success("Covers have been added successfully");
          }, 5000);
        } else {
          throw new Error("Please connect wallet and try again");
        }
      } else {
        toast.error("Please select covers to upload");
      }
    } catch (error) {
      await dispatch(stopLoader());
      toast.error(error.message);
    }
  };

  const uploadCoverImages = async (data) => {
    console.log({ currentBatchNo });
    data.nft.forEach((x) => {
      x.currentBatchNo = currentBatchNo;
    });
    console.log({ createCollectionNfts: data });
    const { uploadCoverImages, allowNFTCreation } = CollectionActions;
    try {
      let res = await dispatch(uploadCoverImages(data));
      // toast.success(res.data.message);
      return res;
    } catch (error) {
      await dispatch(allowNFTCreation(true));
      // console.log(error);
    }
  };

  const createNftsInChunks = async (chunkArray) => {
    try {
      if (chunkArray.length != 0) {
        let data = {
          nft: chunkArray,
        };
        console.log({ chunkArray });
        let res = await uploadCoverImages(data);
        if (timeout) clearTimeout(timeout);
        if (res.data.status === "200") {
          setCurrentChunk((prevChunk) => prevChunk + 1);
        } else {
          toast.error("Error uploading files");
          await dispatch(LoadingActions.startLoader());
        }
      }
    } catch (error) {
      timeout = setTimeout(() => {
        createNftsInChunks(chunkArray);
      }, 5000);

      // console.log(error);
      throw error;
    }
  };

  const getTotalBatchesSupply = async (id) => {
    try {
      let res = await dispatch(CollectionActions.getTotalBatchesSupply(id));
      return res.result;
    } catch (error) {
      console.log(error);
    }
  };

  const getLastNftName = async (id) => {
    try {
      let res = await dispatch(CollectionActions.getLastBatchNftDetails(id));
      return res.result?.fileName ? res.result.fileName : "";
    } catch (error) {
      console.log(error);
    }
  };

  const getBatchDetails = async (batchId) => {
    try {
      let res = await dispatch(CollectionActions.getBatchDetails({ batchId }));
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(async () => {
    if (masterUploads.length != 0)
      await dispatch(
        CollectionActions.saveNftUpload({
          collectionId: collectionId,
          nftUploads: nftUploads + masterUploads.length,
        })
      );
  }, [masterUploads]);

  return (
    <Layout>
      <Container fluid className="createNew uploadNFTsec">
        <Container className="Creat mb-5">
          <Form onSubmit={handleSubmit(submitForm)}>
            <UploadPreview
              acceptedfileList={acceptedfileList}
              handleClick={handleClick}
              inputKey={inputKey}
              styles={styles}
              selectImage={selectImage}
              type={history.location.state?.contentType}
              showFileUploadedSuccessMessage={showFileUploadedSuccessMessage}
            />

            <Button
              className="Parchase cmnBtn me-3"
              type="submit"
              disabled={uploading == "uploading"}
            >
              {uploading === "uploaded" ? "Done" : "Submit"}
            </Button>
          </Form>
        </Container>
      </Container>
    </Layout>
  );
}

const fields = ["items"];

let uploadNftForm = reduxFormHelper({
  form: "uploadNftForm",
  fields,
  component: UploadCover,
});

export default withRouter(uploadNftForm);
