import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { validationSchema } from "./formValidations";
import FormErrors from "../FormErrors/FormErrors";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { Enviroments } from "../../../constants/constants";

// Resp: set mint per wallet and price
function PriceAndMintPerWallet({ getPriceAndMint, supply }) {
  const { TOOLTIP } = Enviroments;

  // initialize formik object
  const formik = useFormik({
    initialValues: {
      price: 0,
      mintPerWallet: 0,
    },
    validationSchema: validationSchema(supply),
  });
  console.log(formik);

  useEffect(() => {
    getPriceAndMint({ errors: formik.errors, values: formik.values });
  }, [
    formik.values?.mintPerWallet,
    formik.values?.price,
    formik.errors?.mintPerWallet,
    formik.errors?.price,
  ]);

  return (
    <Row>
      <Col>
        <Row className="gx-12">
          <Col className="leftSide" sm={12} md={8}>
            <Row>
              <label>
                Max. Mint per Wallet:
                <CustomTooltip title={TOOLTIP.CREATE.MAX_MINT_PER_WALLET} />
              </label>

              <input
                name="mintPerWallet"
                className="form-control"
                type="text"
                placeholder="e. g 10"
                value={formik.values.mintPerWallet}
                onChange={formik.handleChange}
                onFocus={() => formik.setFieldTouched("mintPerWallet")}
              />
              <FormErrors formik={formik} field="mintPerWallet" />
            </Row>

            <Row>
              <label>Price:</label>
              <input
                name="price"
                className="form-control"
                type="text"
                placeholder="e. g 0.1, 0.01, 0.001"
                value={formik.values.price}
                onChange={formik.handleChange}
                onFocus={() => formik.setFieldTouched("price")}
              />
              <FormErrors formik={formik} field="price" />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PriceAndMintPerWallet;
