import React from "react";
import './FormErrors.css'

function FormErrors({ formik,field }) {
  return (
    <>
      {formik.errors[field] && formik.touched[field] && (
        <span className="form_error">{formik.errors[field]}</span>
      )}
    </>
  );
}

export default FormErrors;
