import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import "./Nft.css";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { useDispatch, useSelector } from "react-redux";
import { Enviroments } from "../../../constants/constants";
import AudioPlayer from "../AudioPlayer/AudioPlayer";
import VideoPlayer from "../VideoPlayer/VideoPlayer";

function Nft({
  nfts,
  getNfts,
  totalCount,
  history,
  collectionId,
  parent,
  getCustomizedColor,
  currentBatchNo,
  collectionName,
  isCustomizeButtonClicked,
}) {
  console.log(nfts);
  const limit = 8;
  const numberOfPages = Math.ceil(totalCount / limit);
  const dispatch = useDispatch();

  const selectedPage = useSelector((state) => {
    if (state.collection?.nft) {
      if (collectionId == state.collection?.nft?.collectionId) {
        return state.collection.nft.currentPage;
      }
    }
    return 0;
  });
  const currentPage = parent === "collectionDetails" ? 0 : selectedPage;
  const [page, setPage] = useState(currentPage);

  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e == 0 ? 0 : e - 1,
      filters: {},
    };
    getNfts(obj);
  };

  const goToNftDetails = async (nft) => {
    if (parent === "collectionDetails") {
      if (history.location.pathname.indexOf("auth") != -1) {
        history.push(
          `/auth/nft-details/${collectionId}/${currentBatchNo}/${nft._id}`
        );
      } else {
        history.push(
          `/nft-details/${collectionId}/${currentBatchNo}/${nft._id}`
        );
      }
    } else {
      if (history.location.pathname.indexOf("auth") != -1) {
        history.replace(
          `/auth/nft-details/${collectionId}/${currentBatchNo}/${nft._id}`
        );
      } else {
        history.replace(
          `/nft-details/${collectionId}/${currentBatchNo}/${nft._id}`
        );
      }
    }
    await dispatch(
      CollectionActions.saveNftPage({
        collectionId,
        currentPage: page < 0 ? 0 : page,
      })
    );
  };

  const checkHeadingAlign = () => {
    if (parent === "collectionDetails") {
      if (isCustomizeButtonClicked === true) {
        return "nft_head_margin";
      } else return "nft_head";
    }
    return "nft_head_margin";
  };

  return (
    <Container className="ContMain mb-5">
      {nfts && nfts.length > 0 ? (
        <>
          <div className={`${checkHeadingAlign()}`}>
            <div className="text-start">
              <span className="title black">
                NFT Collections - Minting Now:&nbsp;
              </span>
            </div>

            <div className="text-start">
              <span
                className="title nft_title"
                style={
                  getCustomizedColor ? getCustomizedColor("heading") : null
                }
              >
                {collectionName || `NFTs Collection`}
              </span>
            </div>
          </div>
          <Row className="banner_row mb-4">
            {nfts.map((nft, i) => (
              <Col
                sm={6}
                lg={3}
                className="mt-3"
                style={{ cursor: "pointer" }}
                key={i}
              >
                {nft?.collection?.contentType &&
                  nft?.collection?.contentType ===
                    Enviroments.CONTENT_TYPE.MUSIC && (
                    <div
                      className="coll-new mb-4"
                      onClick={() => goToNftDetails(nft)}
                    >
                      <AudioPlayer
                        src={nft.url}
                        fileName={nft.fileName}
                        hidePlayIcon={true}
                        cover={nft.coverImage || nft.collection?.coverImage}
                      />
                    </div>
                  )}

                {nft?.collection?.contentType &&
                  nft?.collection?.contentType ===
                    Enviroments.CONTENT_TYPE.VIDEO && (
                    <div
                      className="coll-new mb-4"
                      onClick={() => goToNftDetails(nft)}
                    >
                      <VideoPlayer
                        src={nft.url}
                        fileName={nft.fileName}
                        hidePlayIcon={true}
                        cover={nft.coverImage || nft.collection?.coverImage}
                      />
                    </div>
                  )}

                {!nft?.collection?.contentType ||
                  (nft?.collection?.contentType ===
                    Enviroments.CONTENT_TYPE.IMAGES && (
                    <div
                      className="coll-new mb-4"
                      onClick={() => goToNftDetails(nft)}
                    >
                      <img
                        style={{
                          maxWidth: "395px",
                          height: "330px",
                          width: "100%",
                          // objectFit: "cover"
                        }}
                        src={nft.url}
                        alt="name"
                      />
                    </div>
                  ))}
              </Col>
            ))}
          </Row>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={totalCount}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
            style={{ color: "red" }}
          />
        </>
      ) : (
        <p>No NFT's Found</p>
      )}
    </Container>
  );
}

export default withRouter(Nft);
