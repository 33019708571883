import types from "../types";

const setCustomizeButtonClicked = (data) => {
  return {
    type: types.customize.SET_CUSTOMIZE_BUTTON_CLICKED,
    payload: data,
  };
};


export const CustomizeActions = {
  setCustomizeButtonClicked,
};
