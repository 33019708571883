import React, { useState } from "react";
import ModalInvitationList from "../ModalInvitationList/ModalInvitationList";
import { Button } from "react-bootstrap";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { Enviroments } from "../../../constants/constants";
import { useMemo } from "react";
import AddToWhitelist from "../AddToWhitelistModal/AddToWhitelist";

const InvitationCode = ({ collectionDetails, getCustomizedColor }) => {
  const [value, copy] = useCopyToClipboard(2000);

  const [showInvitationList, setShowInvitationList] = useState(false);
  const [showWhitelistModal, setShowWhitelistModal] = useState(false);
  const { currentBatchNo: {
    mainSaleStartsIn,
    preSaleEndTime,
    mainSaleEndsIn,
    isWhiteListedEnable,
    whiteListStartTime,
    whiteListEndTime

  } } = collectionDetails

  const isMainSaleOpened = useMemo(() => {
    if (collectionDetails._id) {
      if (isWhiteListedEnable) {
        if (!!mainSaleStartsIn && !!preSaleEndTime) {
          if (
            new Date().getTime() > new Date(preSaleEndTime).getTime() &&
            new Date().getTime() < new Date(mainSaleEndsIn).getTime()
          )
            return true;
        }
      } else {
        if (new Date().getTime() < new Date(mainSaleEndsIn).getTime())
          return true;
      }
    }

    return false;
  }, [preSaleEndTime, mainSaleStartsIn, new Date().getTime()]);

  const isPreSaleEnded = useMemo(() => {

    if (collectionDetails._id) {
      if (!!preSaleEndTime) {
        if (new Date().getTime() > new Date(preSaleEndTime).getTime())
          return true;
      }
      return false;
    }
  }, [preSaleEndTime, new Date().getTime()]);

  const beforeWhiteListEnds = useMemo(() => {
    if (collectionDetails._id) {
      if (!!whiteListStartTime && !!whiteListEndTime) {
        if (new Date().getTime() < new Date(whiteListEndTime).getTime())
          return true;
      }
      return false;
    }
  }, [whiteListStartTime, whiteListEndTime, new Date().getTime()]);


  const generateMainSaleLink = () => {
    const domain = Enviroments.DOMAIN;
    const link = `${domain}/${collectionDetails?.blockChain?.blockChain}?collection=${collectionDetails.externalLink}`;
    copy(link);
  };

  return (
    <>
      <div className="mb-2 text-center mt-5">
        <>
          {isWhiteListedEnable && beforeWhiteListEnds && (
            <>
              <Button
                className="mb-5 Parchase cmnBtn"
                style={getCustomizedColor('button')}
                onClick={() => {
                  setShowInvitationList(true);
                }}
              >
                Referral Codes
              </Button>

              <Button
                className="mb-5 Parchase cmnBtn ms-3"
                type="button"
                style={getCustomizedColor("button")}
                onClick={() => setShowWhitelistModal(true)}
              >
                Upload Csv
              </Button>
            </>
          )}
        </>

        {isMainSaleOpened && (
          <>
            <Button
              className="Parchase cmnBtn"
              onClick={() => {
                generateMainSaleLink();
              }}
              style={getCustomizedColor('button')}
            >
              copy minting page URL
            </Button>

            <div>
              {value && <span>Copied value: {value.toLowerCase()}</span>}
            </div>
          </>
        )}

        <AddToWhitelist
          collectionId={collectionDetails._id}
          show={showWhitelistModal}
          setShowWhitelistModal={setShowWhitelistModal}
          batch={collectionDetails.currentBatchNo}
        />

        <ModalInvitationList
          setShow={setShowInvitationList}
          show={showInvitationList}
          collectionDetails={collectionDetails}
        />
      </div>
    </>
  );
};

export default InvitationCode;
