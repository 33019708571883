import React, { useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { toasts as toast } from "../Toast/Toast";
import { CollectionActions } from "../../../redux/actions/collection.action";
import "./Attributes.scss";
import FileValidator from "../../../services/fileValidation.service";
import S3Service from "../../../services/s3.service";
import { LoadingActions } from "../../../redux/actions/loading.action";
import { Enviroments } from "../../../constants/constants";

const AttributesModal = (props) => {
  console.log("props", props);
  console.count("attributes loaded");
  const dispatch = useDispatch();
  // const { history } = props;
  const [file, setFile] = useState(null);
  const filename = () => {
    const { IMAGES, MUSIC, VIDEO } = Enviroments.CONTENT_TYPE;
    let name = "";
    if (props.type === "addAttribute") {
      if (props.contentType === IMAGES) {
        name = "attributes.csv";
      } else if (props.contentType === MUSIC) {
        name = "musicAttributes.csv";
      } else if (props.contentType === VIDEO) {
        name = "videoAttributes.csv";
      }
    } else {
      name = "nameAndDescription.csv";
    }

    return name;
  };

  const hideModal = () => {
    console.log(props);
    props.setShow(false);
  };

  const uploadCsv = async () => {
    const { startLoader, stopLoader } = LoadingActions;
    try {
      await dispatch(startLoader());
      const s3Service = new S3Service(props.s3Keys);
      let fileRes = await s3Service.uploadFileToS3Bucket(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("collectionId", props.collectionId);
      formData.append("csvLocation", fileRes.Location);
      formData.append("fileName", file.name);
      formData.append("currentBatchNo", props.currentBatchNo);
      let data = formData;
      let res = await dispatch(CollectionActions[props.type](data));
      let message =
        props.type === "addAttribute"
          ? res.data.message
          : "Name and Description has been uploaded successfully";
      toast.success(message);
      hideModal();
    } catch (error) {
      toast.error(error);
    } finally {
      await dispatch(stopLoader());
    }
  };

  const selectFile = (e) => {
    if (e.target.value.length > 0) {
      let res = FileValidator.validate(e.target.files[0], acceptedfileList);
      if (res) {
        setFile(e.target.files[0]);
      } else {
        setFile(null);
        toast.error("Please select csv file");
      }
    } else {
      setFile(null);
    }
  };

  const acceptedfileList = [
    "text/csv",
    "application/vnd.ms-excel",
    // ".glb",
    // ".gltf"
  ];

  return (
    <Modal
      scrollable={true}
      className="connect_wallet"
      show={props.show}
      size="lg"
      centered
    >
      <Modal.Header closeButton onClick={hideModal}>
        <Modal.Title>
          {props.type === "addAttribute"
            ? "Attributes"
            : "Name and Description"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="attributesModal">
        <Row>
          <Col className="baseToken_style">
            {props.type === "addAttribute" ? (
              <>
                <div className="text-center">
                  <h3>Instructions</h3>
                </div>

                <ol>
                  <li>Attributes can be max 50</li>
                </ol>
              </> 
            ) : (
              <>
                <div className="text-center">
                  <h3>Name and description of each nft</h3>
                </div>
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col lg={{ offset: 2, size: 8 }}>
            <Row>
              <Col lg={12}>
                <input
                  type="file"
                  onChange={selectFile}
                  accept={acceptedfileList.join(" ")}
                />
              </Col>
            </Row>
            <Link to={`/files/${filename()}`} target="_blank" download>
              Click to download sample file
            </Link>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary cmnBtn" disabled={!file} onClick={uploadCsv}>
          Upload csv
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(AttributesModal);
