import React from "react";
import { useEffect } from "react";

function MintingNote({
  getCustomizedColor,
  collectionDetails,
  availableNfts,
  isPrivate,
  mintedCount,
  mintedCountPerWallet,
  totalPrivateNfts
}) {
  const {currentBatchNo:{mintPerWallet, supply}} = collectionDetails

  useEffect(()=>{
    console.important({availableNfts, mintedCountPerWallet});
  },[availableNfts,mintedCountPerWallet])
  return (
    <>
      {isPrivate ? (
        <></>
        // <h5 style={getCustomizedColor("heading")}>
        //   Note:-{" "}
        //   <span
        //     style={getCustomizedColor("text")}
        //     className="max_wallet_per_mint"
        //   >
        //     The maximum number of NFTs that are already minted is {`${mintedCountPerWallet} / ${totalPrivateNfts}`}
        //   </span>
        // </h5>
      ) : (
        <h5 style={getCustomizedColor("heading")}>
          Note:-{" "}
          <span
            style={getCustomizedColor("text")}
            className="max_wallet_per_mint"
          >
            The maximum number of NFTs that are already minted is {`${mintedCountPerWallet} / ${mintPerWallet}`}{" "}
            per wallet (user){" "}
          </span>
        </h5>
      )}
    </>
  );
}

export default MintingNote;
