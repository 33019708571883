import React from "react";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { NotificationActions } from "../../../redux/actions/notification.action";
import { UserActions } from "../../../redux/actions/user.action";
import "./Notifications.scss";
// import 'animate.css';

const Notifications = ({ text, actionText, history, data, hide }) => {
  const dispatch = useDispatch();

  const performAction = () => {
    if (data.type === "SEND_EMAIL") {
      sendEmail();
    } else if (data.type === "CLOSE") {
      closeNotification();
    }
  };

  const sendEmail = async () => {
    const { setNotifData } = NotificationActions;
    const { resendOneTimePass } = UserActions;

    await dispatch(
      resendOneTimePass({ role: "EMAIL", user: data.state.email })
    );

    await dispatch(
      setNotifData({
        data: null,
        actionText: "",
        text: "",
      })
    );

    history.push({
      pathname: data.url,
      state: data.state,
    });
  };

  const closeNotification = () => {
    dispatch(
      NotificationActions.setNotifData({
        actionText: "",
        text: "",
        data: null,
        hide: true,
      })
    );
  };

  return (
    <>
      {hide === false && (
        <>
          {text && (
            <div className="notification-box animate__animated animate__bounceInDown">
              <div>{text}</div>
            </div>
          )}

          {text && actionText && (
            <div className="notification-box animate__animated animate__bounceInDown">
              <div>{text}</div>
              <div>
                <Link onClick={() => performAction()}>{actionText}</Link>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withRouter(Notifications);
