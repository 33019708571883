import types from "../types";

const initialState = {
  data: null
};

const batchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.batch.SAVE_BATCH_DRAFT:
      return { ...state, data: payload };
    default:
      return state;
  }
};

export default batchReducer;
