import React from "react";
import {Card } from "react-bootstrap";
import "./Card.scss";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CustomizeActions } from "../../../redux/actions/customize.action";
function Cards({history, collections}) {
  const dispatch = useDispatch()

  const getCoinImage = (coin) => {
    if(coin==="bsc") coin = "bnb"
    return <img src={require(`../../../assets/images/${coin}.svg`).default} />
  }

  const goToMintingPage = async (data) => {
    await dispatch(CustomizeActions.setCustomizeButtonClicked(false))
    const {blockChain:{blockChain},externalLink} = data.collection;
    if(blockChain)
    history.push(`/${blockChain.toLowerCase()}?collection=${externalLink}`)
  }


  return (
    <>
      <div className="cardBlocks">
        <Row>
          {collections?.list.map((obj) => (
            <Col xl={3} lg={4} md={6} sm={12} className="card_link" onClick={()=>goToMintingPage(obj)}>
              <Card className="cards ">
                <Card.Img className="mainImg" src={obj?.collection?.logo} />
                <Card.Body className="content">
                  <div>
                    <Card.Title>{obj?.collection?.originalName}</Card.Title>
                    {/* <Card.Text>{obj.description}</Card.Text> */}
                  </div>
                  <div className="coin_image">
                    {
                      getCoinImage(obj?.collection?.blockChain?.blockChain.toLowerCase())
                    }
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default withRouter(Cards);
