import React from "react";
import Cards from "../card/Cards";
import Slider from "react-slick";
import "./SoldOut.scss";
import SoldOutCards from "../SoldOutCards/SoldOutCards";

const SoldOut = ({ settings, soldOutCollections, setSoldOutIndex }) => {
  const totaldots = Math.ceil(soldOutCollections.totalCounts / 8);
  const array = new Array();
  array.length = totaldots > 6 ? 6 : totaldots;
  array.fill(1);
  console.log({ dotsarray: array, totaldots, soldOutCollections });

  const handleChange = (oldIndex, newIndex) => {
    setSoldOutIndex(newIndex);
  };

  return (
    <>
      <h2 className="maintitle mb-4 ">Sold Out </h2>
      <div className="slider_slick">
        <Slider
          {...settings}
          className="slide1"
          beforeChange={handleChange}
          
        >
          {array.map((x) => (
            <div>
              <SoldOutCards collections={soldOutCollections} />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default SoldOut;
