import { ApiService } from "../../services/api.service";
import { LoadingActions } from "./loading.action";


const getCommission = () => {
  return async (dispatch, getState) => {
    const { getCommission } = ApiService;
    const { startLoader, stopLoader } = LoadingActions;
    try {
      await dispatch(startLoader());
      const token = getState().persist.jwtToken;
      const headers = {
        "content-type": "application/json",
        jwt: token,
      };
      let res = await getCommission(headers);
      await dispatch(stopLoader());
      return res.data.data;
    } catch (e) {
      await dispatch(stopLoader());
      // console.log(e);
      return e;
    }
  };
};
const fetchFaq = (data) => {
  return async (dispatch, getState) => {
    const { fetchFaq } = ApiService;
    const { startLoader, stopLoader } = LoadingActions;
    try {
      await dispatch(startLoader());
      const token = getState().persist.jwtToken;
      const headers = {
        "content-type": "application/json",
        jwt: token,
      };
      let res = await fetchFaq(data, headers);
      await dispatch(stopLoader());
      return res;
    } catch (e) {
      await dispatch(stopLoader());
      // console.log(e);
      return e;
    }
  };
};

const getAllCategories = (data) => {
  return async (dispatch, getState) => {
    const { getAllCategories } = ApiService;
    const { startLoader, stopLoader } = LoadingActions;
    try {
      await dispatch(startLoader());
      const token = getState().persist.jwtToken;
      const headers = {
        "content-type": "application/json",
        jwt: token,
      };
      let res = await getAllCategories(data, headers);
      await dispatch(stopLoader());
      return res.data.data;
    } catch (e) {
      await dispatch(stopLoader());
      // console.log(e);
      return e;
    }
  };
}

export const AdminActions = {
  getCommission,
  fetchFaq,
  getAllCategories
};
