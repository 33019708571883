import React, { useState } from "react";
import { Image } from "react-bootstrap";
import CountdownTimer from "../CountdownTimer/CountdownTimer";

function CollectionBasicDetails({ collectionDetails }) {
    const [disableMinting, setDisableMinting] = useState(false)
  return (
    <div className="mt-5">
      <>
        <div className="text-center mb-5">
          <Image
            className={`nftImage`}
            src={collectionDetails.collectionId.logo}
          />
        </div>
        <h5 className="labeltext  mb-4">
          Name:{" "}
          <p className="mb-0  ms-2 inputSec">
            {" "}
            {collectionDetails.collectionId.originalName}
          </p>
        </h5>

        <CountdownTimer
          collectionDetails={collectionDetails.collectionId}
          batch={collectionDetails}
          setDisableMinting={setDisableMinting}
        />

        <h5 className="labeltext mb-4">
          Price per Unit :
          <p className="mb-0 ms-2 inputSec">
            {collectionDetails.price}{" "}
            {/* {collectionDetails.blockChain.blockChain} */}
          </p>
        </h5>
        <h5 className="labeltext  mb-4">
          Max. Mint per Wallet :
          <p className="mb-0 ms-2 inputSec">
            {collectionDetails.mintPerWallet}
          </p>
        </h5>
        <h5 className="labeltext  mb-4">
          Total Supply :
          <p className="mb-0 ms-2 inputSec">{collectionDetails.supply}</p>
        </h5>
      </>
    </div>
  );
}

export default CollectionBasicDetails;
