import React from "react";
import "./NftNameSerial.scss";
import { Col, Row } from "react-bootstrap";

function NftNameSerial({ nftDetails }) {
  return (
    <Row>
      <Col>
        <h2 className="nft_name mb-3" >
          {nftDetails.name
            ? nftDetails.name
            : `${nftDetails.collection.name} #${nftDetails.serial}`}
        </h2>
      </Col>
    </Row>
  );
}

export default NftNameSerial;
