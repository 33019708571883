import React from "react";
import { Container } from "react-bootstrap";
import "./MyCollection.scss";
import CollectionType from "../CollectionType/CollectionType";
import {Enviroments} from '../../../constants/constants'


function MyCollection() {
  const {CONTENT_TYPE} = Enviroments;
  console.count("MyCollection rerenders")
  return (
    <Container className="ContMain myCollection">
      <CollectionType type={CONTENT_TYPE.IMAGES} />
      <CollectionType type={CONTENT_TYPE.MUSIC} />
      <CollectionType type={CONTENT_TYPE.VIDEO} />
    </Container>
  );
}

const memoizedMyCollection = React.memo(MyCollection);

export default memoizedMyCollection
