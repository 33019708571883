class ContractErrors {
  constructor() {}

  handleContractError(error) {
    let message = "";
    
    if (error.code) {
      if (error.code == -32602) {
        message = "Only a creator can publish the collection";
      }
      if (error.code == -32603) {
        message =
          "Please check your wallet for the gas fee recommendation. If you are gas fee bid is too low, the contract will be rejected. You may still try again later.";
      }
      if(error.code == 4001){
        message = "User denied transaction"
      }
      if(error.code == -32002){
        message = "Please connect your wallet and try again"
      }
      if(error.code == 4100){
        message = "Only creator wallet can publish collection"
      }
    } else {
        message = JSON.parse(error.message.substr(error.message.indexOf("{"))).message;
    }
    return message;
  }
}

export default new ContractErrors()
