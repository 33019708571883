import moment from "moment";
import { toast } from "react-toastify";

const validatePriceAndMintPerWallet = (priceAndMint) => {
  const { errors, values } = priceAndMint;
  console.log({ formikErrors: errors, priceAndMint: priceAndMint });
  if (Object.keys(errors).length > 0) {
    if (errors.price) {
      toast.error(errors.price);
    } else {
      toast.error(errors.mintPerWallet);
    }
    return false;
  } else if (!values.mintPerWallet) {
    toast.error(`Mint per wallet must be greater than 0`);
    return false;
  } else if (!values.price) {
    toast.error(`Price must be atleast 0.0001`);
    return false;
  } else if (isNaN(values.mintPerWallet)) {
    return false;
  } else if (isNaN(values.price)) {
    return false;
  }
  return true;
};

const validatePrivateMinting = (privateMintData) => {
  if (privateMintData.isPrivateMintingAllowed) {
    if (!privateMintData.startMint) {
      toast.error("Please select start mint value");
      return false;
    }
    if (!privateMintData.endMint) {
      toast.error("Please select end mint value");
      return false;
    }
  }
  return true;
};

const formatDate = (date) => {
  let newDate = "";
  if (date instanceof Date || !!date) {
    newDate = moment.utc(date).format();
    return newDate;
  }
  toast.error("Please select datetime");
  return null;
};

const handlePreSaleAndPostSale = (
  dates,
  isWhiteList,
  props,
  privateMintData,
  priceAndMint,
  updateDates
) => {
  try {
    const { whitelist, presale, mainsale } = dates;
    // console.clear();
    if (isWhiteList === "yes") {
      handlePresale(
        dates,
        isWhiteList,
        props,
        privateMintData,
        priceAndMint,
        updateDates,
        whitelist,
        presale,
        mainsale
      );
    } else {
      handleMainsale(
        dates,
        isWhiteList,
        props,
        privateMintData,
        priceAndMint,
        updateDates,
        whitelist,
        presale,
        mainsale
      );
    }
    console.log({localDates:ReleaseDateService.checkLocalDates(dates)})
  } catch (error) {
    throw error;
  }
};

const handlePresale = (
  dates,
  isWhiteList,
  props,
  privateMintData,
  priceAndMint,
  updateDates,
  whitelist,
  presale,
  mainsale
) => {
    // Checking with current datetime
    Object.keys(dates).forEach((date) => {
      Object.keys(dates[date]).forEach((time) => {
        const selectedTime = dates[date][time];
        if (new Date().getTime() > new Date(selectedTime).getTime()) {
          throw new Error(
            `${formatMessage(date)} ${time
              .split("_")
              .join(" ")} can't be in past`
          );
        }
      });
    });
  

  //Checking with each other
  let count = 0;
  let new_arr = Object.keys(dates).map((key) => {
    return Object.keys(dates[key]).map((time) => {
      count += 1;
      return {
        name: key,
        timeLabel: time,
        index: count,
        time: dates[key][time],
      };
    });
  });

  let finalArr = new_arr.flat();

  for (var i = 0; i < finalArr.length; i++) {
    for (var j = i + 1; j < finalArr.length; j++) {
      if (
        new Date(finalArr[i].time).getTime() >=
        new Date(finalArr[j].time).getTime()
      ) {
        throw new Error(
          `${formatMessage(finalArr[j]["name"])} ${finalArr[j]["timeLabel"]
            .split("_")
            .join(" ")} can't be less than or equal to ${formatMessage(
            finalArr[i]["name"]
          )} ${finalArr[i]["timeLabel"].split("_").join(" ")}`
        );
      }
    }
  }

  if (!props.showUpdateDatesModal) {
    props.setLiveParams({
      whiteListStartTime: formatDate(whitelist.start_time),
      whiteListEndTime: formatDate(whitelist.end_time),
      preSaleStartTime: formatDate(presale.start_time),
      preSaleEndTime: formatDate(presale.end_time),
      mainSaleStartsIn: formatDate(mainsale.start_time),
      mainSaleEndsIn: formatDate(mainsale.end_time),
      isWhiteListedEnable: true,
      ...privateMintData,
      ...priceAndMint.values,
    });
  } else {
    let data = {
      whiteListStartTime: formatDate(whitelist.start_time),
      whiteListEndTime: formatDate(whitelist.end_time),
      preSaleStartTime: formatDate(presale.start_time),
      preSaleEndTime: formatDate(presale.end_time),
      mainSaleStartsIn: formatDate(mainsale.start_time),
      mainSaleEndsIn: formatDate(mainsale.end_time),
      isWhiteListedEnable: true,
    };
    updateDates(data);
  }
};

const handleMainsale = (
  dates,
  isWhiteList,
  props,
  privateMintData,
  priceAndMint,
  updateDates,
  whitelist,
  presale,
  mainsale
) => {
  // Checking with current datetime
    Object.keys(dates)
      .filter((x) => x === "mainsale")
      .forEach((date) => {
        Object.keys(dates[date]).forEach((time) => {
          const selectedTime = dates[date][time];
          if (new Date().getTime() > new Date(selectedTime).getTime()) {
            throw new Error(
              `${formatMessage(date)} ${time
                .split("_")
                .join(" ")} can't be in past`
            );
          }
        });
      });
  

  //Checking with each other
  let count = 0;
  let new_arr = Object.keys(dates)
    .filter((x) => x == "mainsale")
    .map((key) => {
      return Object.keys(dates[key]).map((time) => {
        count += 1;
        return {
          name: key,
          timeLabel: time,
          index: count,
          time: dates[key][time],
        };
      });
    });

  let finalArr = new_arr.flat();

  for (var i = 0; i < finalArr.length; i++) {
    for (var j = i + 1; j < finalArr.length; j++) {
      if (
        new Date(finalArr[i].time).getTime() >=
        new Date(finalArr[j].time).getTime()
      ) {
        throw new Error(
          `${formatMessage(finalArr[j]["name"])} ${finalArr[j]["timeLabel"]
            .split("_")
            .join(" ")} can't be less than or equal to ${formatMessage(
            finalArr[i]["name"]
          )} ${finalArr[i]["timeLabel"].split("_").join(" ")}`
        );
      }
    }
  }
  if (!props.showUpdateDatesModal) {
    props.setLiveParams({
      whiteListStartTime: "",
      whiteListEndTime: "",
      preSaleEndTime: "",
      preSaleStartTime: "",
      mainSaleStartsIn: formatDate(mainsale.start_time),
      isWhiteListedEnable: false,
      mainSaleEndsIn: formatDate(mainsale.end_time),
      ...privateMintData,
      ...priceAndMint.values,
    });
  } else {
    let data = {
      whiteListStartTime: "",
      whiteListEndTime: "",
      preSaleEndTime: "",
      preSaleStartTime: "",
      mainSaleStartsIn: formatDate(mainsale.start_time),
      isWhiteListedEnable: false,
      mainSaleEndsIn: formatDate(mainsale.end_time),
      // ...priceAndMint.values,
    };
    updateDates(data);
  }
};

const formatMessage = (message) => {
  return message.replace("sale", "-sale");
};

const checkLocalDates = (obj) => {
  return Object.values(obj).map((x)=>{
    if(typeof x === "string" && x){
      return new Date(x)
    }
  })
}

export const ReleaseDateService = {
  validatePriceAndMintPerWallet,
  validatePrivateMinting,
  handlePreSaleAndPostSale,
  checkLocalDates
};
