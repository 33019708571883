import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { useDispatch } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import { Layout } from "../../../common";
import TransferNftModal from "../../../common/TransferNftModal/TransferNftModal";
import "./PrivateNfts.scss";

function PrivateNfts({ history }) {
  const limit = 10;
  const [page, setPage] = useState(0);
  const [privateNftInfo, setPrivateNftInfo] = useState({
    list: [],
    totalCounts: 0,
  });
  const { collectionId } = useParams();
  const dispatch = useDispatch();
  const {
    location: {
      state: { collectionDetails, contract },
    },
  } = history;
  const [showTransferNftModal, setShowTransferNftModal] = useState(false);
  const [tokenId, setTokenId] = useState(-1);

  const getPrivateMintedNfts = async (data) => {
    try {
      const res = await dispatch(CollectionActions.getPrivateMintedNfts(data));
      if (res.data.data)
        setPrivateNftInfo({
          list: res.data.data.list,
          totalCounts: res.data.data.totalCounts,
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e == 0 ? 0 : e - 1,
      collection: collectionId,
      currentBatchNo: collectionDetails.currentBatchNo._id
    };

    getPrivateMintedNfts(obj);
  };

  const openTransferNftModal = (id) => {
    setShowTransferNftModal(true);
    setTokenId(id);
  };

  const txnHashDetails = (nft) => {
    if (nft.collectionId.blockChain.blockChain === "ETH") {
      window.open(`https://goerli.etherscan.io/tx/${nft.transactionHash}`);
    } else if (nft.collectionId.blockChain.blockChain === "BSC") {
      window.open(`https://testnet.bscscan.com/tx/${nft.transactionHash}`);
    } else if (nft.collectionId.blockChain.blockChain === "MATIC") {
      window.open(`https://mumbai.polygonscan.com/tx/${nft.transactionHash}`);
    }
  };

  const getPageNo = useCallback((index) => {
    if(page == 0){
      return (page * limit) + index + 1
    }
    return ((page -1) * limit) + index + 1
  },[page])

  useEffect(() => {
    const data = {
      page,
      limit,
      collection: collectionId,
      currentBatchNo: collectionDetails.currentBatchNo._id
    };
    getPrivateMintedNfts(data);
  }, []);

  return (
    <Layout>
      <Container>
        <Row className="mt-5">
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#S.No</th>
                  <th>Token Id</th>
                  <th>Nft Image</th>
                  <th>Collection Name</th>
                  <th>Batch No</th>
                  <th>Batch Supply</th>
                  <th>Owner</th>
                  <th>Txn Hash</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {privateNftInfo && privateNftInfo.list.length ? (
                  <>
                    {privateNftInfo.list.map((nft, index) => (
                      <tr>
                        <td>{getPageNo(index)}</td>
                        <td>{nft.tokenId}</td>

                        <td>
                          <img
                            className="private_nft_image"
                            src={nft.url}
                            alt="nft image"
                          />
                        </td>
                        <td>{collectionDetails?.originalName}</td>

                        <td>{nft?.currentBatchNo?.batchNo}</td>
                        <td>{nft?.currentBatchNo?.supply}</td>

                        <td>{`${nft?.owner.substr(
                          0,
                          4
                        )}.....${nft?.owner.substr(38, 44)}`}</td>

                        
                        <td>
                          {nft?.transactionHash?.length > 0 ? (
                            <p
                              style={{ color: "#5374da" }}
                              onClick={() => {
                                txnHashDetails(nft);
                              }}
                            >
                              {nft.transactionHash.substr(0, 6)}....
                              {nft.transactionHash.substr(58, 64)}{" "}
                            </p>
                          ) : (
                            "N/A"
                          )}
                        </td>

                        <td>
                          {nft?.creator?.toLowerCase() ===
                          nft?.owner?.toLowerCase() ? (
                            <Button
                              variant="primary cmnBtn"
                              onClick={() => openTransferNftModal(nft.tokenId)}
                            >
                              Transfer
                            </Button>
                          ) : <p>Transferred</p>}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        {privateNftInfo.totalCounts > limit && (
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={privateNftInfo.totalCounts}
            pageRangeDisplayed={3}
            onChange={handlePageChange}
          />
        )}

        {privateNftInfo.list.length > 0 && contract && (
          <TransferNftModal
            show={showTransferNftModal}
            setShow={setShowTransferNftModal}
            tokenId={tokenId}
            contract={contract}
            owner={privateNftInfo.list[0].creator}
            getPrivateMintedNfts={getPrivateMintedNfts}
            collectionId={collectionId}
            collectionDetails={collectionDetails}
          />
        )}
      </Container>
    </Layout>
  );
}

export default withRouter(PrivateNfts);
