import React from "react";
import { Image } from "react-bootstrap";
import { Field } from "redux-form";
import carbon from "../../../assets/images/carbon.svg";
import { Enviroments } from "../../../constants/constants";
import { FormField } from "../FormField";
import { required } from "redux-form-validators";
import audio from "../../../assets/images/audio-wave-icon-2-256.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UploadPreview = ({
  type,
  handleClick,
  inputKey,
  acceptedfileList,
  styles,
  selectImage,
  showFileUploadedSuccessMessage,
}) => {
  const { IMAGES, MUSIC, VIDEO } = Enviroments.CONTENT_TYPE;
  const history = useHistory();
  const locationUrl = history.location.pathname;
  return (
    <>
      {type === IMAGES && (
        <>
          <div className={`${styles.imageUpload} QAns`}>
            <label for="file-input1">
              <Image src={carbon} onClick={() => handleClick("fileItems")} />
              {/* <p>
            JPG, PNG, SVG, GIF, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max
            size: 1 GB
          </p> */}
              <p>
                JPG, PNG, GIF. Max size:{" "}
                {Enviroments.NFT_LIMITS.FILE_SIZE_LIMIT} MB (per image)
              </p>
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "10px",
                  color: "Blue",
                }}
              >
                Click on the container to add the images
              </p>
              {showFileUploadedSuccessMessage()}
            </label>
            {/* <input className="ms-5" id="file-input" type="file" /> */}
            <Field
              className="ms-md-2 ms-lg-5"
              onChange={selectImage}
              id="fileItems"
              type="file"
              key={inputKey}
              name="items"
              accept={acceptedfileList.join(",")}
              validate={[required()]}
              component={FormField}
              multiple={true}
            />
          </div>
          {locationUrl.includes("UploadCover") ? (
            <p>
              <i>Note:- </i> For each asset (image, video, audio file) you have uploaded, you
              must select a cover image. You need to use the same numbering
              order (e.g. 1.mp3 => 1.png) to assure that the cover image is
              assigned to the right asset.
            </p>
          ) : (
            <div>
              <p>
                <i>Note:- </i>Images are always selected in serial order like
                1.png, 2.png ...
              </p>
              <p className="mt-3">
                <i>Note:- </i>The supply of a batch will be decided on the
                behalf of uploaded nfts e.g if you <br /> have uploaded 10 nfts
                from 1 to 10 the supply of batch will be 10 and so on...
              </p>
            </div>
          )}
        </>
      )}

      {type === MUSIC && (
        <>
          <div className={`${styles.imageUpload} QAns`}>
            <label for="file-input1">
              <Image
                src={audio}
                style={{ height: "97px", width: "97px" }}
                onClick={() => handleClick("fileItems")}
              />
              {/* <p>
                  JPG, PNG, SVG, GIF, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max
                  size: 1 GB
                </p> */}
              <p>
                MP3. Max size: {Enviroments.NFT_LIMITS.FILE_SIZE_LIMIT} MB (per
                file)
              </p>
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "10px",
                  color: "Blue",
                }}
              >
                Click on the container to add the music
              </p>
              {showFileUploadedSuccessMessage()}
            </label>
            {/* <input className="ms-5" id="file-input" type="file" /> */}
            <Field
              className="ms-md-2 ms-lg-5"
              onChange={selectImage}
              id="fileItems"
              type="file"
              key={inputKey}
              name="items"
              accept={acceptedfileList.join(",")}
              validate={[required()]}
              component={FormField}
              multiple={true}
            />
          </div>
          <div>
            <p>
              Note:- selected music files should be in sorted order <br />
              e.g 1.mp3, 2.mp3, 3.mp3, etc.
            </p>
          </div>
        </>
      )}

      {type === VIDEO && (
        <>
          <div className={`${styles.imageUpload} QAns`}>
            <label for="file-input1">
              <i
                class="fa-solid fa-video fa-3x mb-2"
                style={{ color: "#5C56F2", cursor: "pointer" }}
                onClick={() => handleClick("fileItems")}
              ></i>
              {/* <p>
                  JPG, PNG, SVG, GIF, MP4, WEBM, MP3, WAV, OGG, GLB, GLTF. Max
                  size: 1 GB
                </p> */}
              <p>
                MP4. Max size: {Enviroments.NFT_LIMITS.VIDEO_SIZE_LIMIT} MB (per
                file)
              </p>
              <p
                style={{
                  marginTop: "10px",
                  fontSize: "10px",
                  color: "Blue",
                }}
              >
                Click on the container to add the video
              </p>
              {showFileUploadedSuccessMessage()}
            </label>
            {/* <input className="ms-5" id="file-input" type="file" /> */}
            <Field
              className="ms-md-2 ms-lg-5"
              onChange={selectImage}
              id="fileItems"
              type="file"
              key={inputKey}
              name="items"
              accept={acceptedfileList.join(",")}
              validate={[required()]}
              component={FormField}
              multiple={true}
            />
          </div>
          <div>
            <p>
              Note:- selected video files must be in sorted order <br />
              e.g 1.mp4, 2.mp4, 3.mp4, etc.
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default UploadPreview;
