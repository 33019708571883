import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import { PersistGate } from "redux-persist/lib/integration/react";
import configureValidators from "./validators/validators";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux/history";
import Loader from "./components/common/Loader/Loader";
import { Router } from "react-router-dom";

const { store, persistor } = configureStore();
configureValidators();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={Loader}>
        <ConnectedRouter history={history}>
          <Router history={history}>
            <App />
          </Router>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
