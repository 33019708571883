import React, { useEffect, useState } from "react";
import { Col, Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import withAttributesModal from "../../../HOC/WithAttributesModal";
import { CollectionActions } from "../../../redux/actions/collection.action";
import swalService from "../../../services/swal.service";
import AttributesModal from "../AttributesModal/AttributesModal";
import ReleaseDateConfirmationModal from "../ReleaseDateConfirmationModal/ReleaseDateConfirmationModal";
import { toasts as toast } from "../Toast/Toast";
import { Enviroments } from "../../../constants/constants";

function BatchDropdownOptions({
  row,
  history,
  s3keys,
  setKey,
  maxSupply,
  setBatchData,
}) {
  console.log({ row });
  const [showAttributeModal, setShowAttributeModal] = useState(false);
  const [showUpdateAttributeModal, setShowUpdateAttributeModal] =
    useState(false);
  const dispatch = useDispatch();
  const [showReleaseDateModal, setShowReleaseDateModal] = useState(false);

  const AddAttributesModal = withAttributesModal(
    AttributesModal,
    "addAttribute"
  );
  const UpdateAttributeModal = withAttributesModal(
    AttributesModal,
    "uploadNftNameDescCsv"
  );

  const [lastNft, setLastNft] = useState({
    index: -1,
    name: "",
  });

  const publishBatch = async (row) => {
    const { isConfirmed } = await swalService.confirmAction(
      "Do you want to launch this batch?"
    );
    if (isConfirmed) {
      setShowReleaseDateModal(true);
    }
  };

  const launchBatch = async (params) => {
    try {
      let data = {
        collectionId: row.collectionId,
        currentBatchNo: row._id,
        previousBatchNo: row.collection.currentBatchNo,
        ...params,
      };
      await dispatch(CollectionActions.publishBatch(data));
      toast.success("Batched published successfully");
      setKey("published");
    } catch (error) {
      console.log(error);
    }
  };

  const uploadNft = async (row) => {
    await dispatch(CollectionActions.allowNFTCreation(true));

    history.push({
      pathname: `/auth/collection/UploadNft/${row.collectionId}`,
      state: {
        externalLink: row.collection.externalLink,
        currentBatchNo: row._id,
        contentType: row.collection?.contentType,
      },
    });
  };

  const openAttributeModal = () => {
    setShowAttributeModal(true);
  };

  const openUpdateAttributeModal = () => {
    setShowUpdateAttributeModal(true);
  };

  const viewBatch = async (row) => {
    const details = await getBatchDetails(row);
    if (!details) return;
    console.important({ currentBatchNoBefore: details._id });
    history.push({
      pathname: `/auth/collection/details/${row.collection.externalLink}`,
      state: {
        currentBatchNo: details,
      },
    });

    // history.push(`/auth/collection/details/${row.collection.externalLink}`)
  };

  const handleClose = () => {
    setShowReleaseDateModal(false);
  };

  const getBatchDetails = async (row) => {
    try {
      const batchId = row._id;
      const res = await dispatch(
        CollectionActions.getBatchDetails({ batchId })
      );
      if (res) return res;
      return null;
    } catch (error) {
      console.log(error);
    }
  };

  const getLastNftName = async (id) => {
    try {
      let res = await dispatch(CollectionActions.getLastBatchNftDetails(id));
      setLastNft({
        name: res.result?.fileName ? res.result.fileName : "",
        index: res.result?.fileName ? +res.result.fileName.split(".")[0] : -1,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const confirmDeleteBatch = async (batch) => {
    console.log(batch);
    const { isConfirmed } = await swalService.confirmAction(
      "Are you sure you want to delete this batch?"
    );
    if (isConfirmed) {
      deleteBatch(batch);
    }
  };

  const deleteBatch = async (batch) => {
    try {
      const batchId = batch._id;
      const res = await dispatch(CollectionActions.deleteBatch(batchId));
      setBatchData({
        list: [],
        totalCounts: 0,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const goToCoverUpload = async (row) => {
    history.push({
      pathname: `/auth/collection/UploadCover/${row.collection?._id}`,
      state: {
        contentType: Enviroments.CONTENT_TYPE.IMAGES,
        externalLink: row.collection?.externalLink,
        currentBatchNo: row._id,
      },
    });
  };

  useEffect(() => {
    getLastNftName(row?.collection?._id);
  }, []);

  return (
    <Col>
      <div className="publishBtn">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => viewBatch(row)}>View</Dropdown.Item>
            {row.supply != 0 && (
              <Dropdown.Item onClick={() => publishBatch(row)}>
                Launch
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => uploadNft(row)}>
              Upload{" "}
              {row.collection?.contentType
                ? row.collection?.contentType.toLowerCase()
                : "image"}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => openAttributeModal()}>
              Upload Attributes
            </Dropdown.Item>
            <Dropdown.Item onClick={() => openUpdateAttributeModal()}>
              Upload Name And Description
            </Dropdown.Item>
            {row.collection?.contentType !== Enviroments.CONTENT_TYPE.IMAGES &&
              row.supply != 0 && (
                <Dropdown.Item onClick={() => goToCoverUpload(row)}>
                  Upload Covers
                </Dropdown.Item>
              )}

            <Dropdown.Item onClick={() => confirmDeleteBatch(row)}>
              Delete Batch
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {showAttributeModal && (
          <AddAttributesModal
            show={showAttributeModal}
            setShow={setShowAttributeModal}
            collectionId={row.collectionId}
            s3Keys={s3keys}
            currentBatchNo={row._id}
            contentType={row.collection?.contentType}
          />
        )}

        {showUpdateAttributeModal && (
          <UpdateAttributeModal
            show={showUpdateAttributeModal}
            setShow={setShowUpdateAttributeModal}
            collectionId={row.collectionId}
            s3Keys={s3keys}
            currentBatchNo={row._id}
            contentType={row.collection?.contentType}
          />
        )}

        {showReleaseDateModal && (
          <ReleaseDateConfirmationModal
            show={showReleaseDateModal}
            handleClose={handleClose}
            setLiveParams={launchBatch}
            supply={row.supply}
            setShowReleaseDateModal={setShowReleaseDateModal}
            title="Launch Batch"
            lastNft={lastNft}
          />
        )}
      </div>
    </Col>
  );
}

export default withRouter(BatchDropdownOptions);
