import React from "react";
import { useState } from "react";
import box from "../../../assets/images/box.svg";

function ColorCustomization({ customize, setCustomize, setIsCustomized }) {

  const handleColorChange = (e) => {
    const { type, value, name } = e.target
    setCustomize((obj) => {
      return {
        ...obj,
        colors: {
          ...obj.colors,
          [name]: {
            ...obj.colors[name],
            color: value
          },
        },
      };
    });
    setTimeout(() => {
      setIsCustomized(true);
    }, 500);
  };

  const handleBorderChange = (e) => {
    const { type, value, name } = e.target

    setCustomize((obj) => {
      return {
        ...obj,
        colors: {
          ...obj.colors,
          [name]: {
            ...obj.colors[name],
            border: value + "px"
          },
        },
      };
    });
    setTimeout(() => {
      setIsCustomized(true);
    }, 500);

  }

  return (
    <section className="first">
      <h5 className="subTitle">Customise</h5>
      <p> Pick the desired color to change the theme.</p>
      <div className="hrLine"></div>
      <ul>
        {Object.keys(customize.colors).map((color) => (
          <li>
            <h6>{customize.colors[color].text}</h6>
            <div className="colorPalet d-flex align-items-center">
              <span>
                <img className="dimond" src={box} alt="img" />
              </span>
              <label for="favcolor" className="trangle" />
              <input
                type="color"
                id="head"
                name={color}
                value={customize.colors[color].color}
                onChange={handleColorChange}
              />
              {customize.colors[color].border && (
                <input name={color} value={customize.colors[color].border.split('px')[0]} type="range" min={0} max={10} onChange={handleBorderChange} />
              )}

            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default ColorCustomization;
