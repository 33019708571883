import React, { useEffect } from "react";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import { CollectionActions } from "../../../redux/actions/collection.action";
import WalletManagerService from "../../../services/WalletManager.service";
import CustomHeaderButton from "../CustomHeaderButton/CustomHeaderButton";
import ReleaseDateConfirmationModal from "../ReleaseDateConfirmationModal/ReleaseDateConfirmationModal";
import SettingsHeaderIcon from "../SettingsHeaderIcon/SettingsHeaderIcon";
import { toasts as toast } from "../Toast/Toast";
import "./HeaderLoginButtons.scss";
import useS3Config from "../../../hooks/useS3Config";
import { LoadingActions } from "../../../redux/actions/loading.action";
import S3Service from "../../../services/s3.service";
import { CustomizeActions } from "../../../redux/actions/customize.action";
import contractErrorsService from "../../../services/contractErrors.service";

function HeaderLoginButtons(props) {
  const dispatch = useDispatch();

  const { isCustomizeButtonClicked } = useSelector((state) => state.customize);
  const { collectionDetails } = useSelector((state) => state.collection);

  const [showReleaseDateModal, setShowReleaseDateModal] = useState();

  console.log({
    isCustomizeButtonClicked,
    currentPage: props.checkCurrentPage,
  });

  const { keys: s3Keys } = useS3Config();

  const { logoImage } = useSelector((state) => state.collection);

  const goToCollection = () => {
    props.history.push("/auth/collection/allCollection");
  };

  const logout = async () => {
    await dispatch({
      type: "LOGOUT_USERS_PERSIST",
    });
  };

  const handleClose = () => {
    setShowReleaseDateModal(false);
  };

  const handleResponse = (res) => {
    collectionGoLive({ ...collectionDetails, ...res });
  };

  const collectionGoLive = async (obj) => {
    const { collectionGoLive, getContractByBlockChain } = CollectionActions;
    try {
      let data = {
        _id: collectionDetails._id,
        ...obj,
        
      };

      const batch = {
       ...obj.currentBatchNo
      }
      console.log({ apidata: data });
      const provider = WalletManagerService.initialize();
      const web3 = new Web3(provider);
      let response = await dispatch(getContractByBlockChain(obj, data, web3, batch));
      toast.success("Collection is live now..!!");
      await dispatch(
        CollectionActions.saveCollectionDetails({
          ...collectionDetails,
          isLive: true,
        })
      );
    } catch (error) {
      await dispatch(
        CollectionActions.saveCollectionDetails({
          ...collectionDetails,
          isLive: false,
        })
      );
      // console.log(error);
      if (error) {
        const message = contractErrorsService.handleContractError(error);
        toast.error(message);
      }
    } finally {
      handleClose();
    }
  };

  const saveCustomization = async () => {
    if (isLogoImageChanged() === true) {
      await saveLogoImage(logoImage);
      await dispatch(CustomizeActions.setCustomizeButtonClicked(false));
      await dispatch(
        CollectionActions.saveCollectionLogoImage({
          collectionId: collectionDetails._id,
          image: {},
        })
      );
    } else {
      await dispatch(CustomizeActions.setCustomizeButtonClicked(false));
    }
  };

  const isLogoImageChanged = () => {
    const { collectionId, image } = logoImage;
    return collectionDetails._id === collectionId && !!image?.name;
  };

  const saveLogoImage = async (logoData) => {
    let imageUrl = await uploadImageToS3(logoData.image);
    await dispatch(
      CollectionActions.updateCollection(logoData.collectionId, {
        ...collectionDetails,
        logo: imageUrl,
      })
    );
    await dispatch(
      CollectionActions.saveCollectionDetails({ ...collectionDetails })
    );
  };

  const uploadImageToS3 = async (file) => {
    try {
      await dispatch(LoadingActions.startLoader());
      let s3Service = new S3Service(s3Keys);
      let res = await s3Service.uploadFileToS3Bucket(file);
      await dispatch(LoadingActions.stopLoader());
      return res.Location;
    } catch (error) {
      await dispatch(LoadingActions.stopLoader());
      // console.log(error);
    }
  };

  return (
    <>
      <Nav className="">
        {props.checkCurrentPage === "INNER_PAGES" && (
          <>
            <CustomHeaderButton
              action={goToCollection}
              style={props.style}
              className="cmnBtn me-md-2 me-none"
              text={"CREATE"}
            />
            <CustomHeaderButton
              action={logout}
              style={props.style}
              className="loginBtn cmnBtn bg-white no-margin"
              text={"LOGOUT"}
            />

            <SettingsHeaderIcon />
          </>
        )}
        {props.checkCurrentPage === "DETAIL_PAGE_WITH_LOGIN" && (
          <>
            {isCustomizeButtonClicked === true ? (
              <>
                <CustomHeaderButton
                  style={props.style}
                  action={saveCustomization}
                  className="cmnBtn me-md-2 me-none"
                  text={"SAVE"}
                />
                {!collectionDetails?.isLive && (
                  <CustomHeaderButton
                    style={props.style}
                    action={() => setShowReleaseDateModal(true)}
                    className="loginBtn cmnBtn me-3 bg-white"
                    text={"PUBLISH"}
                  />
                )}
              </>
            ) : (
              <>
                <CustomHeaderButton
                  action={goToCollection}
                  style={props.style}
                  className="cmnBtn me-md-2 me-none"
                  text={"CREATE"}
                />
                <CustomHeaderButton
                  action={logout}
                  style={props.style}
                  className="loginBtn cmnBtn bg-white no-margin"
                  text={"LOGOUT"}
                />

                <SettingsHeaderIcon />
              </>
            )}
          </>
        )}
        {props.checkCurrentPage === "HOMEPAGE" && (
          <>
            <CustomHeaderButton
              action={goToCollection}
              style={props.style}
              className="cmnBtn me-md-2 me-none"
              text={"CREATE"}
            />
            <CustomHeaderButton
              action={logout}
              style={props.style}
              className="loginBtn cmnBtn bg-white no-margin"
              text={"LOGOUT"}
            />

            <SettingsHeaderIcon />
          </>
        )}
      </Nav>
      {!!collectionDetails && collectionDetails.currentBatchNo && (
        <ReleaseDateConfirmationModal
          show={showReleaseDateModal}
          handleClose={handleClose}
          setLiveParams={handleResponse}
          date={collectionDetails.currentBatchNo.whiteListStartTime}
          supply={collectionDetails.currentBatchNo.supply}
          collectionDetails={collectionDetails}
          setShowReleaseDateModal={setShowReleaseDateModal}
          title="Publish Dates"
        />
      )}
    </>
  );
}

export default HeaderLoginButtons;
