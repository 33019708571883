import React, { useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import { withRouter } from "react-router-dom";
import "./AccessModal.scss";

const AccessModal = (props) => {
  const {history, type} = props;

  const goToLink = () => {
    if(type && type === "publicInvitation"){
      props.setShowAccessModal(false)
    } else {
      history.push('/')
    }
  }

  return (
    <Modal
      scrollable={true}
      className="connect_wallet ss"
      show={props.show}
      size="lg"
      centered
    >
      {/* <Modal.Header closeButton>
        
      </Modal.Header> */}
      <Modal.Body className="accessModal">
        <Row>
          <Col className="baseToken_style">
            <div className="text-center">
              <InfoCircle size={30} className="mb-3" color="blue" />
            </div>
            {type && type === "publicInvitation" ? (
              <div className="d-flex align-items-center">
              <p>Note:-</p> 
              <span className="mx-2">{props.message}</span>
              </div>
            ) : ( <p> {props.message}</p>)}
           
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>goToLink()}>Back</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(AccessModal);
