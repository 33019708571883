import React from 'react'

function CustomHeaderButton({action, text, style, className}) {
    return (
        <button
        onClick={() => action()}
        style={
          !!style
            ? {
                background: style.buttonBackground,
                color: style.buttonColor,
              }
            : null
        }
        className={className}
      >
        {text}
      </button>
    )
}

export default CustomHeaderButton
