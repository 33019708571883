import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import add_circle from "../../../assets/images/add_circle_plus.svg";
import { Row, Col, Button, Image } from "react-bootstrap";
import { CustomizeActions } from "../../../redux/actions/customize.action";
import { NotificationActions } from "../../../redux/actions/notification.action";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { Enviroments } from "../../../constants/constants";
import { getTypeHeader } from "../../../utils/contentTypeHelper";

const CollectionType = ({ history, type }) => {
  const [collections, setCollections] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const limit = 3;
  const numberOfPages = Math.ceil(totalCount / limit);
  const {
    COLLECTION_TYPES: { LIVE },
    CONTENT_TYPE
  } = Enviroments;


  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e == 0 ? 0 : e - 1,
      filters: {},
      type: LIVE,
    };
    // getCollections(obj);
  };

  const getCollections = useCallback(async (data) => {
    const { getCollections } = CollectionActions;
    try {
      const res = await dispatch(getCollections(data));
      await dispatch(
        NotificationActions.checkDisabledCollection(res.data.data.list)
      );
      setCollections(res.data.data);
      // setCollections([]);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  },[dispatch, page]);

  
  const goToCollection = async (obj) => {
    await dispatch(CustomizeActions.setCustomizeButtonClicked(false));
    history.push({
      pathname: `/auth/collection/details/${obj.externalLink}`,
    });
  };
  useEffect(() => {
    if(totalCount === 0){
      getCollections({
        page: page == 0 ? 0 : page - 1,
        limit: limit,
        filters: {
          contentType:type
        },
        type: LIVE,
      });
    }

  }, []);
  
  return (
    <>
      <Row className="banner_row_col mt-1">
        <Col lg={9}>
          <div className="collection_col">
            <h2 className="main-heading">
              My {getTypeHeader(type)} NFT Collections{" "}
            </h2>
            <p>
              Create, curate, and manage collections of unique NFTs to share and
              sell.
            </p>
            <p>
              To create a customized minting page for your project, please click
              on the drafts tab
            </p>
          </div>
        </Col>
      </Row>

      <Row className="mb-4 collectionList">
        <Col>
          <div className="col-new mb-3 ">
            <p className="textStyle mt-auto">Create new collection</p>
            <Button
              className="create-btn mt-auto"
              onClick={() => history.push({
                pathname:"/auth/collection/CreateNew",
                state:{
                  type
                }
              })}
            >
              CREATE <img src={add_circle} />
            </Button>
          </div>
        </Col>

        {collections.totalCounts > 0 ? (
          <>
            {collections.list.map((row, i) => (
              <Col style={{ cursor: "pointer" }} key={i}>
                <div
                  className="coll-new col-new mb-3"
                  onClick={() => goToCollection(row)}
                >
                  <Image
                    fluid
                    style={{ height: "150px" }}
                    src={row.logo}
                    alt="name"
                  />
                  <h5>{row.originalName}</h5>
                </div>
              </Col>
            ))}
          </>
        ) : (
          <Row>
            <Col>
              <p className="noCollection">No Collections Found</p>
            </Col>
          </Row>
        )}
      </Row>
      {totalCount > limit && (
        <Row>
          <Col>
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={3}
              onChange={(e) => handlePageChange(e)}
            />
          </Col>
        </Row>
      )}
    </>
  );
};


export default withRouter(CollectionType);
