import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AdminActions } from "../../../redux/actions/admin.action";
import { Enviroments } from "../../../constants/constants";
import "./CategoriesFilter.scss";

const CategoriesFilter = ({ filterDrafts, filterGenre }) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState({
    list: [],
    totalCounts: 0,
  });

  const dropdownRef = useRef(null);

  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState("Choose One");

  const getCategories = async (obj) => {
    try {
      const res = await dispatch(AdminActions.getAllCategories(obj));
      setCategories({
        list: res.list,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createFilterData = (value) => {
    value = value.trim().toLowerCase();
    let selectedCategory = "";
    if (
      value === "all" ||
      value === "image" ||
      value === "music" ||
      value === "video"
    ) {
      selectedCategory = value;
    }
    return {
      contentType: Object.values(Enviroments.CONTENT_TYPE).includes(
        selectedCategory.toUpperCase()
      )
        ? selectedCategory.toUpperCase()
        : undefined,
    };
  };

  const handleCategoryChange = (value) => {
    let data = createFilterData(value);
    setIsSelected(value);
    console.log({ selectedData: data });
    filterDrafts(data);
    filterGenre(value);
  };

  useEffect(() => {
    const obj = {
      limit: 500,
      page: 0,
    };
    getCategories(obj);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Col lg={6}>
      <div className="collection_col col-padding d-flex mb-5">
        <h5>Search your drafts according to categories</h5>

        <div className="dropdown" id="select_cat" ref={dropdownRef}>
          <div
            onClick={(e) => {
              setIsActive(!isActive);
            }}
            className="dropdown-btn"
          >
            {selected}
            <span
              className={isActive ? "fas fa-caret-up" : "fas fa-caret-down"}
            />
          </div>

          <div
            className="dropdown-content"
            style={{ display: isActive ? "block" : "none" }}
          >
            <div
              onClick={(e) => {
                handleCategoryChange(e.target.textContent);
                setIsActive(!isActive);
              }}
              className="item"
            >
              All
            </div>
            <div
              onClick={(e) => {
                handleCategoryChange(e.target.textContent);
                setIsActive(!isActive);
              }}
              className="item"
            >
              Image
            </div>
            <div
              className="item"
              onClick={(e) => {
                handleCategoryChange(e.target.textContent);
                setIsActive(!isActive);
              }}
            >
              Music
            </div>

            <div
              className="item"
              onClick={(e) => {
                handleCategoryChange(e.target.textContent);
                setIsActive(!isActive);
              }}
            >
              Video
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default CategoriesFilter;
