import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Enviroments } from "../constants/constants";
import { CollectionActions } from "../redux/actions/collection.action";

const useTotalMintedCount = (mintedCountInfo, collectionDetails, isPrivate) => {
  const dispatch = useDispatch();
  const [totalMinted, setTotalMinted] = useState(mintedCountInfo || 0);

  // gives total minted nft
  const getMintedNftsCount = async (id) => {
    try {
      const { getMintedNftsCount } = CollectionActions;
      const role = Enviroments.MINTING_TYPES.public;

      let res = await dispatch(
        getMintedNftsCount(id, role, collectionDetails.currentBatchNo._id)
      );

      if (res.data.data?.mintedCount != null) {
        setTotalMinted(res.data.data.mintedCount);
      } else {
        setTotalMinted(0);
      }
    } catch (error) {
      setTotalMinted(0);
      // console.log(error);
    }
  };

  useEffect(() => {
    getMintedNftsCount(collectionDetails._id);
  }, [totalMinted]);

  return { totalMinted };
};

export default useTotalMintedCount;
