import React from "react";
import { Col, Row } from "react-bootstrap";

function BatchSupplyDetails({batchData, maxSupply}) {
  return (
    <Row>
      <Col className="mt-2">
        <div>
          <span>
            <b>Collection Name: </b>
            {batchData.list[0]?.collection.originalName}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <span>
            <b>Total Supply:</b> {batchData.list[0]?.collection.totalSupply}
          </span>
          <span>
            <b>Available Supply:</b> {maxSupply}
          </span>
        </div>
      </Col>
    </Row>
  );
}

export default BatchSupplyDetails;
