import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import { Layout } from "../../../common";
import Nft from "../../../common/Nft/Nft";
import NftAttributes from "../../../common/NftAttributes/NftAttributes";
import NftNameSerial from "../../../common/NftNameSerial/NftNameSerial";
import { Enviroments } from "../../../../constants/constants";
import AudioPlayer from "../../../common/AudioPlayer/AudioPlayer";
import VideoPlayer from "../../../common/VideoPlayer/VideoPlayer";

function NftDetails({ match, history }) {
  const {
    params: { collectionId, nftId, currentBatchNo },
  } = match;

  const [nfts, setNfts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [nftDetails, setNftDetails] = useState({});

  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.persist.isLoggedIn);

  const isPrivate = useMemo(() => {
    if (match.path.indexOf("auth") != -1 && isLoggedIn) {
      return true;
    }
    return false;
  }, [match, isLoggedIn]);

  const getCollectionNfts = async (obj) => {
    const { getCollectionNfts } = CollectionActions;
    let data = {
      ...obj,
      collection: collectionId,
      nftId,
      currentBatchNo,
      pageType: isPrivate ? "CREATOR" : "USER",
    };
    try {
      let res = await dispatch(getCollectionNfts(data));
      setNfts(res.data.data.list);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  };
  const getNftDetails = async () => {
    const { getNftDetails } = CollectionActions;
    const data = {
      nftId,
      collectionId,
      currentBatchNo,
    };
    try {
      let res = await dispatch(getNftDetails(data));
      setNftDetails(res.data.data);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    const obj = {
      limit: 8,
      page: 0,
      filters: {},
      currentBatchNo,
    };

    getCollectionNfts(obj);
    getNftDetails();
  }, [nftId]);

  return (
    <Layout>
      <Container>
        {nftDetails && Object.keys(nftDetails).length > 0 && (
          <>
            <Row xs={1} md={2} lg={2} className="my-5">
              <Col className="collectionImg">
                {!nftDetails.collection.contentType ||
                  (nftDetails.collection.contentType ===
                    Enviroments.CONTENT_TYPE.IMAGES && (
                    <Image fluid src={nftDetails.url} />
                  ))}

                {nftDetails.collection.contentType &&
                  nftDetails.collection.contentType ===
                    Enviroments.CONTENT_TYPE.MUSIC && (
                    <AudioPlayer
                      src={nftDetails.url}
                      fileName={nftDetails.fileName}
                      hidePlayIcon={false}
                      nftId={nftId}
                      cover={nftDetails?.coverImage || nftDetails?.collection.coverImage}
                    />
                  )}
                {nftDetails.collection.contentType &&
                  nftDetails.collection.contentType ===
                    Enviroments.CONTENT_TYPE.VIDEO && (
                    <VideoPlayer
                      src={nftDetails.url}
                      fileName={nftDetails.fileName}
                      hidePlayIcon={false}
                      nftId={nftId}
                      cover={nftDetails?.coverImage || nftDetails?.collection.coverImage}

                    />
                  )}
              </Col>
              <Col>
                <NftNameSerial nftDetails={nftDetails} />
                {nftDetails.areAttributesUploaded ? (
                  <NftAttributes
                    attributes={nftDetails.nftAttributes.attributes}
                  />
                ) : (
                  <p>No attributes uploaded</p>
                )}
              </Col>
            </Row>
            <Row xs={1} md={2} lg={2} className="mb-5">
              {nftDetails?.name && (
                <Col>
                  <h4>Name : </h4>
                  <span>{nftDetails.name}</span>
                </Col>
              )}
              {nftDetails?.description && (
                <Col>
                  <h4>Description : </h4>
                  <span>{nftDetails.description}</span>
                </Col>
              )}
            </Row>
          </>
        )}

        <Nft
          nfts={nfts}
          getNfts={getCollectionNfts}
          totalCount={totalCount}
          collectionId={collectionId}
          parent="nftDetails"
          currentBatchNo={currentBatchNo}
          collectionName={nftDetails?.collection?.originalName}
        />
      </Container>
    </Layout>
  );
}

export default withRouter(NftDetails);
