import { toast } from "react-toastify";
import Web3 from "web3";
import { NetworkHelper } from "../utils/networkHelper";
import contractErrorsService from "./contractErrors.service";
import Wallet from "./wallet.service";
import WalletStructure from "./walletStructure.service";

class Web3Service extends WalletStructure {
  initialize() {
    try {
      return Wallet.getProvider("metamask");
      
    } catch (error) {
      toast.error(error.message);
      // console.log("Web3 Error:", error);
      throw error;
    }
  }
 

  enableWallet() {
    this.provider = this.initialize()
    this.provider.enable();
  }

  async connectMetamask() {
    try {
      this.provider = Wallet.getProvider('metamask')
      let address = await this.provider.request({
        method: "eth_requestAccounts",
      });
      return {accounts:address, provider:this.provider};
    } catch (error) {
      const message = contractErrorsService.handleContractError(error)
      toast.error(message);
      // throw error;
    }
  }
}

export default new Web3Service();
