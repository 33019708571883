import types from "../types";

const initialState = {
  collections: { walletAddress: "", walletType: "" },
  isNFTCreationAllowed: false,
  currentCollection: {
    nftUploads: 0,
    collectionId: "",
  },
  nft: {
    collectionId: "",
    currentPage: 0,
  },
  collectionDetails: null,
  logoImage:{
    collectionId:"",
    image:null
  }
};

const collectionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.collection.SAVE_COLLECTION:
      return { ...state, collection: payload };

    case types.collection.ALLOW_NFT_CREATION:
      return { ...state, isNFTCreationAllowed: payload };

    case types.collection.SAVE_COLLECTION_WALLET_ADDRESS:
      return { ...state, collections: { ...state.collections, ...payload } };
    case types.collection.SAVE_NFT_UPLOAD:
      return { ...state, currentCollection: payload };
    case types.collection.SAVE_NFT_PAGE:
      return { ...state, nft: payload };
    case types.collection.SAVE_WALLET_TYPE:
      return { ...state, collections: { ...state.collections, ...payload } };
    case types.collection.SAVE_COLLECTION_DETAILS:
      return { ...state, collectionDetails: payload };
    case types.collection.SAVE_COLLECTION_LOGO_IMAGE:
      return { ...state, logoImage: payload };
    default:
      return state;
  }
};

export default collectionReducer;
