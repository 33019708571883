import { Enviroments } from "../constants/constants";
import coinbaseService from "./coinbase.service";
import walletconnectService from "./walletconnect.service";
import WalletStructure from "./walletStructure.service";
import web3Service from "./web3.service";

class WalletManager extends WalletStructure {


  initialize(type, chainId, redux) {
    // localStorage.removeItem('walletconnect')
    const {WALLET_TYPES:{metamask, walletConnect}} = Enviroments
    if (type ===  metamask || !type) {
      this.provider = web3Service.initialize();
    } else if (type === walletConnect) {
      this.provider = walletconnectService.initialize(chainId);
    } 
    return this.provider;
  }

  async enableWallet(type, redux, undefined, infuraId) {
    try {
      const {WALLET_TYPES:{metamask, walletConnect, coinbase}} = Enviroments
      let obj=null;
      if (type === metamask || !type) {
        obj = await web3Service.connectMetamask();
      } else if(type === walletConnect){
        obj = await walletconnectService.enableWallet(redux, undefined, infuraId)
      } else if(type === coinbase){
        obj = await coinbaseService.enableWallet()
      }
   
      return obj;
    } catch (error) {
        throw error;
    }
   
  }

  async disconnect(){
    await this.provider.disconnect()
  }

  
}

export default new WalletManager();
