import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { toasts as toast } from "../Toast/Toast";
import "./GenerateInvitationCode.scss";
import { Enviroments } from "../../../constants/constants";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

function GenerateInvitationCode({ getInvitations, collectionDetails }) {
  const { INVITATION_TYPES, TOOLTIP:{INVITATION} } = Enviroments;
  const [invitationData, setInvitationData] = useState({
    maxNumberOfWallet: 0,
    type: INVITATION_TYPES.private,
  });

  const { currentBatchNo } = collectionDetails;
  const dispatch = useDispatch();

  const selectNumber = (e) => {
    setInvitationData((data) => {
      return {
        ...data,
        maxNumberOfWallet: e.target.value,
      };
    });
  };

  const getInvitationCode = async (data) => {
    console.log({ invitationCode: data });
    try {
      const { getInvitationCode } = CollectionActions;
      await dispatch(
        getInvitationCode({
          ...data,
          collectionId: collectionDetails._id,
          currentBatchNo: currentBatchNo._id,
        })
      );
      setInvitationData((data) => {
        return {
          ...data,
          maxNumberOfWallet: 0,
        };
      });
      getInvitations({ page: 0, limit: 10 });
    } catch (error) {}
  };

  const handleSubmit = (e) => {
    if (invitationData.type === INVITATION_TYPES.public || invitationData.type === INVITATION_TYPES.csv) {
      invitationData.maxNumberOfWallet = 0;
    } else {
     
      console.log({ invitationData });
      if (Number(invitationData.maxNumberOfWallet) <= 0) {
        toast.error("Seats can't be less than 1");
        return;
      }
      if (
        invitationData.maxNumberOfWallet !=
        parseInt(invitationData.maxNumberOfWallet)
      ) {
        toast.error("Seats can be integer only");
        return;
      }
    }
    getInvitationCode(invitationData);
  };

  const handleRadioClick = (e) => {
    setInvitationData((data) => {
      return {
        ...data,
        type: e.target.value,
      };
    });
  };

  return (
    <div className="my-3 invitation_container">
      <Row>
        <Col className="baseToken_style">
          <label>No. of Wallets</label> &nbsp;
          {invitationData.type == INVITATION_TYPES.private && (
            <input
              type="number"
              value={invitationData.maxNumberOfWallet}
              onChange={selectNumber}
            />
          )}
          <span className="mx-3">
            <Form.Check
              inline
              label="Private Link"
              name="group1"
              type="radio"
              className="invitation_link"
              onClick={handleRadioClick}
              value={INVITATION_TYPES.private}
              checked={invitationData.type === INVITATION_TYPES.private}
              id={`inline-radio-1`}
            />
            <CustomTooltip title={INVITATION.PRIVATE_LINK}/>
            <Form.Check
              inline
              label="Public Link"
              name="group1"
              type="radio"
              className="invitation_link"
              value={INVITATION_TYPES.public}
              checked={invitationData.type === INVITATION_TYPES.public}
              onClick={handleRadioClick}
              id={`inline-radio-2`}
            />

            <CustomTooltip title={INVITATION.PUBLIC_LINK}/>

            <Form.Check
              inline
              label="CSV Link"
              name="group1"
              type="radio"
              className="invitation_link"
              value={INVITATION_TYPES.csv}
              checked={invitationData.type === INVITATION_TYPES.csv}
              onClick={handleRadioClick}
              id={`inline-radio-3`}
            />
            <CustomTooltip title={INVITATION.CSV_LINK}/>

            {currentBatchNo.isWhiteListedEnable && (
              <Button
                className="ms-2 cmnBtn"
                variant="primary"
                onClick={handleSubmit}
              >
                Generate
              </Button>
            )}
          </span>
        </Col>
      </Row>
    </div>
  );
}

export default GenerateInvitationCode;
