import React from "react";
import { Button, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import lockImage from "../../../assets/images/lock.png";
import "./SettingsHeaderIcon.scss";

function SettingsHeaderIcon({ history }) {
  const goToSettingsPage = () => {
    if(history.location.pathname.indexOf('mainsetting') === -1)
    history.push("/auth/mainsetting");
  };

  return (
    <Button className="lock_image p-0" onClick={() => goToSettingsPage()}>
      <Image src={lockImage} />
    </Button>
  );
}

export default withRouter(SettingsHeaderIcon);
