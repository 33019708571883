import React, { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import {Enviroments} from '../../../constants/constants'

function PrivateMinting({ supply, getPrivateMintData, lastNft }) {
  console.important({lastNft})
  const {TOOLTIP:{PRIVATE_MINTING}} = Enviroments;

  const [privateMinting, setPrivateMinting] = useState({
    isPrivateMinting: "no",
    privateNft: [],
    isStart:"yes",
    range: {
      from: lastNft && lastNft.index > -1 ? lastNft.index+1 : 1,
      to: lastNft && lastNft.index > -1 ? lastNft.index+supply:supply,
    },
  });

  // const [sliderMinMax, setSliderMinMax] = useState({
  //   min: 1,
  //   max: supply,
  // });

  const handlePrivateMinting = ({ target: { name, value } }) => {
    setPrivateMinting((x) => {
      return {
        ...x,
        [name]: value,
      };
    });

  };

  const handleIsStart = (e) => {
    const {value} = e.target;
    if(value === "yes"){
      setPrivateMinting((x) => {
        return {
          ...x,
          isStart: value,
          range:{
            ...x.range,
            from:lastNft && lastNft.index > -1 ? lastNft.index+1 : 1,
            to:lastNft && lastNft.index > -1 ? lastNft.index+supply : supply
          }
        };
      });
    } else if(value === "no") {
      setPrivateMinting((x) => {
        return {
          ...x,
          isStart: value,
          range:{
            ...x.range,
            from: lastNft && lastNft.index > -1 ? lastNft.index+1 : 1,
            to:lastNft && lastNft.index > -1 ? lastNft.index+supply : supply,
          }
        };
      });
    }
    

  }

  // const handleSlider = ({ min, max }) => {
  //   setSliderMinMax({
  //     min,
  //     max,
  //   });

  //   getPrivateMintData({ ...privateMinting, min, max });
  // };

  const handleRangeValue = (e) => { 
    const {name,value} = e.target;
    console.log(value);
    setPrivateMinting({...privateMinting, range:{
      ...privateMinting.range,
      [name]:value
    }})
  }

  const handleMinMax = (e) => {
    console.important({lastNft})
    const {name,value} = e.target;
    let min,max = 0;
    if(privateMinting.isStart ==="yes"){
      if(name === "from"){
        min = lastNft && lastNft.index > -1 ? lastNft.index+1 : 1;
        max = lastNft && lastNft.index > -1 ? lastNft.index+1 : 1;
      } else if(name === "to"){
        min = lastNft && lastNft.index > -1 ? lastNft.index+1 : 1;
        max = lastNft && lastNft.index > -1 ? lastNft.index+supply : supply;
      }
    } else if(privateMinting.isStart === "no"){
      if(name === "from"){
        min = lastNft && lastNft.index > -1 ? lastNft.index+1 : 1
        max = lastNft && lastNft.index > -1 ? lastNft.index+supply : supply;
      } else if(name === "to"){
        min = lastNft && lastNft.index > -1 ? lastNft.index+supply : supply;
        max = lastNft && lastNft.index > -1 ? lastNft.index+supply : supply;
      }
    }
    if(value > max){
      setPrivateMinting({...privateMinting, range:{
        ...privateMinting.range,
        [name]:max
      }})
    }
    else if(value < min){
      setPrivateMinting({...privateMinting, range:{
        ...privateMinting.range,
        [name]:min
      }})
    }
   
  }

  const getMinMaxValue = (name,type) => {
    if(name==="from"){
      if(privateMinting.isStart === "yes"){
        if(type === "min") return lastNft && lastNft.index > -1 ? lastNft.index+1 : 1;
        if(type === "max") return lastNft && lastNft.index > -1 ? lastNft.index+1 : 1;
      } else if(privateMinting.isStart === "no") {
        if(type === "min") return lastNft && lastNft.index > -1 ? lastNft.index+1 : 1;
        if(type === "max") return lastNft && lastNft.index > -1 ? lastNft.index+supply -1 : supply -1;;
      }
    } else if(name==="to"){
      if(privateMinting.isStart === "yes"){
        if(type === "min") return +privateMinting.range.from + 1;
        if(type === "max") return lastNft && lastNft.index > -1 ? lastNft.index+supply : supply;
      } else if(privateMinting.isStart === "no") {
        if(type === "min") return lastNft && lastNft.index > -1 ? lastNft.index+supply : supply;
        if(type === "max") return lastNft && lastNft.index > -1 ? lastNft.index+supply : supply;
      }
    }
  }

  useEffect(()=>{
    getPrivateMintData({ ...privateMinting });
  },[privateMinting])

 

  return (
    <Col>
      <label>Do you want private minting?</label>
      <div className="d-flex align-items-center mb-2">
        <div className="fldWrap customRadio me-2">
          <input
            type="radio"
            id="test3"
            onChange={handlePrivateMinting}
            name="isPrivateMinting"
            checked={privateMinting.isPrivateMinting === "yes"}
            value={"yes"}
          />{" "}
          <label for="test3">Yes</label>
        </div>
        <div className="fldWrap me-2 customRadio">
          <input
            type="radio"
            id="test4"
            onChange={handlePrivateMinting}
            name="isPrivateMinting"
            checked={privateMinting.isPrivateMinting === "no"}
            value={"no"}
          />{" "}
          <label for="test4"> No</label>
        </div>
      </div>
      {privateMinting.isPrivateMinting == "yes" && (
        <>
          <div className="d-flex align-items-center">
            <div className="fldWrap me-2 customRadio">
              <input
                type="radio"
                onChange={handleIsStart}
                name="isStart"
                id="test5"
                checked={privateMinting.isStart === "yes"}
                value={"yes"}
              />{" "}
              <label for="test5"> Start</label>
              <CustomTooltip title={PRIVATE_MINTING.START} />
            </div>
            <div className="fldWrap me-2 customRadio">
              <input
                type="radio"
                onChange={handleIsStart}
                name="isStart"
                id="test6"
                checked={privateMinting.isStart === "no"}
                value={"no"}
              />{" "}
              <label for="test6"> End</label>
              <CustomTooltip title={PRIVATE_MINTING.END} />
            </div>
          </div>

          <div className="my-3">
            {/* <MultiRangeSlider
                min={1}
                max={supply}
                minDisabled={
                  privateMinting.isStart === "yes" && sliderMinMax.min == 1
                }
                maxDisabled={
                  privateMinting.isStart === "no" && sliderMinMax.max == supply
                }
                onChange={(data) => handleSlider(data)}
                renderAgain={positionChangedTimes}
              /> */}
            <Row>
              <Col md={6}>
                <input
                  min={getMinMaxValue('from','min')}
                  max={getMinMaxValue('from','max')}
                  value={privateMinting.range.from}
                  placeholder="from"
                  type="number"
                  name="from"
                  id=""
                  className="form-control"
                  onChange={handleRangeValue}
                  onBlur={event=>handleMinMax(event)}
                />
              </Col>
              <Col md={6}>
                <input
                  min={getMinMaxValue('to','min')}
                  max={getMinMaxValue('to','max')}
                  value={privateMinting.range.to}
                  placeholder="to"
                  type="number"
                  name="to"
                  id=""
                  className="form-control"
                  onChange={handleRangeValue}
                  onBlur={event => handleMinMax(event)}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </Col>
  );
}

export default PrivateMinting;
