// Resp: Show batches
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { CollectionActions } from "../../../../redux/actions/collection.action";
import { Layout } from "../../../common";
import BatchDraft from "../../../common/BatchDraft/BatchDraft";
import BatchTable from "../../../common/BatchTable/BatchTable";
import CreateBatchAction from "../../../common/CreateBatchAction/CreateBatchAction";

function Batches({ match, location, history }) {
  const {
    params: { collectionId },
  } = match;


  const [batchData, setBatchData] = useState({
    list: [],
    totalCounts: 0,
  });

  const [maxSupply, setMaxSupply] = useState(0);

  const [key, setKey] = useState(history.location?.state?.name ? "draft" : "published");
  const dispatch = useDispatch();

  const selectTab = (k) => {
    console.log("batchDataOnTab");
    setBatchData({
      list:[],
      totalCounts:0
    });
    setKey(k)
  }

  
  const getMaxSupply = (list) => {
    if (list.length > 0) {
      return (
        list[0].collection.totalSupply -
        list.reduce((total, batch) => {
          return total + batch.supply;
        }, 0)
        );
      }
      return 0;
    };
    
    const getLastElement = (data) => {
      return data.list[data.list.length - 1];
    };
    
    const getBatchesOfCollection = async (data) => {
      try {
        console.log({ batchDataBefore: data });
        let res = await dispatch(CollectionActions.getBatchesOfCollection(data));
        const newBatchData = {...res}
        newBatchData.list = res.list.sort((x,y)=>x.batchNo - y.batchNo)
        setBatchData(newBatchData);
        console.log({ newMaxSupply: getMaxSupply(newBatchData.list) });
      setMaxSupply(getMaxSupply(newBatchData.list));
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    let obj = {
      page: 0,
      limit: 10,
      collectionId,
      type: key.toUpperCase(),
    };
    getBatchesOfCollection(obj);
  }, [collectionId, key]);
  
  
  return (
    <>
      <div>
        <Layout>
          <Container>
            <Row>
              <div className="d-flex-column mt-5">
                
                <Row>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => selectTab(k)}
                  >
                    <Tab eventKey="draft" title="Drafts">
                      {key == "draft" && (
                        <Row className="mt-3">
                          <BatchDraft
                            batchData={batchData}
                            collectionId={collectionId}
                            setKey={setKey}
                            setBatchData={setBatchData}
                          />
                        </Row>
                      )}
                    </Tab>
                    <Tab eventKey="published" title="Published">
                      {key == "published" && (
                        <>
                          <Row className="mt-3">
                            <BatchTable
                              batchData={batchData}
                              getBatchesOfCollection={getBatchesOfCollection}
                              collectionId={collectionId}
                              setKey={setKey}
                              maxSupply={maxSupply}
                            />
                          </Row>
                          {batchData.list.length > 0 && (
                            <>
                              {(getLastElement(batchData).nftsMinted.length > 0
                                ? getLastElement(batchData).nftsMinted[0].count
                                : 0) === getLastElement(batchData).supply && maxSupply > 0 && (
                                <CreateBatchAction
                                  batch={getLastElement(batchData)}
                                  getBatchesOfCollection={
                                    getBatchesOfCollection
                                  }
                                  setKey={setKey}
                                  maxSupply={maxSupply}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </Tab>
                  </Tabs>
                </Row>
              </div>
            </Row>
          </Container>
        </Layout>
      </div>
    </>
  );
}

export default withRouter(Batches);
