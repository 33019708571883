import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import GenerateInvitationCode from "../GenerateInvitationCode/GenerateInvitationCode";
import InvitationList from "../InvitationList/InvitationList";
import MySwal from "../../../services/swal.service";

function ModalInvitationList({
  setShow,
  show,
  collectionDetails
}) {

  const [invitationData, setInvitationData] = useState({
    list: [],
    totalCounts: 0,
  });

  const page = 0;
  const limit = 10;

  const dispatch = useDispatch();
  const closeModal = () => {
    setShow(false);
  };


  const getInvitations = async ({page=0, limit=10}) => {
    const { getInvitations } = CollectionActions;
    let data = {
      collectionId: collectionDetails._id,
      page,
      limit,
      currentBatchNo:collectionDetails.currentBatchNo?._id
    };
    try {
      let res = await dispatch(getInvitations(data));
      if (res) {
        setInvitationData(res.data.data);
        
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const deleteInvitation = async (data) => {
    const { deleteInvitation } = CollectionActions;
    try {
      await MySwal.delete();
      let res = await dispatch(deleteInvitation(data));
      MySwal.showSuccessMessage("Deleted!")
      if (res) {
        getInvitations({page,limit});
      }
      
    } catch (error) {
      MySwal.showInfoMessage("Changes are not saved")
      // console.log(error);
    }
  };

  

  useEffect(()=>{
    getInvitations({page,limit})
  },[])


  return (
    <Modal
      scrollable={true}
      className="connect_wallet ss"
      show={show}
      size="xl"
      centered
      onHide={closeModal}
      fullscreen={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Invitations List</Modal.Title>
      </Modal.Header>
      <Modal.Body className="accessModal">
        <GenerateInvitationCode
          getInvitations={getInvitations}
          collectionDetails={collectionDetails}
        />
        <InvitationList
          deleteInvitation={deleteInvitation}
          collectionDetails={collectionDetails}
          invitationData={invitationData}
          getInvitations={getInvitations}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalInvitationList;
