import React from "react";
import { Col, Row } from "react-bootstrap";
import "./nftAttributes.scss";

function NftAttributes({ attributes }) {
  return (
    <Row className="Right nftAttributes">
      <Col className="Right nftAttributes">
        <h2 className="mb-3">Attributes</h2>
        <ul>
          {attributes &&
            attributes.length > 0 &&
            attributes.map((attr) => (
              <li>
                <h4>
                  {attr.trait_type}: <span>{attr.value}</span>{" "}
                </h4>
              </li>
            ))}
        </ul>
      </Col>
    </Row>
  );
}

export default NftAttributes;
