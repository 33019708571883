import types from "../types";

const initialState = {
    data:null,
    actionText:'',
    text:'',
    hide:false
};

const notificationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.notifications.SET_NOTIF_DATA:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default notificationReducer;
