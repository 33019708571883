import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Web3 from "web3";
import { Enviroments } from "../constants/constants";
import Wallet from "./wallet.service";
import WalletStructure from "./walletStructure.service";

class CoinbaseService extends WalletStructure {
  
  initialize(chainId) {
    try {
      let isCoinbaseWallet;
      isCoinbaseWallet = Wallet.getProvider("coinbase");
      if (!isCoinbaseWallet) throw new Error("Coinbase is not installed");

      const { APP_NAME, APP_LOGO_URL, DEFAULT_CHAIN_ID, DEFAULT_ETH_JSONRPC_URL } = Enviroments.COINBASE_WALLET_CONSTANTS;
      const coinbaseWallet = new CoinbaseWalletSDK({
        appName: APP_NAME,
        appLogoUrl: APP_LOGO_URL,
        darkMode: false,
      });

      return coinbaseWallet.makeWeb3Provider(
        DEFAULT_ETH_JSONRPC_URL,
        chainId || DEFAULT_CHAIN_ID
      );
      
    } catch (error) {
      console.log(error);
    }
  }

  async enableWallet() {
   this.provider = this.initialize()
    let accounts = await this.provider.request({ method: "eth_requestAccounts" });
    return {accounts,provider:this.provider};
  }
}

export default new CoinbaseService();
