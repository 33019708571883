import React from "react";
import "./CustomTooltip.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Image from "react-bootstrap/Image";
import Tooltip from "react-bootstrap/Tooltip";

function CustomTooltip({title}) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="button-tooltip-2">
          {title}{" "}
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        
          <Image
            ref={ref}
            {...triggerHandler}
            className="tooltip_img"
            src={require("../../../assets/images/exclamation.svg").default}
          />
          
        
      )}
    </OverlayTrigger>
  );
}

export default CustomTooltip;
