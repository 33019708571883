import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { BatchReduxActions } from "../../../redux/actions/batch.action";
import { CollectionActions } from "../../../redux/actions/collection.action";
import swalService from "../../../services/swal.service";
import { toasts as toast} from "../Toast/Toast";

function CreateBatchAction({
  history,
  batch,
  getBatchesOfCollection,
  setKey,
  maxSupply,
}) {
  console.log({ maxSupply });
  const dispatch = useDispatch();

  const handleBatch = async (data, newBatchData) => {
    console.log({ batchData: data });
    console.log({previousBatchNo:batch.currentBatchNo, newBatchNo:newBatchData.currentBatchNo})

    await dispatch(BatchReduxActions.saveBatchDraft(batch));
    history.push({
      pathname: `/auth/collection/UploadNft/${batch.collectionId}`,
      state: {
        externalLink: batch.collection.externalLink,
        currentBatchNo: newBatchData.currentBatchNo,
        supply:newBatchData.supply,
        contentType:batch.collection.contentType
      },
    });
    // setKey("draft")
    // getBatchesOfCollection(obj);
   
  };


  const createBatch = async () => {
    if(maxSupply == 0) {
      toast.error("All nfts are minted. Can't create more batches")
      return;
    }
    const { isConfirmed } = await swalService.confirmAction(
      "Do you want to create a new batch?"
    );

    if (isConfirmed) {
      let data = {
        batchNo: Number(batch.batchNo) + 1,
        collectionId: batch.collectionId,
      };
      console.log({ createBatchData: data });
      try {
        let res = await dispatch(CollectionActions.createNewBatch(data));
        console.log({ newbatchData: res });
        await handleBatch(data, res);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Button onClick={() => createBatch()}>Create Batch</Button>
    </>
  );
}

export default withRouter(CreateBatchAction);
