import React from "react";
import BatchDetailAction from "../BatchDetailAction/BatchDetailAction";

function BatchActions({ batch, getBatchesOfCollection, setKey, maxSupply }) {
  return (
    <td>
      <div className="d-flex justify-content-center">
        <BatchDetailAction batch={batch} />
        
      </div>
    </td>
  );
}

export default BatchActions;
