import types from "../types";

const saveWalletAddress = (data) => ({
  type: types.persist.SAVE_WALLET_ADDRESS,
  payload: data,
});

const setLogin = () => ({
  type: types.persist.SAVE_IS_LOGGED_IN,
});

const resetStore = () => ({
  type: types.persist.RESET_STORE,
});

const saveToken = (data) => ({
    type: types.persist.SAVE_JWT_TOKEN,
    payload:data
});

const saveIsResent = (data) => ({
  type: types.persist.OTP_RESENT_BOOL,
  payload: data
});

const saveUser = (data) => ({
    type: types.persist.SAVE_USER,
    payload:data
});

export const PersistActions = {
  saveWalletAddress,
  setLogin,
  resetStore,
  saveToken,
  saveUser,
  saveIsResent
};
