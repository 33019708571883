import React, { useEffect, useState } from "react";
import { Accordion, Container } from "react-bootstrap";
import "./Faq.scss";
import { AdminActions } from "../../../../redux/actions/admin.action";
import Pagination from "react-js-pagination";
import prev from "../../../../assets/images/prev.png";
import next from "../../../../assets/images/next.png";
import { useDispatch, useSelector } from "react-redux";
import banner1 from "../../../../assets/images/Banner.svg";
import BANNER_WELCOME_PAGE from "../../../../assets/images/BANNER_WELCOME_PAGE.png";
import BANNER_HELP_CENTER from "../../../../assets/images/BANNER_HELP_CENTER.png";
import BANNER_SPOZZ_BLOG from "../../../../assets/images/BANNER_SPOZZ_BLOG.png";
import Slider from "react-slick";
import { ButtonCommon } from "../../../common";
import { withRouter } from "react-router-dom";
import FaqSlide from "../../../common/FaqSlide/FaqSlide";

const ArrowNext = (props) => {
  return (
    <div className="arrow arrowNext" onClick={props.onClick}>
      <img src={prev} alt="" />
    </div>
  );
};

const ArrowPrev = (props) => {
  return (
    <div className="arrow arrowPrev" onClick={props.onClick}>
      <img src={next} alt="" />
    </div>
  );
};
const Faq = ({ history }) => {
  const [faqList, setFaqList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [page, setPage] = useState(0);
  const limit = 4;
  const handlePageChange = (e) => {
    setPage(e);
    const obj = {
      limit: limit,
      page: e == 0 ? 0 : e - 1,
    };
    fetchFaq(obj);
  };
  const dispatch = useDispatch();

  const fetchFaq = async (data) => {
    const { fetchFaq } = AdminActions;
    try {
      const res = await dispatch(fetchFaq(data));
      setFaqList(res.data.data.list);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {
      // console.log(error);
    }
  };
  function createMarkup(data) {
    return { __html: data.answer };
  }
  useEffect(() => {
    fetchFaq({ limit: limit, page: page });
  }, []);

  const settings = {
    dots: false,
    // infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: -1,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
  };

  const goToFaqDetails = (id) => {
    history.push(`/faq-details/${id}`);
  };

  return (
    <div className="CollapseBg">
      {/* <Container> */}
      {/* <h2 className="mb-4 maintitle">Frequently Asked Questions</h2> */}
      {/* <Accordion className="mb-4" >
          {faqList.length > 0 &&
            faqList.map((data, i) => (
              <Accordion.Item eventKey={i}>
                <Accordion.Header>{data.question}</Accordion.Header>
                <Accordion.Body dangerouslySetInnerHTML={createMarkup(data)} />
              </Accordion.Item>
            ))}
        </Accordion>
        {totalCount > limit && (
          <div className="layoutStyle fQ">
            <Pagination
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
            />
          </div>
        )} */}
      <div className="slider_slick">
        <Slider {...settings}>
          {faqList.length > 0 &&
            faqList.map((data, i) => (
              <div className="slick_slide">
                <img src={BANNER_WELCOME_PAGE} alt="" />
                <div className="slide_content d-flex justify-content-center">
                  <h2>{data.question}</h2>
                  <ButtonCommon
                    onClick={() => goToFaqDetails(data._id)}
                    className="read_btn"
                    buttonText="Read More"
                  />
                </div>
              </div>
            ))}

          <FaqSlide
            src={BANNER_HELP_CENTER}
            text="Got Questions? We've got Answers!"
            url="https://help.spozz.club/en/support/home"
          />

          <FaqSlide
            src={BANNER_SPOZZ_BLOG}
            text="SPOZZ Blog: Stay Informed, Stay Inspired"
            url="https://blog.spozz.club"
          />

          <FaqSlide
            src={BANNER_WELCOME_PAGE}
            text="Discover SPOZZ.club: Unveiling the Ecosystem"
            url="https://welcome.spozz.club"
          />

          {/* <div className="slick_slide">
              <img src={banner1} alt="" />
              <h2>How to <br /><span>become a Member</span></h2>
            </div>
            <div className="slick_slide">
              <img src={banner1} alt="" />
              <h2>How to <br /><span>become a Member</span></h2>
            </div> */}
        </Slider>
      </div>
      {/* </Container> */}
    </div>
  );
};

export default withRouter(Faq);
