import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Button } from "react-bootstrap";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Enviroments } from "../../../constants/constants";
import moment from "moment";
import { toasts as toast } from "../Toast/Toast";
import PrivateMinting from "../PrivateMinting/PrivateMinting";
import { CollectionActions } from "../../../redux/actions/collection.action";
import { useDispatch } from "react-redux";
import PriceAndMintPerWallet from "../PriceAndMintPerWallet/PriceAndMintPerWallet";
import { ReleaseDateService } from "./ReleaseDataConfirmationService";

function ReleaseDateConfirmationModal(props) {
  let { COUNTDOWN_TYPES, PUBLISH_DATES } = Enviroments;
  const times = PUBLISH_DATES;
  const res =
    props.collectionDetails && props.collectionDetails.isWhiteListedEnable
      ? "yes"
      : "no";
  const [isWhiteList, setIsWhiteList] = useState(res);
  const [countdownTimes, setCountdownTimes] = useState({ ...times });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [privateMintData, setPrivateMintdata] = useState({
    isPrivateMintingAllowed: false,
    startMint: 0,
    endMint: 0,
  });

  const [priceAndMint, setPriceAndMint] = useState(null);

  const dispatch = useDispatch();

  const setPublishParams = (date) => {
    for (let time in times) {
      times[time].start_time = date;
      times[time].end_time = date;
    }

    setCountdownTimes(times);
  };

  const handleWhitelistChange = (e) => {
    setIsWhiteList(e.target.value);
  };

  const setTime = (index, type, date) => {
    let item = Object.keys(countdownTimes)[index];
    let itemType = Object.keys(countdownTimes[item])[type];
    let selectedItem = countdownTimes[item][itemType];
    selectedItem = date;
    setCountdownTimes((times) => {
      return {
        ...times,
        [item]: { ...times[item], [itemType]: selectedItem },
      };
    });
  };

  const handleResponse = (choice, dates, isWhiteList) => {
    console.important({ dates });
    try {
      if (props.supply != undefined && props.supply == 0) {
        throw new Error("Please add NFTs in collection before publish");
      }
      const {
        validatePrivateMinting,
        validatePriceAndMintPerWallet,
        handlePreSaleAndPostSale,
      } = ReleaseDateService;
      if (choice) {
        let isPrivateMintingValid = validatePrivateMinting(privateMintData);
        let isPriceAndMintPerWalletValid = true;
        if (!props.showUpdateDatesModal) {
          isPriceAndMintPerWalletValid =
            validatePriceAndMintPerWallet(priceAndMint);
        }
        if (isPrivateMintingValid && isPriceAndMintPerWalletValid) {
          handlePreSaleAndPostSale(
            dates,
            isWhiteList,
            props,
            privateMintData,
            priceAndMint,
            updateDates
          );

          props.setShowReleaseDateModal(false);
        }
      } else {
        if (props.setShowUpdateDatesModal) {
          props.setShowUpdateDatesModal(false);
        }
        props.setShowReleaseDateModal(false);
      }
    } catch (error) {
      toast.error(error.message);
      // props.setShowReleaseDateModal(false);
      // if (props.setShowUpdateDatesModal) {
      //   props.setShowUpdateDatesModal(false);
      // }
    }
  };

  const handlePrivateMinting = (data) => {
    console.log({ privateMintData: data });
    let obj = {
      isPrivateMintingAllowed: data.isPrivateMinting == "yes" ? true : false,
      startMint: data.range.from,
      endMint: data.range.to,
    };

    console.important({ range: obj });
    setPrivateMintdata(obj);
  };

  const updateDates = async (data) => {
    try {
      data.batchStatus = "ON_GOING";
      let res = await dispatch(
        CollectionActions.updateBatch(
          props.collectionDetails.currentBatchNo._id,
          data
        )
      );
      toast.success("Dates have been extended successfully");
      props.setShowUpdateDatesModal(false);
      props.handleDatesUpdate(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPriceAndMint = (obj) => {
    console.log({ formikValues: obj });
    const { errors, values } = obj;
    setPriceAndMint({ values, errors });
  };

  useEffect(() => {}, [countdownTimes]);

  useEffect(() => {
    if (props.show) {
      setPublishParams(currentDate);
    }
  }, [props.show]);

  return (
    <Modal
      scrollable={true}
      className="connect_wallet modalComn"
      show={props.show}
      onHide={props.handleClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="baseToken_style">
            <label>Do you want to whitelist users? </label>
            <div className="d-flex align-items-center mb-2">
              <div className="fldWrap customRadio me-2">
                <input
                  type="radio"
                  onChange={handleWhitelistChange}
                  name="isWhitelist"
                  id="test1"
                  checked={isWhiteList === "yes"}
                  value={"yes"}
                />{" "}
                <label for="test1"> Yes</label>
              </div>
              <div className="fldWrap customRadio me-2">
                <input
                  type="radio"
                  id="test2"
                  onChange={handleWhitelistChange}
                  name="isWhitelist"
                  checked={isWhiteList === "no"}
                  value={"no"}
                />{" "}
                <label for="test2">No</label>
              </div>
            </div>

            {isWhiteList == "yes" ? (
              <Row>
                {Object.values(COUNTDOWN_TYPES).map((item, index) => {
                  return Object.values(item).map((x, y) => (
                    <Col md={6} lg={6}>
                      <div className="mb-2">
                        <label>{x.select_placeholder}</label>
                        <Flatpickr
                          name="date"
                          className="form-control"
                          data-enable-time
                          value={currentDate}
                          placeholder="MM-DD-YYYY"
                          options={{
                            dateFormat: "d/m/Y H:i",
                            minuteIncrement: 1,
                          }}
                          onChange={([date]) => {
                            setTime(index, y, date);
                          }}
                        />
                      </div>
                    </Col>
                  ));
                })}
              </Row>
            ) : (
              <Row>
                {Object.values(COUNTDOWN_TYPES.postsale).map((type, index) => (
                  <Col md={6} lg={6}>
                    <div className="mb-2">
                      <label>{type.select_placeholder}</label>
                      <Flatpickr
                        name="date"
                        className="form-control"
                        data-enable-time
                        value={currentDate}
                        options={{
                          dateFormat: "d/m/Y H:i",
                          minuteIncrement: 1,
                        }}
                        placeholder="MM-DD-YYYY"
                        onChange={([date]) => {
                          setTime(2, index, date);
                        }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            )}

            {/* main sale time */}
          </Col>
        </Row>
        {/* private minting  */}
        {!props.showUpdateDatesModal && (
          <>
            <Row>
              <PrivateMinting
                supply={props.supply}
                getPrivateMintData={handlePrivateMinting}
                lastNft={props.lastNft}
                
              />
            </Row>
            {/** Mint per wallet and supply */}
            <PriceAndMintPerWallet
              getPriceAndMint={getPriceAndMint}
              supply={props.supply}

            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cmnBtn"
          onClick={() => handleResponse(true, countdownTimes, isWhiteList)}
        >
          Yes
        </Button>
        <Button className="cmnBtn" onClick={() => handleResponse(false)}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReleaseDateConfirmationModal;
