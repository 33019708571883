import { toast } from "react-toastify";
import { ApiService } from "../../services/api.service";
import { LoadingActions } from "./loading.action";
import { PersistActions } from "./persist.action";


const register = (data) => {
  return async (dispatch, getState) => {
    const { startLoader, stopLoader } = LoadingActions;
    try {
      await dispatch(startLoader());
      const headers = { "content-type": "application/json" };
      let res = await ApiService.register(data, headers);
      await dispatch(stopLoader());
      return res;
    } catch (err) {
      await dispatch(stopLoader());
      throw err
      // console.log(err);
    }
  };
};

const login = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { startLoader, stopLoader } = LoadingActions;
      const { setLogin, saveToken, saveUser } = PersistActions;
 
      try {
        await dispatch(startLoader());
        const headers = { "content-type": "application/json" };
        let res = await ApiService.login(data, headers);
        const responseData = res.data.data;
       
        const { user, token } = responseData;
        if (user.isDisabled == false) {
          await dispatch(setLogin());
          await dispatch(saveToken(token));
          await dispatch(saveUser(user));
          await dispatch(stopLoader());
          toast.success(res.data.message);
        } else {
          throw new Error("User is disabled")
        }

        resolve();
      } catch (err) {
        await dispatch(stopLoader());
        console.log(err?.message)
        if(err?.data?.errors?.message === "Error: Please verify your email before login."){
          reject("email verify error");
        }
        reject(err?.message);
      }
    });
  };
};


const forgotPassword = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { startLoader, stopLoader } = LoadingActions;
      const { saveToken } = PersistActions;
      try {
        await dispatch(startLoader());
        const headers = { "content-type": "application/json" };
        let res = await ApiService.forgotPassword(data, headers);
        await dispatch(stopLoader());
        await dispatch(saveToken(res.data.data.token));
        resolve(res);
      } catch (err) {
        await dispatch(stopLoader());
        reject(err);
      }
    });
  };
};

const resetPassword = (data) => {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      const { startLoader, stopLoader } = LoadingActions;
      const token = getState().persist.jwtToken;
      try {
        await dispatch(startLoader());
        const headers = {
          "content-type": "application/json",
          jwt: token,
        };
        let res = await ApiService.resetPassword(data, headers);
        await dispatch(stopLoader());
        resolve(res);
      } catch (err) {
        await dispatch(stopLoader());
        reject(err);
      }
    });
  };
};

const changePassword = (data) => {
  return async (dispatch, getState) => {
    const { startLoader, stopLoader } = LoadingActions;
    const token = getState().persist.jwtToken;
    try {
      await dispatch(startLoader());
      const headers = {
        "content-type": "application/json",
        jwt: token,
      };
      let res = await ApiService.changePassword(data, headers);
      await dispatch(stopLoader());
      return res;
    } catch (err) {
      await dispatch(stopLoader());
      // console.log(err);
    }
  };
};

/**
 * @function verifyEmail
 * @param {*} data { otp, user }
 * @returns void
 */
const verifyEmail = (data) => async (dispatch) => {
  const { startLoader, stopLoader } = LoadingActions;
  
  try {
    dispatch(startLoader());
    const res = await ApiService.verifyEmail(data, {});
    dispatch(stopLoader());
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
}

/**
 * @function resendOneTimePass
 * @param { role, user } data 
 * @returns OTP to email
 */
const resendOneTimePass = (data) => async (dispatch) => {
  const { startLoader, stopLoader } = LoadingActions;
  
  try {
    dispatch(startLoader());
    const res = await ApiService.resendOneTimePass(data, {});
    dispatch(stopLoader());
    return res;
  } catch (error) {
    dispatch(stopLoader());
    throw error;
  }
}

const sendOtpForCp = () => async (dispatch, getState) => {
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    const res = await ApiService.sendOtpForCp(headers);
    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const verificationBeforeCp = (data) => async (dispatch, getState) => {
  try {
    const token = getState().persist.jwtToken;
    const headers = {
      "content-type": "application/json",
      jwt: token,
    };
    const res = await ApiService.verificationBeforeCp(data, headers);
    toast.success(res.data.message)
  } catch (error) {
    throw error;
  }
};

export const UserActions = {
  register,
  login,
  forgotPassword,
  resetPassword,
  changePassword,
  verifyEmail,
  resendOneTimePass,
  sendOtpForCp,
  verificationBeforeCp
};
