import React from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import "./CustomizationDrawer.scss";
import ColorCustomization from "../ColorCustomization/ColorCustomization";
import { useDispatch } from "react-redux";
import BannerNftCustomization from "../BannerNftCustomization/BannerNftCustomization";
import SocialMediaCustomization from "../SocialMediaCustomization/SocialMediacustomization";
function CustomizationDrawer({
  show,
  handleClose,
  customizeDetailsPage,
  customize,
  setCustomize,
  setIsCustomized
}) {
  const dispatch = useDispatch();

  const customizePage = (e) => {
    e.preventDefault();
    customizeDetailsPage(e)
  }

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      backdrop={false}
      placement={"end"}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="subTitle"></Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={customizePage}>
          <ColorCustomization customize={customize} setCustomize={setCustomize} setIsCustomized={setIsCustomized}/>
          <div className="hrLine"></div>
          <SocialMediaCustomization customize={customize} setCustomize={setCustomize} setIsCustomized={setIsCustomized}/>
          <div className="hrLine"></div>
         <BannerNftCustomization customize={customize} setCustomize={setCustomize} setIsCustomized={setIsCustomized}/>
          <div className="hrLine"></div>
          <Button type="submit" className="cmnBtn mt-4">
           Done
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default React.memo(CustomizationDrawer);
