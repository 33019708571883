import React, { useState, useEffect, useMemo } from "react";
import Mint from "../Mint/Mint";
import Whitelist from "../Whitelist/Whitelist";
import InvitationCode from "../InvitationCode/InvitationCode";
import useQuery from "../../../hooks/useQuery";
import { useDispatch } from "react-redux";
import { CollectionActions } from "../../../redux/actions/collection.action";
import AccessModal from "../AccessModal/AccessModal";
import { Enviroments } from "../../../constants/constants";
import { withRouter } from "react-router-dom";
import "./BasicInfo.scss";
import EditCollectionModal from "../EditCollectionModal/EditCollectionModal";
import { useSelector } from "react-redux";
import RoundImageLogoSection from "../RoundImageLogoSection/RoundImageLogoSection";
import SquareImageSection from "../SquareImageSection/SquareImageSection";
import RoundImageDetails from "../RoundImageDetails/RoundImageDetails";
import PausedBatchInfo from "../PausedBatchInfo/PausedBatchInfo";

function BasicInfo({
  collectionDetails,
  getCustomizedColor,
  contractFetch,
  walletAddress,
  connectWallet,
  match,
  setCollectionDetails,
  nfts,
  setShowUpdateDatesModal,
  banners,
}) {
  console.log({ walletAddress });
  console.log({ banners });
  const { PUBLISH_PARAMS } = Enviroments;
  const [disableMinting, setDisableMinting] = useState(true);
  const [invitation, setInvitation] = useState({
    numberOfWalletJoined: 0,
    maxNumberOfWallet: 0,
    inviteId: null,
    type: "",
  });
  const query = useQuery();

  const inviteCode = query.get("code");
  const externalLink = query.get("collection");

  const dispatch = useDispatch();

  const [publishParams, setPublishParams] = useState(PUBLISH_PARAMS);

  const [showAccessModal, setShowAccessModal] = useState(false);
  const [disableMessage, setDisableMessage] = useState("");
  const [showEditCollectionModal, setShowEditCollectionModal] = useState(false);
  const isLoggedIn = useSelector((state) => state.persist.isLoggedIn);
  const [modifier, setModifier] = useState({
    name: "",
    text: "",
    validation: [],
  }); // to edit the property of collectiondetails in updatemodal

  const { isCustomizeButtonClicked } = useSelector((state) => state.customize);

  const isCreator = useMemo(() => {
    return (
      !!walletAddress &&
      walletAddress === collectionDetails.walletAddress.toLowerCase()
    );
  });

  const [mintedCountInfo, setMintedCountInfo] = useState(0);

  const { MINTING_TYPES, INVITATION_STATUS } = Enviroments;

  const isPrivate = useMemo(() => {
    if (match.path.indexOf("auth") != -1 && isLoggedIn) {
      return true;
    }
    return false;
  }, [match, isLoggedIn]);

  const checkInactiveInviteStatus = (status) => {
    const { INACTIVE } = INVITATION_STATUS;
    if (status === INACTIVE) {
      setShowAccessModal(true);
      setDisableMessage("Please use this link in presale now, if you have already whitelisted");
    }
  };

  const getInvitationDetailByInviteCode = async (inviteCode) => {
    try {
      const res = await dispatch(
        CollectionActions.getInvitationDetailByInviteCode(inviteCode)
      );
      if (res.data.data) {
        const { maxNumberOfWallet, numberOfWalletJoined, _id, type, status } =
          res.data.data;
        setInvitation({
          maxNumberOfWallet,
          numberOfWalletJoined,
          type,
          inviteId: _id,
        });
        
        checkInactiveInviteStatus(status);
      } else {
        setShowAccessModal(true);
        setDisableMessage("Invitation code doesn't exist");
        setInvitation({
          inviteId: -1,
        });
      }
    } catch (error) {}
  };

  const handleClose = () => {
    setShowUpdateDatesModal(false);
  };

  const openEditCollectionModal = (obj) => {
    if (!!obj) setModifier(obj);
    setShowEditCollectionModal(true);
  };

  const debugEverything = () => {
    let obj = {
      isCreator,
      invitation,
      walletAddress,
      mintingType,
    };
    console.table(obj);
  };

  const notifyInvitationStatus = (status) => {
    checkInactiveInviteStatus(status);
  };

  useEffect(() => {
    if (inviteCode) getInvitationDetailByInviteCode(inviteCode);
  }, [inviteCode]);

  const mintingType = useMemo(() => {
    let type = collectionDetails.currentBatchNo.isPrivateMintingAllowed;
    return type == true ? MINTING_TYPES.private : MINTING_TYPES.public;
  }, [collectionDetails]);

  return (
    /* Round image logo */

    <div className="collection-basic-info">
      {!isCustomizeButtonClicked && isPrivate && (
        <RoundImageLogoSection
          banners={banners}
          collectionDetails={collectionDetails}
        />
      )}

      {collectionDetails && Object.keys(collectionDetails).length > 0 && (
        <section>
          <div className="Right ">
            <>
              {/* if customized button is clicked show round image layout, 
            otherwise show square image layout */}

              {!isCustomizeButtonClicked ? (
                <RoundImageDetails
                  collectionDetails={collectionDetails}
                  getCustomizedColor={getCustomizedColor}
                  setDisableMinting={setDisableMinting}
                  walletAddress={walletAddress}
                  contractFetch={contractFetch}
                  isPrivate={isPrivate}
                  mintedCountInfo={mintedCountInfo}
                  setCollectionDetails={setCollectionDetails}
                  invitationType={invitation.type}
                  notifyInvitationStatus={notifyInvitationStatus}
                />
              ) : (
                <SquareImageSection
                  collectionDetails={collectionDetails}
                  getCustomizedColor={getCustomizedColor}
                  setDisableMinting={setDisableMinting}
                  walletAddress={walletAddress}
                  contractFetch={contractFetch}
                  isPrivate={isPrivate}
                  openEditCollectionModal={openEditCollectionModal}
                  mintedCountInfo={mintedCountInfo}
                  setCollectionDetails={setCollectionDetails}
                  invitationType={invitation.type}
                  notifyInvitationStatus={notifyInvitationStatus}
                />
              )}

              {/* if batch is ON_GOING show whitelist and mint  */}

              {collectionDetails.isLive &&
                collectionDetails.currentBatchNo.batchStatus === "ON_GOING" && (
                  <>
                    {debugEverything()}
                    {isCreator == false && isPrivate == false && (
                      <>
                        {(invitation.inviteId && invitation.inviteId != -1) ||
                        !inviteCode ? (
                          <>
                            <Whitelist
                              collectionDetails={collectionDetails}
                              getCustomizedColor={getCustomizedColor}
                              walletAddress={walletAddress}
                              connectWallet={connectWallet}
                              invitation={invitation}
                            />

                            <Mint
                              walletAddress={walletAddress}
                              collectionDetails={collectionDetails}
                              getCustomizedColor={getCustomizedColor}
                              connectWallet={connectWallet}
                              disableMinting={disableMinting}
                              contractFetch={contractFetch}
                              nfts={nfts}
                              isPrivate={isPrivate}
                              mintingType={mintingType}
                              setMintedCountInfo={setMintedCountInfo}
                              inviteCode={inviteCode}
                              setCollectionDetails={setCollectionDetails}
                              inviteId={invitation.inviteId}
                            />
                          </>
                        ) : (
                          <>
                            <AccessModal
                              show={showAccessModal}
                              message={disableMessage}
                            />
                          </>
                        )}
                      </>
                    )}

                    {isPrivate == false && invitation.type === "PUBLIC" && (
                      <AccessModal
                        show={showAccessModal}
                        message={disableMessage}
                        type={"publicInvitation"}
                        setShowAccessModal={setShowAccessModal}
                      />
                    )}

                    {isPrivate == true && (
                      <>
                        {mintingType == MINTING_TYPES.public ? (
                          <InvitationCode
                            collectionDetails={collectionDetails}
                            getCustomizedColor={getCustomizedColor}
                          />
                        ) : (
                          <>
                            <InvitationCode
                              collectionDetails={collectionDetails}
                              getCustomizedColor={getCustomizedColor}
                            />
                            <Mint
                              walletAddress={walletAddress}
                              collectionDetails={collectionDetails}
                              getCustomizedColor={getCustomizedColor}
                              connectWallet={connectWallet}
                              disableMinting={false}
                              contractFetch={contractFetch}
                              mintingType={mintingType}
                              nfts={nfts}
                              isPrivate={isPrivate}
                              setMintedCountInfo={setMintedCountInfo}
                              inviteCode={inviteCode}
                              setCollectionDetails={setCollectionDetails}
                              inviteId={invitation.inviteId}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

              {/* if batch is paused show info in public minting, 
                show mint button and info in private minting */}

              {collectionDetails.isLive &&
                collectionDetails.currentBatchNo.batchStatus === "PAUSED" &&
                isPrivate == true && (
                  <>
                    {mintingType === "PUBLIC" ? (
                      <PausedBatchInfo
                        getCustomizedColor={getCustomizedColor}
                      />
                    ) : (
                      <>
                        <Mint
                          walletAddress={walletAddress}
                          collectionDetails={collectionDetails}
                          getCustomizedColor={getCustomizedColor}
                          connectWallet={connectWallet}
                          disableMinting={false}
                          contractFetch={contractFetch}
                          mintingType={mintingType}
                          nfts={nfts}
                          isPrivate={isPrivate}
                          setMintedCountInfo={setMintedCountInfo}
                          inviteCode={inviteCode}
                          setCollectionDetails={setCollectionDetails}
                        />

                        <PausedBatchInfo
                          getCustomizedColor={getCustomizedColor}
                        />
                      </>
                    )}
                  </>
                )}
            </>
          </div>
        </section>
      )}
      {showEditCollectionModal && (
        <EditCollectionModal
          show={showEditCollectionModal}
          setShow={setShowEditCollectionModal}
          collectionDetails={collectionDetails}
          setCollectionDetails={setCollectionDetails}
          modifier={modifier}
        />
      )}

      {/* {JSON.stringify({batchStatus:collectionDetails.currentBatchNo.batchStatus, mintingType})} */}
    </div>
  );
}

export default withRouter(BasicInfo);
