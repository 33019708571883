import Swal from "sweetalert2";

class MySwal {
  defaultPosition="top-end"
  constructor() {}

  toaster(title, type, position) {
    Swal.fire({
      // title: 'Whitelisted!',
      title: title,
      icon: type,
      position: position,
      showConfirmButton: false,
      showCloseButton: true,
      toast: true,
      
    });
  }

  delete() {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "Do you want to delete this link?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          resolve();
        } else if (result.isDenied) {
          reject();
        }
      });
    });
  }


  showSuccessMessage(message) {
    Swal.fire(message, "", "success");
  }

  showInfoMessage(message) {
    Swal.fire(message, "", "info");
  }

  confirmAction(title,overrideObj){
    if(overrideObj){
      return Swal.fire({
        title: title,
        type:"info",
        position: this.defaultPosition,
        showConfirmButton: true,
        showCloseButton: true,
        showCancelButton:true,
        confirmButtonText: 'Yes',
        cancelButtonText: `No`,
        toast: true,
        ...overrideObj
      });
    }

    return Swal.fire({
      title: title,
      type:"info",
      position: this.defaultPosition,
      showConfirmButton: true,
      showCloseButton: true,
      showCancelButton:true,
      confirmButtonText: 'Yes',
      cancelButtonText: `No`,
      toast: true,
    });
    
  }

  
}

export default new MySwal();
