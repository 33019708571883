import React, { useState } from "react";
import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { HomepageActions } from "../../../redux/actions/homepage.action";

function LeftHeaderSection() {
  const [header, setHeader] = useState({
    headingOne: "",
    headingTwo: "",
    paragraph: "",
  });
  const dispatch = useDispatch();

  const getHeaders = async () => {
    try {
      const { getHeaderValues } = HomepageActions;
      let res = await dispatch(getHeaderValues());
      if (res.data.data) {
        setHeader(res.data.data.header);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHeaders();
  }, []);
  return (
    <>
      <Col xl={7} lg={8} className="titleSec">
        <h2 className="maintext">{header.headingOne}</h2>
        <h2 className="subtext">{header.headingTwo}</h2>
        <div
          className="gline mb24"
          dangerouslySetInnerHTML={{ __html: header.paragraph }}
        ></div>
      </Col>
    </>
  );
}

export default LeftHeaderSection;
