import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { CollectionActions } from "../../../redux/actions/collection.action";
import WalletManagerService from "../../../services/WalletManager.service";
import ReleaseDateConfirmationModal from "../ReleaseDateConfirmationModal/ReleaseDateConfirmationModal";
import { toasts as toast } from "../Toast/Toast";
import Web3 from "web3";
import "./PublishSection.scss";
import contractErrorsService from "../../../services/contractErrors.service";

function PublishSection({
  collectionDetails,
  getCustomizedColor,
  history,
  showUpdateDatesModal,
  setShowUpdateDatesModal,
  handleDatesUpdate,
}) {
  console.log({ showUpdateDatesModal });
  const [showReleaseDateModal, setShowReleaseDateModal] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowReleaseDateModal(false);
    setShowUpdateDatesModal(false);
  };

  const handleResponse = (res) => {
    collectionGoLive({ ...collectionDetails, ...res });
  };

  const collectionGoLive = async (obj) => {
    try {
      
      let data = {
        _id: collectionDetails._id,
        ...obj,
      };
      const batch = {
        ...obj.currentBatchNo
      }
      console.log({ apidata: data });
      const provider = WalletManagerService.initialize();
      const web3 = new Web3(provider);
      const { collectionGoLive, getContractByBlockChain } = CollectionActions;
      let response = await dispatch(getContractByBlockChain(obj, data, web3, batch));
      toast.success("Collection is live now..!!");
    } catch (error) {
      if (error) {
        const message = contractErrorsService.handleContractError(error);
        toast.error(message);
      }
    } finally {
      handleClose();
    }
  };

  return (
    <section className="five">
      <Container>
        <div class=" mb-4 " />
        {/* <h4 style={getCustomizedColor("heading")} className="title mb-0">
          SPOZZ_SPACE
        </h4> */}
        {/* <p className="mb-3">SPOZZIES</p> */}
        <div className="mt-2">
          {!collectionDetails.isLive && (
            <>
              <div className="hl"></div>
              <Button
                variant="primary"
                style={getCustomizedColor ? getCustomizedColor("button") : null}
                className="cmnBtn mb-4"
                onClick={setShowReleaseDateModal}
              >
                PUBLISH
              </Button>
            </>
          )}
          {/* <Button
            variant="primary"
            style={getCustomizedColor ? getCustomizedColor("button") : null}
            className="cmnBtn mb-4 ms-3"
            onClick={() => history.push("/auth/collection/allCollection")}
          >
            Back to Collections
          </Button> */}
        </div>
      </Container>

      {!!collectionDetails && collectionDetails.currentBatchNo && (
        <ReleaseDateConfirmationModal
          show={showUpdateDatesModal || showReleaseDateModal}
          handleClose={handleClose}
          setLiveParams={handleResponse}
          date={collectionDetails.currentBatchNo.whiteListStartTime}
          supply={collectionDetails.currentBatchNo.supply}
          collectionDetails={collectionDetails}
          setShowReleaseDateModal={setShowReleaseDateModal}
          setShowUpdateDatesModal={setShowUpdateDatesModal}
          showUpdateDatesModal={showUpdateDatesModal}
          handleDatesUpdate={handleDatesUpdate}
          title="Update Dates"
        />
      )}
    </section>
  );
}

export default withRouter(PublishSection);
